import { GridApi, ValueFormatterParams } from 'ag-grid-community'
import { ColumnVisibilityState, FilterModel, GridState, LicenseManager, SortModelItem } from 'ag-grid-enterprise'
import axios from 'axios'
import { debounce } from 'lodash'
import { Component, Mixins } from 'vue-property-decorator'

import { PlatformSettingsModule } from '@/store/modules/platformSettings'
import { ITableSettings, ITimestampedGridState, TGenericObject } from '@/types/base'
import { addContextToUrl, API_URLS } from '@/utils/helpers'

import LocaleMixin from './LocaleMixin'
import PlatformSettingsMixin from './PlatformSettingsMixin'

@Component
export default class AGGridMixin extends Mixins(PlatformSettingsMixin, LocaleMixin) {
  localStorageKey: string | null = null
  tableName: string | null = null
  gridApi: GridApi | null = null
  gridState: GridState | null = null
  defaultGridSort: SortModelItem[] | null = null
  columnVisibility: ColumnVisibilityState | null = null
  vueWaitKey: string = 'load-grid-state'
  urlParamFilters: { [key: string]: string | { colName: string; filterType: string; valueType?: string } } = {}
  currencyFilterParams = {
    allowedCharPattern: '\\d\\-\\,\\s\\€',
    numberParser: (text: string | null) => {
      if (text == null) {
        return null
      }
      return Number.parseFloat(text.replace(',', '.').replace('€', ''))
    },
    numberFormatter: (value: number | null) => {
      return value == null ? null : value.toFixed(2).replace('.', ',')
    },
  }

  setFiltersFromURLParams() {
    const urlParams = new URLSearchParams(window.location.search)
    const filterModel: FilterModel = {}
    for (const [key, urlParamFilter] of Object.entries(this.urlParamFilters)) {
      if (urlParams.has(key)) {
        if (typeof urlParamFilter === 'object') {
          if (urlParamFilter.filterType === 'set') {
            filterModel[urlParamFilter.colName] = {
              filterType: 'set',
              values: urlParams.getAll(key).map((value) => {
                if (urlParamFilter.valueType === 'number') {
                  return Number(value)
                }
                return value
              }),
            }
          } else {
            filterModel[urlParamFilter.colName] = {
              type: urlParamFilter.filterType,
              filter: urlParams.get(key),
            }
          }
        } else {
          filterModel[urlParamFilter] = {
            type: 'contains',
            filter: urlParams.get(key),
          }
        }
      }
    }
    if (Object.keys(filterModel).length > 0) {
      this.gridApi.setFilterModel(filterModel)
    }
  }

  filtersChanged = false
  gridColumnsChanged = false
  isResetting = false

  saveGridState(): void {
    const stringifiedTimestampedState = JSON.stringify({ timestamp: Date.now(), state: this.gridApi.getState() })
    localStorage[this.localStorageKey] = stringifiedTimestampedState
    this.saveGridToDatabase(stringifiedTimestampedState)
  }

  saveGridToDatabase(timestampedState: string): void {
    if (!this.localStorageKey) {
      return
    }
    axios.patch(
      API_URLS.TABLE_SETTINGS('site_admin_table_internal_fundings'),
      {
        site_admin_table_internal_fundings: timestampedState,
      },
      { headers: { 'X-CSRFToken': this.$cookies.get('csrftoken') } }
    )
  }

  async loadGridState(): Promise<void> {
    let gridStateFromDB: ITimestampedGridState | null = null
    let gridStateFromLocalStorage: ITimestampedGridState | null = null
    if (this.tableName) {
      gridStateFromDB = await axios
        .get<
          ITableSettings & { site_admin_table_internal_fundings: string }
        >(addContextToUrl(API_URLS.TABLE_SETTINGS(this.tableName), { sortBy: 'title', pageSize: 9999 }))
        .then((response) => {
          if (!!response.data['site_admin_table_internal_fundings']) {
            return JSON.parse(response.data['site_admin_table_internal_fundings']) as ITimestampedGridState
          }
          return null
        })
    }
    if (this.localStorageKey && !!localStorage[this.localStorageKey]) {
      gridStateFromLocalStorage = JSON.parse(localStorage[this.localStorageKey]) as ITimestampedGridState
    }

    // If both localstorage and db are empty, set defaults ourselves, as otherwise we would return an empty gridState
    // empty object will still override coldefs like `hide`. We can also consider setting gridState to undefined instead
    // and define `hide` and others in coldefs. Quick test didn't work though
    if (!gridStateFromDB && !gridStateFromLocalStorage) {
      this.gridState = {
        columnVisibility: this.columnVisibility,
      }
    } else if (
      gridStateFromDB &&
      (!gridStateFromLocalStorage || gridStateFromDB.timestamp > gridStateFromLocalStorage.timestamp)
    ) {
      this.gridState = gridStateFromDB.state
    } else {
      this.gridState = gridStateFromLocalStorage.state
    }

    // If no sorting applied, even if we have found a previous state, apply default sorting
    if (this.defaultGridSort && !this.gridState?.sort) {
      this.gridState = {
        ...this.gridState,
        sort: { sortModel: this.defaultGridSort },
      }
    }
  }

  dateFilterCustomOptions() {
    // This function provides customized date filter options for ag-grid table
    // It is used in agDateColumnFilter inside filterParams property
    // Here you can add new custom options for date filter or customize the exisiting options.
    return [
      'equals',
      'notEqual',
      {
        displayKey: 'after',
        displayName: this.$gettext('From (Incl.)'),
        predicate: (filterValue: Date, cellValue: Date) => {
          const filterDate = this.createDateWithoutTime(filterValue)
          const cellDate = this.createDateWithoutTime(cellValue)
          return cellDate >= filterDate
        },
      },
      {
        displayKey: 'before',
        displayName: this.$gettext('Until (Incl.)'),
        predicate: (filterValue: Date, cellValue: Date) => {
          const filterDate = this.createDateWithoutTime(filterValue)
          const cellDate = this.createDateWithoutTime(cellValue)
          return cellDate <= filterDate
        },
      },
      {
        displayKey: 'inRange',
        displayName: this.$gettext('From-Until (Incl.)'),
        numberOfInputs: 2,
        predicate: (filterValue: Date[], cellValue: Date) => {
          const [start_date, end_date] = filterValue
          const filterStartDate = this.createDateWithoutTime(start_date)
          const filterEndDate = this.createDateWithoutTime(end_date)
          const cellDate = this.createDateWithoutTime(cellValue)
          return cellDate >= filterStartDate && cellDate <= filterEndDate
        },
      },
      'blank',
      'notBlank',
    ]
  }

  createDateWithoutTime(date: Date): Date {
    const newDate = new Date(date)
    newDate.setHours(0, 0, 0, 0)
    return newDate
  }

  dateComparator(filterValue: Date, cellValue: Date): number {
    // Create new Date objects to avoid modifying the original dates
    const filterDate = this.createDateWithoutTime(filterValue)
    const cellDate = this.createDateWithoutTime(cellValue)

    if (filterDate < cellDate) {
      return 1
    } else if (filterDate > cellDate) {
      return -1
    } else {
      return 0
    }
  }

  filterTitleAndSlug(
    filterText: string,
    value: string,
    filterOption: string,
    data: { slug: string },
    objKey: 'project' | 'organization' | 'company' | 'donation_limit' | 'self'
  ): boolean {
    let obj = { slug: '' }
    if (objKey === 'self') {
      obj = data
    } else {
      obj = data[objKey]
    }
    switch (filterOption) {
      case 'contains': {
        return value.includes(filterText) || obj.slug.includes(filterText)
      }
      case 'notContains': {
        return !(value.includes(filterText) || obj.slug.includes(filterText))
      }
      case 'equals': {
        return value === filterText || obj.slug === filterText
      }
      case 'notEqual': {
        return !(value === filterText || obj.slug === filterText)
      }
      case 'startsWith': {
        return value.startsWith(filterText) || obj.slug.startsWith(filterText)
      }
      case 'endsWith': {
        return value.endsWith(filterText) || obj.slug.endsWith(filterText)
      }
      default: {
        {
          // should never happen
          console.warn('invalid filter type', filterOption, value)
          return false
        }
      }
    }
  }

  getNestedProperty(obj: TGenericObject, path: string): unknown {
    const parts = path.split('.')
    let value = obj
    for (const part of parts) {
      if (value && value.hasOwnProperty(part)) {
        value = value[part]
      } else {
        return undefined
      }
    }
    return value
  }

  currencyValueFormatter(params: ValueFormatterParams, key?: string): string {
    // key i.e. 'amount.in_currency_display'
    if (key && params.data) {
      return this.getNestedProperty(params.data, key) as string
    }
    return this.toCurrency(params.value)
  }

  handleSearchChange(searchString) {
    this.gridApi?.setGridOption('quickFilterText', searchString)
  }

  handleFiltersChanged() {
    if (!this.gridApi?.isAnyFilterPresent()) {
      this.filtersChanged = false
    }
    if (this.gridApi?.isAnyFilterPresent() && !this.isResetting) {
      this.filtersChanged = true
    }
    if (this.isResetting) {
      this.isResetting = false
    }
  }

  resetFilters() {
    this.isResetting = true
    this.gridApi?.setFilterModel(null)
    this.gridApi?.resetQuickFilter()
    this.filtersChanged = false
  }

  handleGridColumnsChanged() {
    if (!this.isResetting) {
      this.gridColumnsChanged = true
    }
    if (this.isResetting) {
      this.isResetting = false
    }
  }

  resetAllColumns() {
    this.isResetting = true
    this.gridApi?.resetColumnState()
    this.gridColumnsChanged = false
  }

  async beforeMount() {
    let licenseKey: string = null
    const agGridData = JSON.parse(localStorage.getItem('ag-grid') || '{}')
    if (
      agGridData.license_key &&
      agGridData.license_key_timestamp &&
      Date.now() - new Date(agGridData?.license_key_timestamp).getTime() < 60 * 5 * 100 // Every 5 minutes we load again
    ) {
      licenseKey = agGridData.license_key
    }
    if (!licenseKey) {
      await PlatformSettingsModule.fetchPlatformSettings()
      licenseKey = PlatformSettingsModule.features?.ag_grid_key
      localStorage.setItem('ag-grid', JSON.stringify({ license_key: licenseKey, license_key_timestamp: new Date() }))
    }
    LicenseManager.setLicenseKey(licenseKey)
  }

  async created(): Promise<void> {
    this.$wait.start(this.vueWaitKey)
    await this.loadGridState()
    this.saveGridState = debounce(this.saveGridState, 500)
    this.saveGridToDatabase = debounce(this.saveGridToDatabase, 3000)
    this.$wait.end(this.vueWaitKey)
  }
}
