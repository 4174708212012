<template>
  <b-modal id="favourite-added-modal" v-model="isOpenModal" centered size="md" v-on="$listeners">
    <template #modal-title>
      <h5><translate>Favorite saved!</translate></h5>
    </template>

    <template #default>
      <p>
        <translate
          >The project has been added to your favorites. You can find your favorites in the menu item Favorites in the
          My profile section.</translate
        >
      </p>
    </template>
    <template #modal-footer="">
      <b-button id="close-favourite-modal-btn" variant="default" class="mr-1" @click="closeModal()">
        <translate>Back to project</translate>
      </b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'favourite-added-modal',
})
export default class FavouriteAddedModal extends Vue {
  @Prop({ default: false }) value!: boolean

  get isOpenModal(): boolean {
    return this.value
  }

  set isOpenModal(value: boolean) {
    this.$emit('input', value)
  }

  closeModal() {
    this.isOpenModal = false
    location.reload()
  }
}
</script>
