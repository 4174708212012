<template>
  <div>
    <div v-if="contactPersonPhoto" class="position-relative">
      <VideoComponent :photo="contactPersonPhoto">
        <template #image-description>
          <div class="contact-person-splide-description-text">
            <h3>
              {{ contactPerson.addressable_name }}
            </h3>
            <p>
              <translate>Presents this need</translate>
            </p>
          </div>
        </template>
      </VideoComponent>
    </div>
    <div class="row rounded-10 pl-3 mt-2">
      <ReadMoreCollapse :btnTxt="$gettext('About me')">
        <p>{{ contactPerson.vita }}</p>
      </ReadMoreCollapse>
    </div>
    <div class="row rounded-10 pl-3">
      <ReadMoreCollapse :btnTxt="$gettext('Contact')">
        <p v-if="contactPerson.job_title">
          <strong><translate>Job title</translate></strong
          >: {{ contactPerson.job_title }}
        </p>
        <p v-if="contactPerson.phone">
          <strong><translate>Phone</translate></strong
          >: <a :href="'tel:' + contactPerson.phone">{{ contactPerson.phone }}</a>
        </p>
        <p v-if="contactPerson.mobile">
          <strong><translate>Mobile</translate></strong
          >: <a :href="'tel:' + contactPerson.mobile">{{ contactPerson.mobile }}</a>
        </p>
        <p v-if="contactPerson.fax">
          <strong><translate>Fax</translate></strong
          >: {{ contactPerson.fax }}
        </p>
        <div v-if="contactPerson.street && contactPerson.postal_code && contactPerson.city">
          <p>
            <strong><translate>Street</translate></strong
            >: {{ contactPerson.street }}
          </p>
          <p>
            <strong><translate>Postal code</translate></strong
            >: {{ contactPerson.postal_code }}
          </p>
          <p>
            <strong><translate>City</translate></strong
            >: {{ contactPerson.city }}
          </p>
        </div>
        <p v-if="contactPerson.public_contact_info">
          {{ contactPerson.public_contact_info }}
        </p>
      </ReadMoreCollapse>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IContactPerson } from '@/types/foerderApp'

import ReadMoreCollapse from './ReadMoreCollapse.vue'
import VideoComponent from './VideoComponent.vue'
@Component({
  name: 'contact-person',
  components: {
    ReadMoreCollapse,
    VideoComponent,
  },
})
export default class ContactPerson extends Vue {
  @Prop({ required: true }) contactPerson: IContactPerson

  get contactPersonPhoto() {
    if (this.contactPerson?.photos.length > 0) {
      return this.contactPerson?.photos[0]
    }
    return null
  }
}
</script>
