import { Component, Vue } from 'vue-property-decorator'

import { IAPIFilterCtx, TGenericObject } from '@/types/base'
import { IFilter, IFilters } from '@/types/filters'
import { addContextToUrl } from '@/utils/helpers'

@Component({
  name: 'generic-table-mixin',
})
export default class GenericTableMixin extends Vue {
  /*
  Use this Mixin in a List.vue that uses a GenericTable to reduce the amount of boilerplate.
  */
  searchString = ''
  filterCols: { field: string; filter: string }[] = []
  filters: IFilters = {}
  activeTableFilters: IFilter[] | null = null

  get apiExportContext(): IAPIFilterCtx {
    return {
      search: this.searchString,
      filters: this.activeTableFilters,
      xlsx: true,
    }
  }

  tableFilterUpdated(filters: IFilter[], resetAll = false): void {
    this.activeTableFilters = filters
    if (resetAll) {
      for (const key in this.filters) {
        const filter = this.filters[key]
        if ('selected' in filter) {
          filter.selected = []
        } else if ('value' in filter) {
          filter.value = ''
        }
      }
    }
  }

  searchUpdated(newSearch: string): void {
    this.searchString = newSearch
  }

  getFilteredExportUrl(apiUrl: string, customParameters: TGenericObject = {}): string {
    return addContextToUrl(apiUrl, { extraParams: customParameters, ...this.apiExportContext })
  }

  getUnfilteredExportUrl(apiUrl: string, customParameters: TGenericObject = {}): string {
    return addContextToUrl(apiUrl, {
      xlsx: true,
      extraParams: customParameters,
    })
  }
}
