<template>
  <span class="labels" v-html="label" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IChoice } from '@/types/base'

@Component({
  name: 'content-review-status-label',
})
export default class ContentReviewStatusLabel extends Vue {
  @Prop() status!: IChoice

  get label(): string {
    let labelHtml = '-'
    if (this.status) {
      // ignore 1 bc it's just "please choose..."
      if (this.status.choice === 2) {
        labelHtml = `<span class="badge badge-success">${this.status.display}</span>`
      } // accepted
      else if (this.status.choice === 3) {
        labelHtml = `<span class="badge badge-danger">${this.status.display}</span>`
      } // rejected
    }
    return labelHtml
  }
}
</script>
