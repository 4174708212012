<template>
  <b-modal id="donation-success-modal" v-model="isOpenModel" size="lg" centered v-on="$listeners">
    <template #modal-title>
      <h5 v-translate>Thank you for your support!</h5>
    </template>

    <template #default>
      <div class="row">
        <div class="col-md-6">
          <SocialMediaArea
            id="donation-modal-social-media"
            :project="project"
            :projectDetailSettings="projectDetailSettings"
          />
          <b-card class="shadow-sm rounded mb-3">
            <b-img
              v-if="project.photos?.length"
              :src="project.photos[0].src"
              fluid
              :alt="project.title + ' cover image'"
            />
            <hr />
            <div>
              <p
                v-translate="{
                  donationAmount: formatCurrency(
                    donationResult?.prettified_amount_in_coins,
                    donationResult?.prettified_amount_in_currency
                  ),
                  projectTitle: project.title,
                }"
              >
                You supported the project <strong><span class="project_title">%{ projectTitle }</span></strong> with
                <strong><span class="coins">%{ donationAmount }</span></strong
                >.
              </p>
              <p
                v-if="donationResult?.is_project_donation"
                key="missing-amount-info"
                v-translate="{
                  missingAmount: formatCurrency(
                    donationResult?.project_pretty_coins_to_go,
                    donationResult?.project_pretty_currency_to_go
                  ),
                }"
              >
                Just <strong><span class="missing_amount">%{ missingAmount }</span></strong> are needed to fund the
                project completely.
              </p>
            </div>
          </b-card>
        </div>
        <div class="col-md-6">
          <b-row v-for="company in donationResult?.log_donation_companies_info" :key="company.comp_slug">
            <b-card class="shadow-sm rounded mb-3">
              <div class="d-flex justify-content-center align-items-center">
                <b-img
                  v-if="company.comp_logo"
                  class="rounded"
                  :src="company.comp_logo"
                  fluid
                  :alt="company.title + ' logo'"
                />
              </div>
              <hr />
              <div>
                <p
                  v-translate="{
                    donationAmount: formatCurrency(company.comp_donated_amt_coins, company.comp_donated_amt_currency),
                    companyName: company.comp_name,
                  }"
                >
                  You have distributed <strong><span class="coins">%{ donationAmount }</span></strong> from
                  <strong><span class="company_name">%{ companyName }</span></strong
                  >.
                </p>
              </div>
            </b-card>
          </b-row>
        </div>
      </div>
    </template>

    <template #modal-footer="{ close }">
      <b-button id="close-delete-modal-btn" variant="default" class="mr-1" @click="close">
        <translate>Close</translate>
      </b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'

import LocaleMixin from '@/mixins/LocaleMixin'
import { ICurrencyDisplaySettings, IProjectDetailSettings } from '@/types/cms'
import { IDonationResult, IExploreProjectDetail } from '@/types/projects'
import { IUserProfile } from '@/types/users'

import SocialMediaArea from '../explore/SocialMediaArea.vue'

@Component({
  name: 'donation-success-modal',
  components: {
    SocialMediaArea,
  },
})
export default class DonationSuccessModal extends Mixins(LocaleMixin) {
  @Prop() project!: IExploreProjectDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings
  @Prop({ default: null }) donationResult: IDonationResult
  @Prop({ default: null }) userProfile: IUserProfile
  @Prop({ default: false }) value!: boolean

  get isOpenModel(): boolean {
    return this.value
  }

  set isOpenModel(value: boolean) {
    this.$emit('input', value)
  }

  get currencyDisplay(): ICurrencyDisplaySettings {
    return this.projectDetailSettings?.currencyDisplay
  }

  formatCurrency(coinVal: string, centsVal: string): string {
    if (this.currencyDisplay.useCurrency) {
      return `${centsVal} ${this.currencyDisplay.currencySymbol}`
    }
    return `${coinVal} ${this.currencyDisplay.coinNamePlural}`
  }
}
</script>
