<template>
  <span class="labels" v-html="label" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IChoice } from '@/types/base'

@Component({
  name: 'review-status-label',
})
export default class ReviewStatusLabel extends Vue {
  @Prop() status!: IChoice
  @Prop() needs_review!: boolean

  get label(): string {
    let labelHtml = '-'
    if (this.status) {
      switch (this.status.choice) {
        // Ignore 1 cs it's just "Please choose..."
        case 2: {
          // Public
          labelHtml = `<span class="badge badge-success">${this.status.display}</span>`
          break
        }
        case 3: {
          // Rejected
          labelHtml = `<span class="badge badge-danger">${this.status.display}</span>`
          break
        }
        case 4: {
          // Accepted (not public)
          labelHtml = `<span class="badge badge-success">${this.status.display}</span>`
          break
        }
      } // accepted
    }
    if (this.needs_review) {
      const needsReviewLabel = `<span class="badge badge-warning">${this.$gettext('Needs review')}</span>`
      if (labelHtml.length > 1) {
        labelHtml += needsReviewLabel
      } else {
        labelHtml = needsReviewLabel
      }
    }
    return labelHtml
  }
}
</script>
