<template>
  <div>
    <b-form-group>
      <b-form-input
        id="first-name-input"
        v-model="privateDonationForm.first_name"
        type="text"
        :placeholder="$gettext('First name') + ' *'"
        required
      />
      <b-form-invalid-feedback :state="errorState('first_name')">
        {{ formErrors.first_name }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        id="last-name-input"
        v-model="privateDonationForm.last_name"
        type="text"
        :placeholder="$gettext('Last name') + ' *'"
        required
      />
      <b-form-invalid-feedback :state="errorState('last_name')">
        {{ formErrors.last_name }}
      </b-form-invalid-feedback>
    </b-form-group>

    <EmailInput
      v-model="privateDonationForm.email"
      :message.sync="formErrors.email"
      :showPrepend="!projectDetailSettings.enableFoerderApp"
    />
    <AddressInput :form="privateDonationForm" :formErrors="formErrors" />

    <b-form-group>
      <b-form-checkbox
        id="terms_accepted"
        v-model="privateDonationForm.terms_accepted"
        name="terms_accepted"
        :state="formErrors['terms_accepted'] ? false : null"
      >
        <span v-if="projectDetailSettings.termsOfUseText" v-html="projectDetailSettings.termsOfUseText" />
        <translate v-else>I have read and agree to the terms of use</translate>*
      </b-form-checkbox>

      <b-form-checkbox
        v-if="projectDetailSettings.privacyPolicyType == privacyPolicyTypes.WithCheckbox"
        id="privacy_accepted"
        v-model="privateDonationForm.privacy_accepted"
        name="privacy_accepted"
        :state="formErrors['privacy_accepted'] ? false : null"
      >
        <span v-html="projectDetailSettings.privacyPolicyText" />*
      </b-form-checkbox>

      <label
        v-if="projectDetailSettings.privacyPolicyType == privacyPolicyTypes.WithoutCheckbox"
        style="padding: 10px 0 10px 30px"
        v-html="projectDetailSettings.privacyPolicyText"
      />

      <b-form-checkbox
        v-if="projectDetailSettings.showDonationReceiptCheckbox"
        id="needs_receipt"
        v-model="privateDonationForm.needs_receipt"
        name="needs_receipt"
      >
        <translate>I need a donation receipt</translate>
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { TGenericObject } from '@/types/base'
import { IProjectDetailSettings } from '@/types/cms'
import { IPrivateDonationForm } from '@/types/projects'
import { privacyPolicyTypes } from '@/utils/helpers/settings'

import AddressInput from './AddressInput.vue'
import EmailInput from './EmailInput.vue'
@Component({
  name: 'private-donation-form',
  components: {
    EmailInput,
    AddressInput,
  },
})
export default class PrivateDonationForm extends Vue {
  @Prop({ required: false }) form: IPrivateDonationForm
  @Prop() formErrors!: TGenericObject
  @Prop() projectDetailSettings!: IProjectDetailSettings
  privacyPolicyTypes = privacyPolicyTypes

  get privateDonationForm() {
    return this.form
  }

  set privateDonationForm(value: IPrivateDonationForm) {
    this.$emit('input', value)
  }

  errorState(field: string): boolean {
    return !this.formErrors[field]
  }
}
</script>
