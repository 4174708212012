<template>
  <div>
    <b-row v-if="!hideSearch" class="justify-content-between mb-3">
      <b-col cols="auto" class="mr-auto mt-2 d-flex">
        <slot name="action-buttons-left" />
        <slot name="search">
          <InputGroup icon="search" :placeholder="getSearchPlaceholder()" @update="handleSearchChange" />
        </slot>
      </b-col>
      <b-col cols="auto" class="d-flex mt-2">
        <PageSizeSelect v-model="pageSizeModel" />
        <slot v-if="!hideFilterOptions" name="head-right">
          <b-dropdown dropleft no-caret variant="default" class="options-dropdown">
            <template #button-content> <translate>Options</translate> <i class="fal fa-ellipsis-v mr-1" /> </template>
            <slot name="dropdown-content" />
          </b-dropdown>
        </slot>
        <slot name="action-buttons-right" />
      </b-col>
      <b-col v-if="resetFilterButton && activeFilters?.length" cols="12">
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <b-button variant="outline-secondary" class="mt-1" @click="resetFilters">
              <translate>Reset filters</translate>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <v-wait for="fetch items">
      <template slot="waiting">
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-center">
            <b-spinner variant="primary" label="Spinning" />
          </div>
        </div>
      </template>
      <b-table
        ref="selectableTable"
        hover
        outlined
        :striped="tableItems.length >= 3"
        responsive
        no-local-sorting
        show-empty
        sort-icon-left
        selectable
        :class="'generic-table ' + customClasses"
        :small="small"
        :no-select-on-click="true"
        :empty-text="emptyTextWithFallback"
        :fields="computedFields"
        :items="tableItems"
        :busy="tableLoading"
        @sort-changed="sortingChanged"
        @row-selected="onRowSelected"
      >
        <template v-for="field in customFields" #[`cell(${field})`]="props">
          <slot v-bind="props" :name="field" />
        </template>

        <template v-for="slotName in Object.keys($scopedSlots)" #[slotName]="slotScope">
          <slot :name="slotName" v-bind="slotScope" />
        </template>

        <template v-for="col in filterCols" #[`head(${col.field})`]="data">
          <span :key="col.name">
            <TableFilterDropdown
              :key="`header-${col.name}`"
              menuClass="table-filter-menu"
              :label="data.label"
              :fieldName="col.field"
              :options="getFilter(col.filter).options"
              :filterName="getFilter(col.filter).filterName"
              :selected.sync="getFilter(col.filter).selected"
              @on-apply="applyFilter"
            />
          </span>
        </template>

        <!-- TODO: When moving this whole thing to its own module, build something to iterate through a variable and do this -->
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner variant="primary" class="align-middle" />
          </div>
        </template>

        <template #head(selected)>
          <b-form-checkbox :checked="allVisibleItemsSelected" @change="toggleCheckAll" />
        </template>

        <template #cell(selected)="data">
          <b-form-checkbox :checked="data.rowSelected !== false" @change="toggleSelect(data.index)" />
        </template>

        <template #cell(index)="data">
          {{ 1 + Number(data.index) + (Number(localCurrentPage) - 1) * Number(pageSizeModel) }}
          <div class="generic-table-action-slot">
            <slot name="actions" :item="data.item" />
          </div>
        </template>

        <template v-if="sumFields.length > 0" #custom-foot="data">
          <tr style="background: #f5f5f5">
            <td v-for="item in data.fields" :key="item.key" style="text-align: right">
              <strong v-if="item.key === 'title'">
                <translate>Sum</translate>:
                <i id="sum_budgets" class="fas fa-question-circle" />
                <b-tooltip target="sum_budgets" placement="left">
                  <translate>Shows the sum of the rows from the current page.</translate>
                </b-tooltip>
              </strong>
              <strong v-if="sumFields.includes(item.key)">{{ paginationSum(item.key) }}</strong>
            </td>
          </tr>
          <tr v-if="Object.keys(sumOfAllResults).length > 0" style="background: #f5f5f5">
            <td v-for="item in data.fields" :key="item.key" style="text-align: right">
              <strong v-if="item.key === 'title'">
                <translate>Total Sum</translate>:
                <i id="total_sum_budgets" class="fas fa-question-circle" />
                <b-tooltip target="total_sum_budgets" placement="left">
                  <translate>Shows the total sum of all rows across all pages.</translate>
                </b-tooltip>
              </strong>
              <strong v-if="sumOfAllResults[item.key]">{{ toCurrency(sumOfAllResults[item.key]) }}</strong>
            </td>
          </tr>
        </template>
      </b-table>

      <b-pagination
        v-model="localCurrentPage"
        aria-controls="item-list"
        :prev-text="'‹ ' + $gettext('Prev')"
        :next-text="$gettext('Next') + ' ›'"
        :total-rows="itemCount"
        :per-page="pageSizeModel"
        first-number
        last-number
      />
    </v-wait>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { BTable, BvComponent, BvTableCtxObject, BvTableField } from 'bootstrap-vue'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'

import InputGroup from '@/components/InputGroup.vue'
import PageSizeSelect from '@/components/PageSizeSelect.vue'
import TableFilterDropdown from '@/components/TableFilterDropdown.vue'
import LocaleMixin from '@/mixins/LocaleMixin'
import { BvTableFieldArrayWithStickColumn, TGenericObject } from '@/types/base'
import { IFilter, IFilterInit, IFilters } from '@/types/filters'
import { addContextToUrl } from '@/utils/helpers'

@Component({
  components: {
    InputGroup,
    TableFilterDropdown,
    PageSizeSelect,
  },
})
export default class GenericTable extends Mixins(LocaleMixin, BTable) {
  // Table field definitions. Check https://bootstrap-vue.org/docs/components/table for more
  @Ref() readonly selectableTable!: BvComponent
  @Prop({ default: false }) archived!: boolean
  @Prop({ default: () => [] }) customFields!: string[]
  @Prop({ default: '' }) customClasses!: string
  @Prop({ default: false }) bordered!: boolean
  @Prop() searchPlaceholder: string
  @Prop() tableName: string
  @Prop({ default: null }) localStorageName!: string | null
  @Prop() apiUrl!: string
  @Prop({ default: () => [] }) filterCols: { field: string; filter: string; name?: string }[]
  @Prop({ default: false }) hideSearch!: boolean
  @Prop({ default: false }) hideFilterOptions!: boolean
  @Prop({ default: 25 }) pageSize!: number
  @Prop({ default: false }) small!: boolean
  @Prop({ default: false }) selectable: boolean
  @Prop({ default: () => [] }) sumFields: string[]
  @Prop({ default: false }) resetFilterButton: boolean

  // Define filter options. Those that we see in the dropdowns
  @Prop({ default: () => ({}) }) filters: IFilters
  /* // Example how to define filters:
  filters: IFilters = {
    itemFieldFilter: {
      filterName: 'field_name',
      selected: [],
      options: {
        option_1: this.$gettext('Option 1'),
        option_2: this.$gettext('Option 2')
      }
    }
  } */

  tableItems: TGenericObject[] = []
  searchString!: string
  totalPages = 1
  itemCount = 0
  activeFilters: IFilter[] = []
  tableContext: BvTableCtxObject | null = null
  tableLoading = false
  hoveredRowId = 0
  selectedItems: TGenericObject[] = []
  sumOfAllResults = {}
  localCurrentPage = this.currentPage

  handleSearchChange(value: string) {
    this.searchString = value
    this.$emit('search-update', this.searchString)
    this.localCurrentPage = 1
    this.loadItems()
  }

  get pageSizeModel() {
    return this.pageSize
  }

  set pageSizeModel(value: number) {
    this.$emit('update:pageSize', value)
  }

  @Watch('itemCount')
  onItemCountChanged() {
    this.$emit('item-count-update', this.itemCount)
  }

  @Watch('pageSize')
  onPageSizeChanged() {
    this.loadItems()
  }

  @Watch('localCurrentPage')
  onCurrentPageChanged() {
    if (!this.tableLoading) {
      this.loadItems()
    }
  }

  sortingChanged(ctx: BvTableCtxObject) {
    this.tableContext = ctx
    this.loadItems()
  }

  applyFilter(filter: IFilter | IFilter[]) {
    if ('filterName' in filter) {
      this.activeFilters = this.activeFilters.filter((_filter) => _filter.filterName !== filter.filterName)
      this.activeFilters.push(filter)
    } else {
      for (const filterItem of filter) {
        this.activeFilters = this.activeFilters.filter((_filter) => _filter.filterName !== filterItem.filterName)
        this.activeFilters.push(filterItem)
      }
    }

    if (this.localStorageName) {
      localStorage[this.localStorageName] = JSON.stringify(this.activeFilters)
    }

    this.$emit('filter-update', this.activeFilters)
    this.localCurrentPage = 1
    this.loadItems()
  }

  getFilter(filterName: string): IFilterInit {
    return this.filters[filterName]
  }

  getFieldName(field: string | ({ key: string } & { stickyColumn?: boolean } & BvTableField)): string {
    return typeof field === 'string' ? field : field.key
  }

  get computedFields(): BvTableFieldArrayWithStickColumn {
    // add a generic actions column that is basically not visible to add row actions
    const finalFields = [...this.fields]
    finalFields.unshift({ key: 'index', label: this.$gettext('#') })

    if (this.selectable) {
      finalFields.unshift({ key: 'selected', label: 'Select All', sortable: false })
    }

    return finalFields
  }

  get emptyTextWithFallback(): string {
    return this.emptyText || this.$gettext('No entries found')
  }

  getSearchPlaceholder(): string {
    return this.searchPlaceholder || this.$gettext('Search…')
  }

  toggleCheckAll(value: boolean | string): void {
    if (value) {
      this.selectableTable.selectAllRows()
    } else {
      this.selectableTable.clearSelected()
    }
  }

  onRowSelected(items: TGenericObject[]) {
    this.selectedItems = items
    this.$emit('update:selectedItems', items)
  }

  toggleSelect(rowIndex: number): void {
    if (this.selectableTable.isRowSelected(rowIndex)) {
      this.selectableTable.unselectRow(rowIndex)
    } else {
      this.selectableTable.selectRow(rowIndex)
    }
  }

  get allVisibleItemsSelected(): boolean {
    return this.tableItems.length <= this.selectedItems.length
  }

  paginationSum(parameter: string): string {
    const summaries = []
    let filteredSum = []

    for (const element of this.tableItems) {
      const element_ = { ...element, parameter }
      summaries.push(element_[parameter])
    }

    filteredSum = summaries.filter((element) => {
      return element !== null
    })
    const sum = filteredSum.reduce((accumulator, current) => accumulator + current.in_currency, 0)
    return this.toCurrency(sum)
  }

  async loadItems(): Promise<void> {
    if (!this.apiUrl) {
      return
    }

    this.tableLoading = true

    await axios
      .get(
        addContextToUrl(this.apiUrl, {
          sortBy: this.tableContext ? this.tableContext.sortBy : undefined,
          sortDesc: this.tableContext ? this.tableContext.sortDesc : undefined,
          search: this.searchString,
          filters: this.activeFilters,
          page: this.localCurrentPage,
          pageSize: this.pageSizeModel,
        })
      )
      .then((response) => {
        this.totalPages = response.data.total_pages
        this.localCurrentPage = response.data.current_page
        this.tableItems = response.data.results
        this.itemCount = response.data.count

        for (const sumField of this.sumFields) {
          this.sumOfAllResults[sumField] = response.data[`total_${sumField}`]
        }

        this.$emit('loaded', response.data.results)
      })
      .catch(() => {
        this.$bvToast.toast(this.$gettext('Please try again or contact an administrator.'), {
          title: this.$gettext('Error'),
          toaster: 'b-toaster-top-left old',
          variant: 'variant',
          solid: true,
          toastClass: 'sf-toast',
        })
      })

    this.tableLoading = false
  }

  initLocalStorage(): void {
    if (this.localStorageName) {
      try {
        this.activeFilters = JSON.parse(localStorage[this.localStorageName])
        const activeFilterNames = new Set(this.activeFilters.map((filter) => filter.filterName))

        // eslint-disable-next-line unicorn/no-array-reduce
        const activeFiltersObject = this.activeFilters.reduce((result, item: IFilter) => {
          result[item.filterName] = item.selected
          return result
        }, {})

        for (const filterKey in this.filters) {
          const filter = this.filters[filterKey]

          if (activeFilterNames.has(filter.filterName)) {
            filter.selected = activeFiltersObject[filter.filterName]
          }
        }
        this.$emit('filter-update', this.activeFilters)
      } catch {
        localStorage.removeItem(this.localStorageName)
      }
    }
  }

  async loadInitialItems(): Promise<void> {
    this.$wait.start('fetch items')

    // initialize pre-selected filters
    for (const filterKey in this.filters) {
      const filter = this.filters[filterKey]
      if (filter?.selected.length > 0) {
        this.activeFilters.push(filter)
      }
    }

    await Promise.all([this.loadItems()])
    this.$wait.end('fetch items')
  }

  resetFilters(): void {
    this.activeFilters = []
    this.$emit('filter-update', this.activeFilters, true)
    this.localCurrentPage = 1
    this.loadItems()
    localStorage.removeItem(this.localStorageName)
  }

  async created(): Promise<void> {
    moment.locale()
    this.pageSizeModel = this.pageSize
    this.initLocalStorage()
    await this.$nextTick()
    this.loadInitialItems()
    this.handleSearchChange = debounce(this.handleSearchChange, 500)
  }
}
</script>

<style scoped lang="scss">
.generic-table {
  min-height: 400px;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.show-on-hover {
  visibility: hidden;
}

tr:hover {
  .show-on-hover {
    visibility: visible;
  }
}

.border {
  border: 1px solid #dee2e6 !important;
}

.options-dropdown {
  .dropdown-item {
    span {
      margin-left: 6px;
    }
  }
}

.action-btn {
  margin-right: 0.5rem;
  height: max-content;
}
</style>
