<template>
  <div class="row justify-content-end mb-3 no-gutters">
    <h4 v-if="!projectDetailSettings.enableFoerderApp" key="sma-title" v-translate>Tell your friends</h4>
    <div class="social-buttons d-flex">
      <FacebookShareButton :project="project" :projectDetailSettings="projectDetailSettings" />
      <WhatsappShareButton :project="project" :projectDetailSettings="projectDetailSettings" />
      <TwitterShareButton :project="project" :projectDetailSettings="projectDetailSettings" />
      <EmailShareButton :project="project" :projectDetailSettings="projectDetailSettings" />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import EmailShareButton from '@/components/buttons/EmailShareButton.vue'
import FacebookShareButton from '@/components/buttons/FacebookShareButton.vue'
import TwitterShareButton from '@/components/buttons/TwitterShareButton.vue'
import WhatsappShareButton from '@/components/buttons/WhatsappShareButton.vue'
import { IProjectDetailSettings } from '@/types/cms'
import { IExploreProjectDetail } from '@/types/projects'

@Component({
  name: 'social-media-area-foerder-app',
  components: {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    EmailShareButton,
  },
})
export default class SocialMediaAreaFoerderApp extends Vue {
  @Prop() project!: IExploreProjectDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings
}
</script>
<style lang="scss" scoped></style>
