import { IAPIFilterCtx } from '@/types/base'
import { IFilter, IFilters } from '@/types/filters'
import { IOrganization } from '@/types/projects'

export * from './helpers/cookies'

/* TODO CHECK IF NEEDED OR CAN BE DELETED */
export function formatIBAN(ibanField) {
  ibanField.style.textTransform = 'uppercase'
  ibanField.addEventListener('input', function (event: Event) {
    const target = event.target as HTMLInputElement
    let position = target.selectionEnd
    const length = target.value.length
    target.value = target.value
      .replaceAll(/[^\dA-Za-z]/g, '')
      .replaceAll(/(.{4})/g, '$1 ')
      .trim()
    target.selectionEnd = position +=
      target.value.charAt(position - 1) === ' ' &&
      target.value.charAt(length - 1) === ' ' &&
      length !== target.value.length
        ? 1
        : 0
  })
  ibanField.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
}

export function isZO() {
  const element: HTMLElement = document.querySelector('#is_zo')
  return element.dataset.value === 'true'
}

export function getLocale(salutation = true) {
  const localeElement: HTMLElement = document.querySelector('#user_locale')
  if (localeElement && localeElement.dataset) {
    const localeString = localeElement.dataset.locale || 'en'
    if (localeString.toLowerCase() === 'de') {
      return salutation ? localeElement.dataset.salutation + '_' + 'DE' : 'de'
    }
  }
  return 'en_US'
}

export function capitalize(value: string) {
  if (!value) {
    return ''
  }
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function filtersToArray(filters: IFilters): IFilter[] {
  const filtersArray: IFilter[] = []
  for (const key in filters) {
    filtersArray.push(filters[key])
  }
  return filtersArray
}

export function addFiltersToUrl(filters?: IFilter[] | null, archived?, own = false) {
  let filterString = ''
  if (filters) {
    for (const filter of filters) {
      if (filter.selected && filter.selected.length > 0) {
        filterString += `&${filter.filterName}=${filter.selected.toString()}`
      } else if (filter.value) {
        filterString += `&${filter.filterName}=${filter.value}`
      }
    }
  }
  if (archived !== undefined) {
    filterString += archived ? '&archived=true' : '&exclude_status=archived'
  }
  if (own) {
    filterString += '&own'
  }
  return filterString
}

export function addContextToUrl(url: string, ctx: IAPIFilterCtx) {
  const filterString = addFiltersToUrl(ctx.filters, ctx.archived, ctx.own)
  const contextParameters = {
    search: null,
    ordering: null,
    format: null,
    page: ctx.page,
    page_size: ctx.pageSize,
    ...ctx.extraParams,
  }
  if (ctx.search) {
    contextParameters.search = ctx.search
  }
  if (ctx.sortBy) {
    contextParameters.ordering = `${ctx.sortDesc ? '-' : ''}${ctx.sortBy
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase()}`
  }
  if (ctx.xlsx) {
    contextParameters.format = 'xlsx'
  }
  const parametersString = Object.keys(contextParameters)
    .filter((key) => !!contextParameters[key])
    .map((key) => `&${key}=${contextParameters[key]}`)
    .join('')
  return url.endsWith('?') ? `${url}${filterString}${parametersString}` : `${url}?${filterString}${parametersString}`
}

export const API_URLS = {
  V3: {
    ARCHIVE_PROJECTS: '/api/v3/actions/archive-projects/',
    DONATE_ON_PROJECT: '/api/v3/actions/donate-on-project/',
    LOGIN: '/api/v3/actions/login/',
    TOPBAR_DATA: '/api/v3/actions/topbar-data/',
    VOTE: (slug: string) => `/api/v3/votings/${slug}/vote/`,
  },
  SIGNUP: {
    ORGANIZATION: '/organization-signup/',
    CHOOSE: '/choose-signup/',
    SIGNUP: '/signup/',
  },
  ACCOUNT: {
    PASSWORD: {
      RESET: '/account/password/reset/',
    },
  },
  FUNDING_STATISTICS: {
    PROJECTS: '/api/v4/statistics/projects/',
    LEGACY: '/api/v4/statistics/legacy/',
    MONTHLY: '/api/v4/statistics/monthly/',
    CATEGORY: '/api/v4/statistics/categories/',
    FUNDING_TYPE: '/api/v4/statistics/funding-types/',
    TOTAL_FUNDING_TYPES: '/api/v4/statistics/total-funding-count/',
    IPP: '/api/v4/statistics/ipp/',
    EXPORT: '/api/v4/statistics/funding-export/',
    EXPORT_XLSX: (filters: string) => `/api/v4/statistics/funding-export-new/?${filters}&format=xlsx`,
    ASYNC_EXPORT_XLSX: (filters: string) => `/api/v4/statistics/async-xlsx-export?${filters}&format=xlsx`,
  },
  FINANCES: {
    GETBIC: (iban: string) => `/api/v4/iban/${iban}`,
    BANK_ACCOUNTS: {
      LIST: '/api/v4/individual-bank-accounts/',
      RETRIEVE: (slug: string) => `/api/v4/individual-bank-accounts/${slug}/`,
    },
  },
  PLATFORM: {
    PLATFORM_BANK_SETTINGS: {
      LIST: '/api/v4/platform-bank-settings/',
      RETRIEVE: (id: number) => `/api/v4/platform-bank-settings/${id}/`,
    },
  },
  EXPLORE: {
    PROJECTS: {
      EXPLORE: '/api/v4/explore/',
      LOCATIONS: '/api/v4/explore/locations/',
      CATEGORIES: '/api/v4/projectcategories/explore/',
      RETRIEVE: (slug: string) => `/api/v4/explore/project/${slug}/`,
      RETRIEVE_VOTING: (slug: string, votingSlug: string) => `/api/v4/explore/project/${slug}/voting/${votingSlug}/`,
      PREVIEW: (slug: string, previewToken: string) => `/api/v4/explore/project/${slug}/?preview_token=${previewToken}`,
      FAVOURITE: (slug: string) => `/api/v4/projects/${slug}/favorite/`,
      REMOVE_FAVOURITE: (slug: string) => `/api/v4/projects/${slug}/remove-favorite/`,
    },
    ORGANIZATIONS: {
      EXPLORE: '/api/v4/explore-organizations/',
      LOCATIONS: '/api/v4/explore-organizations/locations/',
      CATEGORIES: '/api/v4/projectcategories/explore/',
      RETRIEVE: (slug: string) => `/api/v4/explore-organizations/organization/${slug}/`,
      RETRIEVE_VOTING: (slug: string, votingSlug: string) =>
        `/api/v4/explore-organizations/organization/${slug}/voting/${votingSlug}/`,
      PREVIEW: (slug: string, previewToken: string) =>
        `/api/v4/explore-organizations/organization/${slug}/?preview_token=${previewToken}`,
    },
    PAYMENT_PROVIDERS: '/api/v4/explore/public-payment-providers/',
    SETTINGS: {
      PAGE: (slug: string) => `/api/v4/explore/settings/page/${slug}/`,
      PROJECT: '/api/v4/explore/settings/project-details/',
    },
    COFUNDINGS: '/api/v4/explore/co-fundings/',
  },
  ORGANIZATIONS: {
    LIST: '/api/v4/organizations/',
    MINIMAL_LIST: '/api/v4/organizations/minimal-list/',
    INTERNAL_STATUS: '/api/v4/organizations/internal-status/',
    RETRIEVE: (slug: string) => `/api/v4/organizations/${slug}/`,
    EXPORT: (ctx: IAPIFilterCtx) => addContextToUrl('/api/v4/organizations/', { ...ctx, xlsx: true }),
    ASYNC_EXPORT: (ctx: IAPIFilterCtx) =>
      addContextToUrl('/api/v4/organizations/async-xlsx-export/', { ...ctx, xlsx: true }),
    INFO: (slug: string) => `/api/v4/organization/${slug}/info/`,
  },
  PROJECTS: {
    LIST: '/api/v4/projects/',
    MINIMAL_LIST: '/api/v4/projects/minimal-list/',
    MINIMAL_ORGANIZATION_LIST: '/api/v4/projects/minimal-organization-list/',
    ARCHIVE: '/api/v4/projects/archive/',
    RETRIEVE: (slug: string) => `/api/v4/projects/${slug}/`,
    INFO: (slug: string) => `/api/v4/project/${slug}/info/`,
    DUPLICATE: '/api/v4/projects/duplicate/',
    CHECKED_MARKED_PAYMENTS: (slug: string) => `/api/v4/projects/${slug}/check-marked-payments/`,
    DONATION_LIMITS: (slug: string) => `/api/v4/donation-limits/company/list/${slug}`,
    INTERNAL_STATUS: '/api/v4/projects/internal-status/',
    ADD_FUNDING_CODE: (slug: string) => `/api/v4/projects/${slug}/add-funding-code-to-project/`,
    REMOVE_FUNDING_CODE: (slug: string) => `/api/v4/projects/${slug}/remove-funding-code-from-project/`,
    EXPORT: (ctx: IAPIFilterCtx) => addContextToUrl('/api/v4/projects/', { ...ctx, xlsx: true }),
    ASYNC_EXPORT: (ctx: IAPIFilterCtx) =>
      addContextToUrl('/api/v4/projects/async-xlsx-export/', { ...ctx, xlsx: true }),
  },
  USERS: {
    LIST: '/api/v4/users/?frontend=true&',
    PROFILE: '/api/v4/users/profile/',
    BALANCE: '/api/v4/users/balance/',
    FAVORITE_PROJECTS: '/api/v4/users/favorite-projects/',
    STAFF_LIST: '/api/v4/staff-users/',
    INVITE: '/api/v4/users/invite/',
  },
  PERMISSIONS: {
    LIST: '/api/v4/permissions/',
    EXPORT: '/api/v4/permissions/export/',
    USERS: (permissionName: string) => `/api/v4/permissions/users/${permissionName}/`,
  },
  PRIVATE_DONATIONS: {
    LIST: '/api/v4/private-donations/',
    RETRIEVE: (id: string | number) => `/api/v4/private-donations/${id}/`,
    EXPORT: (filters: string) => `/api/v4/private-donations/?${filters}&format=xlsx`,
    ASYNC_EXPORT: (filters: string) => `/api/v4/private-donations/async-xlsx-export/?${filters}&format=xlsx`,
  },
  PRIVATE_PAYMENT_REQUESTS: {
    DONATE: '/api/v3/actions/privatepaymentrequest/',
  },
  MANUAL_BANK_TRANSFER: {
    DONATE: '/api/v4/manual-bank-transfer/',
  },
  PAYOUTS: {
    LIST: '/api/v4/payouts/',
    OWN_LIST: '/api/v4/own-payouts/',
    MINIMAL_UNPAGINATED: '/api/v4/payouts/minimal-unpaginated/',
    RETRIEVE: (id: string | number) => `/api/v4/payouts/${id}/`,
    EXPORT: (ctx: IAPIFilterCtx) => addContextToUrl('/api/v4/payouts/', { ...ctx, xlsx: true }),
    OWN_EXPORT: (ctx: IAPIFilterCtx) => addContextToUrl('/api/v4/own-payouts/', { ...ctx, xlsx: true }),
    ASYNC_EXPORT: (ctx: IAPIFilterCtx) => addContextToUrl('/api/v4/payouts/async-xlsx-export/', { ...ctx, xlsx: true }),
    OWN_ASYNC_EXPORT: (ctx: IAPIFilterCtx) =>
      addContextToUrl('/api/v4/own-payouts/async-xlsx-export/', { ...ctx, xlsx: true }),
    INVOICE_PERIODS: '/api/v4/payouts/invoice-periods/',
    ORGANIZATION_LIST: '/api/v4/payouts/organization-list/',
    ORGANIZATION_DETAILS: (organizationSlug: string) => `/api/v4/payouts/organization/${organizationSlug}/`,
    OWN_ORGANIZATION_LIST: '/api/v4/payouts/own/organization-list/',
    OWN_ORGANIZATION_DETAILS: (organizationSlug: string) => `/api/v4/payouts/own/organization/${organizationSlug}/`,
    SET_STATUS: '/api/v4/payouts/set-status/',
    RECEIPT_DOWNLOAD: (id: number) => `/api/v4/payouts/${id}/receipt/`,
    DONATION_RECEIPT_DOWNLOAD: (id: number) => `/api/v4/payouts/${id}/donation-receipt/`,
    DONATION_RECEIPTS_ZIP_DOWNLOAD: `/api/v4/payouts/donation-receipts-zip/`,
    SEPA_DOWNLOAD: (version: string, ctx: IAPIFilterCtx) => addContextToUrl(`/api/v4/payouts/sepa/${version}/`, ctx),
    SEPA_SUMMARY_DOWNLOAD: (ctx: IAPIFilterCtx) => addContextToUrl('/api/v4/payouts/sepa/summary/', ctx),
    DELETE_DOCUMENT_LOGS: (id: number) => `/api/v4/payouts/${id}/delete-document-logs/`,
  },
  REMINDERS: {
    LIST: '/api/v4/reminders/',
    PROJECT_LIST: (id: number | string) => `/api/v4/reminders/?target_type=project&target_id=${id}`,
    ORGANIZATION_LIST: (id: number | string) => `/api/v4/reminders/?target_type=organization&target_id=${id}`,
    RETRIEVE: (slug: string) => `/api/v4/reminders/${slug}/`,
    RESOLVE: (slug: string) => `/api/v4/reminders/resolve/${slug}/`,
    TEMPLATES: {
      LIST: '/api/v4/reminder-templates/',
      RETRIEVE: (slug: string) => `/api/v4/reminder-templates/${slug}/`,
    },
  },
  ADDITIONAL_STEPS: {
    CHOICE_FIELDS: '/api/v4/additional-steps/fields/',
    CHOICE_FIELDS_AVAILABLE_IN_FUNDING_STATISTICS:
      '/api/v4/additional-steps/fields/by-type/?fields__available_in_funding_statistics=True',
    CHOICE_FIELDS_AVAILABLE_IN_DOCUMENTS: '/api/v4/additional-steps/fields/?available_in_documents=True',
    PROJECT_FIELDS: '/api/v4/additional-steps/project-fields/',
    ORGANIZATION_FIELDS: '/api/v4/additional-steps/organization-fields/',
  },
  TABLE_SETTINGS: (tableName: string) => `/api/v4/table-settings/${tableName}/`,
  DOCUMENT_GROUPS: {
    ADMINISTRATION: '/site-admin/documents/administration/',
    LIST: '/api/v4/document-groups/',
    MINIMAL_LIST: '/api/v4/document-groups/minimal-list/',
    RETRIEVE: (slug: string) => `/api/v4/document-groups/${slug}/`,
    ACTIVE_DOCUMENT: (slug: string) => `/api/v4/document-groups/${slug}/active-document/`,
  },
  DOCUMENTS: {
    ADMINISTRATION: '/site-admin/documents/administration/',
    LIST: '/api/v4/documents/',
    MINIMAL_LIST: '/api/v4/documents/minimal-list/',
    CREATE: '/api/v4/documents/',
    RETRIEVE: (slug: string) => `/api/v4/documents/${slug}/`,
    PATCH: (slug: string) => `/api/v4/documents/${slug}/`,
    UPLOAD_PDF: (slug: string) => `/api/v4/documents/${slug}/upload-pdf/`,
    DELETE_PDFS: (slug: string) => `/api/v4/documents/${slug}/delete-pdfs/`,
    CREATE_PDF_LOG: `/api/v4/document-logs/`,
    CREATE_MULTI_PROJECTS_PDF_LOG: `/api/v4/document-logs/multi-projects/`,
    GENERATE_PDFS: '/api/v4/document-logs/generate-pdfs/',
    AVAILABLE_FIELDS: (slug: string) => `/api/v4/documents/${slug}/get-available-fields/`,
  },
  FILES: {
    RETRIEVE: (slug: string) => `/api/v3/ajax-upload/load-photos/?album_slug=${slug}`,
  },
  APPLICATION_TYPES: {
    MINIMAL_LIST: '/api/v4/application-types/minimal-list/',
    LIST: '/api/v4/application-types/',
    CREATE: '/api/v4/application-types/',
    RETRIEVE: (slug: string) => `/api/v4/application-types/${slug}/`,
    PATCH: (slug: string) => `/api/v4/application-types/${slug}/`,
  },
  PLATFORMSETTINGS: {
    SETTINGS: '/api/v4/platform-settings/',
    CHECK_FEATURE: (featureName: string) => `/api/v4/platform-settings/check-feature/${featureName}/`,
    CHECK_SETTING: (settingName: string) => `/api/v4/platform-settings/check-setting/${settingName}/`,
  },
  LOGS: {
    SEARCHES: '/api/v4/logs/logged-searches',
    DEFUNDS: {
      LIST: '/api/v4/logs/defunds/',
    },
    CASHINS: {
      LIST: '/api/v4/logs/cashincode/',
    },
    INTERNAL_FUNDINGS: {
      LIST: '/api/v4/logs/internal-fundings/',
      RETRIEVE: (id: number) => `/api/v4/logs/internal-fundings/${id}/`,
    },
  },
  EMAILS: {
    LOGS: {
      LIST: '/api/v4/emails/',
      REQUE: (id: number | string) => `/api/v4/emails/${id}/requeue/`,
      EXPORT: (ctx: IAPIFilterCtx) => addContextToUrl('/api/v4/emails/', { ...ctx, xlsx: true }),
      ASYNC_EXPORT: (ctx: IAPIFilterCtx) =>
        addContextToUrl('/api/v4/emails/async-xlsx-export/', { ...ctx, xlsx: true }),
    },
    TEMPLATES: {
      LIST: '/api/v4/email-templates/',
      RETRIEVE: (id: number | string) => `/api/v4/email-template/${id}/`,
      APPLICATION_TYPE: '/api/v4/email-templates/application-type/',
      APPLICATION_TYPE_DEFAULTS: (templateType: number) =>
        `/api/v4/email-templates/application-type/defaults/${templateType}/`,
    },
  },
  INDIVIDUAL_BANK_ACCOUNTS: {
    LIST: '/api/v4/individual-bank-accounts/',
  },
  COMPANIES: {
    DONATING_COMPANIES: '/api/v4/companies/donating-companies/',
    MINIMAL_LIST: '/api/v4/companies/minimal-list/',
  },
  CONSENTS: {
    EMAIL_SUBSCRIBERS: '/api/v4/email-consent-subscribers/',
    EMAIL_ANONYMIZE: '/api/v4/email-consent-subscribers/anonymize-subscribers/',
    EMAIL_EXPORTED: '/api/v4/email-consent-subscribers/set-exported/',
    EXPORT: (ctx: IAPIFilterCtx) => addContextToUrl('/api/v4/email-consent-subscribers/', { ...ctx, xlsx: true }),
  },
  FUNDING_CODE: {
    LIST: '/api/v4/funding-codes/',
    LIST_PROJECT_STEP: (slug: string) => `/api/v4/funding-codes/project-step-list/?slug=${slug}`,
    RETRIEVE: (pk: string) => `/api/v4/funding-codes/${pk}/`,
    GET_VALID_CODE: '/api/v4/funding-codes/get-valid-code',
    EXPORT: (ctx: IAPIFilterCtx) => addContextToUrl('/api/v4/funding-codes/', { ...ctx, xlsx: true }),
  },
  CONTACT_PERSON: {
    LIST: '/api/v4/contactpersons',
    RETRIEVE: (slug: string) => `/api/v4/contactpersons/${slug}/`,
  },
  FUNDING_SCOPE: {
    LIST: '/api/v4/funding-scopes',
    RETRIEVE: (slug: string) => `/api/v4/funding-scopes/${slug}/`,
  },
  VOTINGS: {
    LIST: '/api/v4/votings/',
    EXPORT: (ctx: IAPIFilterCtx) => addContextToUrl('/api/v4/votings/', { ...ctx, xlsx: true }),
    PARTICIPANTS: {
      CODE_EXPORT: (ctx: IAPIFilterCtx) => addContextToUrl('/api/v4/payouts/', { ...ctx, xlsx: true }),
      CODE_LIST: '/api/v4/voting/code-votes/',
      EMAIL_LIST: '/api/v4/voting/email-votes/',
    },
  },
  COINS: {
    CODES: {
      LIST: '/api/v4/coins/codes/',
      CAMPAIGN: (campaign: string) => `/api/v4/coins/codes/?campaign=${campaign}&`,
      EXPORT: (filters: string) => `/api/v4/coins/codes/${filters}&format=xlsx`,
      REDEEM: '/api/v4/coins/codes/cash-in-code/',
    },
  },
  CO_FUNDINGS: {
    LIST: '/api/v4/co-fundings/',
    CREATE: '/site-admin/co-fundings/create/',
    RETRIEVE: (slug: string) => `/api/v4/co-fundings/${slug}/`,
  },
  DONATION_LIMITS: {
    INTERNAL_FUNDINGS: {
      COMPANIES: '/api/v4/donation-limits/internal-funding/companies/',
      COMPANY_BUDGETS: (slug: string) => `/api/v4/donation-limits/internal-funding/companies/${slug}/`,
      MULTIPLE_COMPANY_BUDGETS: '/api/v4/donation-limits/internal-funding/multiple-companies/',
      COMPANY_BUDGET_ORGANIZATIONS: (companySlug: string, budgetSlug: string) =>
        `/api/v4/donation-limits/internal-funding/companies/${companySlug}/${budgetSlug}/`,
      COMPANY_BUDGET_ORGANIZATION_PROJECTS: (companySlug: string, budgetSlug: string, organizationSlug: string) =>
        `/api/v4/donation-limits/internal-funding/companies/${companySlug}/${budgetSlug}/${organizationSlug}/`,
    },
    COMPANIES: {
      LIST: '/api/v4/donation-limits/company/',
      MINIMAL_LIST: '/api/v4/donation-limits/company/minimal-list/',
      DETAILED_LIST: '/api/v4/donation-limits/company/detailed-list/',
    },
    COMPANIES_EXPORT: (filters: string) => `/api/v4/donation-limits/company/?${filters}&frontend=true&format=xlsx`,
    COMPANY: {
      RETRIEVE: (slug: string) => `/api/v4/donation-limits/company/${slug}/`,
    },
    CHARGES: {
      OVERALL_LIST: '/api/v4/donation-limits/charge-company/',
      LIST: (slug: string) => `/api/v4/donation-limits/charge-company/list/${slug}/`,
      MULTI_CHARGE: '/api/v4/donation-limits/charge-company/multi-charge/',
    },
  },
  COOKIES: {
    SERVICES: {
      LIST: '/api/v4/external-scripts/',
      RETRIEVE: (slug: string) => `/api/v4/external-scripts/${slug}/`,
      EXPORT: (ctx: IAPIFilterCtx) => addContextToUrl('/api/v4/external-scripts/', { ...ctx, xlsx: true }),
    },
  },
  CHANGE_HISTORY: {
    INTERNAL_FUNDING: (id: number, field: string) =>
      `/api/v4/logs/internal-fundings/${id}/field-history?field=${field}`,
  },
}

export const SITE_URLS = {
  PREVIEW: {
    ORGANIZATION: (organization: IOrganization) =>
      `/organization/${organization.slug}/?preview_token=${organization.preview_token}`,
  },
  SITE_ADMIN: {
    ORGANIZATIONS: {
      LIST: '/site-admin/organizations-old/',
      ARCHIVE_LIST: '/site-admin/organizations/archive-old/',
      CREATE: '/site-admin/organizations/create/',
      UPDATE: (slug: string) => `/site-admin/organization/${slug}/update/`,
    },
    DONATION_LIMITS: {
      LIST: '/site-admin/donation-limits/company/',
      CREATE: '/site-admin/donation-limits/company/create/',
      UPDATE: (slug: string) => `/site-admin/donation-limits/company/${slug}/update`,
    },
  },
}
