<template>
  <b-card body-class="px-4 py-2" class="shadow-sm mb-3 bg-white rounded" :title="title">
    <b-card-body class="p-0">
      <b-card-sub-title>
        <LabeledTooltip
          :title="$gettext('In current voting')"
          iconClass="fas fa-info-circle"
          tooltipClass="voting-info-tooltip"
        >
          <h6 class="mb-0 mt-0">
            <strong><translate>Voting period</translate>:</strong>
          </h6>
          <p class="mb-0">
            <strong><translate>Start</translate>:</strong>
            {{ formattedStartDate }}
          </p>
          <p class="mb-0">
            <strong><translate>End</translate>:</strong>
            {{ formattedEndDate }}
          </p>
        </LabeledTooltip>
      </b-card-sub-title>
      <div class="card-inner p-2">
        <div class="row bg-light mb-2 rounded-lg">
          <div class="col-12 d-flex my-3 p-0">
            <div class="col-4">
              <div class="d-flex justify-content-center circular-icon-div rounded-circle">
                <i class="fa fa-trophy icon-style-base" aria-hidden="true" />
              </div>
            </div>
            <div class="col-8 d-flex flex-column justify-content-center">
              <div>
                <span class="info-title">
                  <strong>
                    <translate>Rank</translate>
                  </strong>
                </span>
              </div>
              <div>
                <span class="ranking-data">
                  <p
                    v-if="currentRank !== '-'"
                    v-translate="{ count: currentRank, totalCount: totalRank }"
                    class="p-0 m-0"
                  >
                    <strong class="base-ci-color">%{ count } </strong>of %{ totalCount }
                  </p>
                  <p v-else>-</p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row bg-light rounded-lg">
          <div class="col-12 d-flex my-3 p-0">
            <div class="col-4">
              <div class="d-flex justify-content-center circular-icon-div rounded-circle">
                <i class="fa fa-thumbs-o-up icon-style-base" aria-hidden="true" />
              </div>
            </div>
            <div class="col-8 d-flex flex-column justify-content-center">
              <div>
                <span class="info-title">
                  <strong>
                    <translate>Received votes</translate>
                  </strong>
                </span>
              </div>
              <div>
                <span class="voting-data">
                  <p
                    v-translate="{ count: receivedVotes }"
                    class="p-0 m-0"
                    :translate-n="receivedVotes"
                    translate-plural="<strong class='mr-1 base-ci-color' variant='base-ci-color'>%{ count }</strong> Votes received"
                  >
                    <strong class="base-ci-color">%{ count } </strong>Vote received
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import LabeledTooltip from '@/components/utils/LabeledTooltip.vue'
import { IProject } from '@/types/projects'

@Component({
  name: 'voting-info',
  components: {
    LabeledTooltip,
  },
})
export default class VotingInfo extends Vue {
  @Prop() currentRank: number
  @Prop() totalRank: number
  @Prop() receivedVotes: number
  @Prop() project: IProject

  get title() {
    const today = new Date()
    const end = new Date(this.project.voting?.voting_end)
    if (this.project.voting && end < today) {
      return this.$gettext('Reached Rank')
    } else {
      return this.$gettext('Current Rank')
    }
  }

  get formattedStartDate() {
    return this.$moment(this.project.voting.voting_start).format(this.$gettext('dddd, DD.MM.YYYY [at] HH:mm [o clock]'))
  }

  get formattedEndDate() {
    return this.$moment(this.project.voting.voting_end).format(this.$gettext('dddd, DD.MM.YYYY [at] HH:mm [o clock]'))
  }
}
</script>
