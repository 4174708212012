<template>
  <b-input-group>
    <b-input-group-prepend is-text>
      <span :class="iconClass" />
    </b-input-group-prepend>
    <b-form-input
      v-model="valueModel"
      :debounce="debounce"
      type="text"
      :placeholder="placeholder"
      class="form-control"
      v-on="$listeners"
    />
  </b-input-group>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class InputGroup extends Vue {
  @Prop() icon!: string
  @Prop({ default: '' }) value!: string
  @Prop() placeholder?: string
  @Prop() onChange?: string
  @Prop({ default: 0 }) debounce!: number

  get valueModel(): string {
    return this.value
  }

  set valueModel(value: string) {
    this.$emit('input', value)
  }

  get iconClass(): string {
    return 'fa fa-' + this.icon
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
