<template>
  <b-modal id="duplicate-project-modal" v-model="isOpenModel" v-on="$listeners">
    <template #modal-title>
      <h5 v-translate>Duplicate</h5>
    </template>

    <template #default>
      <div v-if="!project" class="row">
        <div class="col-12">
          <p v-translate>Do you want to duplicate the following projects?</p>
        </div>
      </div>
      <div v-if="!project" class="row">
        <div class="col-12">
          <ul class="table-dropdown">
            <li v-for="proj in projects" :key="proj.slug">
              {{ proj.title }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="project" class="row">
        <div class="col-12">
          <div key="project-duplicate-message" v-translate>Do you want to duplicate <b>%{ project.title }</b>?</div>
        </div>
      </div>
    </template>

    <template #modal-footer="{ close }">
      <b-button id="close-delete-modal-btn" variant="default" class="mr-1" @click="close()">
        <translate>Close</translate>
      </b-button>
      <b-button
        id="confirm-duplication-button"
        variant="base-ci"
        :disabled="isRequestingAPI"
        @click="confirmDuplication"
      >
        <span v-if="!isRequestingAPI"><translate>Duplicate</translate></span>
        <b-spinner v-if="isRequestingAPI" small label="Spinning" />
      </b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import ToastMixin from '@/mixins/ToastMixin'
import { IMinimalProject } from '@/types/projects'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'DuplicateProjectModal',
})
export default class DuplicateProjectModal extends Mixins(ToastMixin) {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: () => [] }) projects!: IMinimalProject[]
  @Prop({ default: false }) project!: IMinimalProject | false // Because of double functionality to use the modal from single project options menu
  isRequestingAPI = false

  get isOpenModel(): boolean {
    return this.value
  }

  set isOpenModel(value: boolean) {
    this.$emit('input', value)
  }

  async confirmDuplication() {
    if (!this.projects && !this.project) {
      return
    }
    this.isRequestingAPI = true
    let slugList: string[] = []
    if (this.project) {
      slugList = [this.project.slug]
    } else {
      for (const proj of this.projects) {
        slugList.push(proj.slug)
      }
    }
    await axios
      .post(API_URLS.PROJECTS.DUPLICATE, {
        project_slugs: slugList,
      })
      .then(() => {
        this.makeToast('success', this.$gettext('Success'), this.$gettext('Successfully duplicated'))
        this.$emit('close')
        this.$emit('success')
      })
      .catch((error) => {
        this.makeToast('danger', this.$gettext('Error'), error.response.data.error)
        this.$emit('close')
        this.$emit('failed')
      })
    this.isRequestingAPI = false
  }
}
</script>
