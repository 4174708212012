<template>
  <div class="card project-card">
    <div class="image-header">
      <b-img
        :src="project.logo || '/static/img/default/project-placeholder-new.png'"
        onerror=""
        :alt="project.title + ' cover photo'"
        class="card-img-top pointer"
        @click="openProject"
      />
      <FavoriteProjectButton
        class="favorite-project-btn"
        :project="project"
        :projectDetailSettings="projectDetailSettings"
      />
      <span v-if="project.fully_funded && showDonationInfo" class="ended-badge btn-base-ci">
        <i class="far fa-check-circle" />
        <translate key="fully-funded">Fully funded</translate>
      </span>
      <span v-else-if="project.expired && showDonationInfo" class="ended-badge btn-base-ci">
        <i class="far fa-clock" />
        <translate key="finished">Finished</translate>
      </span>
    </div>
    <div v-if="!pageSettings.isVotingList && showDonationInfo" class="progress">
      <div
        class="progress-bar social_bar"
        :aria-valuetransitiongoal="getProjectPercentage(project)"
        :style="{ width: getProjectPercentage(project) + '%' }"
      />
    </div>
    <div class="card-body py-1">
      <h4 class="card-title pointer" @click="openProject">
        {{ project.title }}
      </h4>

      <p class="text-muted project-card-address">
        {{ project.organization.title }}<br />
        {{ project.organization.city }}, {{ project.organization.country }} |
        {{ project.category }}
      </p>
      <p v-if="!pageSettings.isVotingList && showDonationInfo">
        <span class="project-card-collected">
          <b class="collected-amount">{{ balance }} </b>
          <translate
            key="collected"
            :translate-params="
              pageSettings.currencyDisplay.useCurrency
                ? { coinName: '' }
                : { coinName: pageSettings.currencyDisplay.coinNamePlural }
            "
          >
            %{ coinName } collected</translate
          >
        </span>
        <br />

        <span class="project-card-percentage">
          <b class="collected-amount">{{ getProjectPercentage(project) }}% </b>
          <translate key="percentage-needs">of current need</translate>
        </span>
      </p>
      <div
        v-else-if="pageSettings.displayCurrentVotingRank"
        class="d-flex justify-content-between mb-3 rounded-lg p-2 mx-n2 bg-light"
      >
        <div class="project-card-votes">
          <span class="text-base-ci h5">{{ project.votes }}</span>
          <br />
          <span key="vote-count" v-translate>Votes received</span>
        </div>
        <div class="text-right project-card-voting-rank">
          <span class="text-base-ci h5">{{ project.voting_rank }}</span>
          <br />
          <span key="voting-rank" v-translate>Current rank</span>
        </div>
      </div>
      <p class="mb-1" v-html="truncate(project.summary, 70)" />
    </div>
    <b-card-footer>
      <b-button class="w-100 view-project-btn" variant="base-ci" @click="openProject">
        <translate v-if="shouldDisplaySupportProjectText()"> View And Support Project </translate>
        <translate v-else>View Project</translate>
      </b-button>
    </b-card-footer>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'

import FavoriteProjectButton from '@/components/explore/FavoriteProjectButton.vue'
import LocaleMixin from '@/mixins/LocaleMixin'
import { ExploreModule } from '@/store/modules/explore'
import { IProjectDetailSettings } from '@/types/cms'
import { IPageSettings } from '@/types/cms'
import { IExploreProjectMinimal } from '@/types/projects'
import { ceilValueIfBelowX } from '@/utils/helpers/math'

@Component({
  name: 'project-card',
  components: {
    FavoriteProjectButton,
  },
})
export default class ProjectCard extends Mixins(LocaleMixin) {
  @Prop() project!: IExploreProjectMinimal
  @Prop() pageSettings: IPageSettings

  imageHovered = false
  buttonHovered = false

  getProjectPercentage(project: IExploreProjectMinimal): number {
    return ceilValueIfBelowX((project.balance / project.goal) * 100, 99)
  }

  get balance(): string {
    const { balance } = this.project

    return this.pageSettings.currencyDisplay.useCurrency
      ? this.toCurrency(balance, { currency: this.pageSettings.currencyDisplay.currencyIsoCode })
      : this.toCoins(balance, this.pageSettings.currencyDisplay.factorOfCurrencyToCoin)
  }

  get projectDetailSettings(): IProjectDetailSettings {
    return ExploreModule.projectDetailSettings
  }

  get showDonationInfo(): boolean {
    if (this.project?.is_status_archived) {
      return this.projectDetailSettings?.displayArchivedCoinBalance !== 3
    }
    return this.projectDetailSettings?.displayCurrentCoinBalance !== 3
  }

  truncate(title: string, length: number): string {
    if (title.length > length) {
      return `${title.slice(0, length - 3)} ...`
    }
    return title
  }

  shouldDisplaySupportProjectText() {
    let condition = false

    if (this.pageSettings.isVotingList) {
      condition =
        !this.project.fully_funded &&
        !this.project.expired &&
        !this.project.is_status_archived &&
        this.pageSettings.voting?.active
    } else {
      condition = !this.project.fully_funded && !this.project.expired && !this.project.is_status_archived
    }

    return condition
  }

  openProject() {
    // Filter out slug and preview_token from query params, as they are not needed in the project detail page and lead to 404
    const query = { ...this.$route.query, slug: undefined, preview_token: undefined }

    if (this.pageSettings.isVotingList && this.pageSettings.voting.slug) {
      this.$router.push({
        name: 'project-detail-voting',
        params: { projectSlug: this.project.slug, votingSlug: this.pageSettings.voting.slug },
        query,
      })
    } else {
      this.$router.push({
        name: 'project-detail',
        params: { projectSlug: this.project.slug },
        query,
      })
    }

    if (this.pageSettings.singlePage) {
      // Scrollbehavior on router doesn't always work,
      // so we double down here to make sure the details are in view
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) <- doesn't work for some reason
      window.scrollTo(0, 0)
    } else {
      location.reload()
    }
  }
}
</script>
