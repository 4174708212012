<template>
  <div v-if="userProfile && !userProfile.is_anonymous && projectDetailSettings?.enableFavorites">
    <span
      style="color: var(--base-ci)"
      :class="[favouriteIconClass, isFavourite ? '' : 'pointer', 'fa-heart h2']"
      @click="setFavourite"
      @mouseover="favouriteMouseover = true"
      @mouseleave="favouriteMouseover = false"
    />
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import ToastMixin from '@/mixins/ToastMixin'
import UserMixin from '@/mixins/UserMixin'
import { IProjectDetailSettings } from '@/types/cms'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { IExploreProjectDetail, IExploreProjectMinimal } from '@/types/projects'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'favorite-project-button',
})
export default class FavoriteProjectButton extends Mixins(ToastMixin, UserMixin) {
  @Prop() project!: IExploreProjectMinimal | IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings
  favouriteMouseover = false

  get isFavourite(): boolean {
    if (this.userProfile?.favorite_projects) {
      return this.userProfile.favorite_projects.some((fav) => fav.slug === this.project.slug)
    }
    return false
  }

  get favouriteIconClass(): string {
    if (this.isFavourite) {
      return 'fad'
    } else if (this.favouriteMouseover) {
      return 'fas'
    }
    return 'far'
  }

  async setFavourite() {
    if (this.userProfile.is_anonymous) {
      return true
    }

    await (this.isFavourite
      ? axios
          .post(API_URLS.EXPLORE.PROJECTS.REMOVE_FAVOURITE(this.project.slug))
          .then(() => {
            this.makeToast('warning', this.$gettext('Removed favorite'), this.project.title)
          })
          .catch(() => {
            this.makeToast('danger', 'Error', 'Failed to remove favorite')
          })
      : axios
          .post(API_URLS.EXPLORE.PROJECTS.FAVOURITE(this.project.slug))
          .then(() => {
            this.makeToast('success', this.$gettext('Added new favorite'), this.project.title)
          })
          .catch(() => {
            this.makeToast('danger', 'Error', 'Failed to add favorite')
          }))

    this.fetchFavoriteProjects()
  }
}
</script>
