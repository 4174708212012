<template>
  <router-view />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class DocumentAdministration extends Vue {}
</script>

<style scoped lang="scss"></style>
