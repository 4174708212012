<template>
  <b-modal id="info-modal" v-model="isOpenModel" centered size="xxl" v-on="$listeners">
    <template #modal-title>
      <h5 v-translate>{{ modalTitle }}</h5>
    </template>

    <template #default>
      <v-wait for="fetch">
        <template slot="waiting">
          <div class="row">
            <div class="col-12 justify-content-center mt-4">
              <b-spinner variant="primary" label="Spinning" />
            </div>
          </div>
        </template>
        <div v-html="infoModalContent" />
      </v-wait>
    </template>

    <template #modal-footer="{ close }">
      <b-button id="close-delete-modal-btn" variant="default" class="mr-1" @click="close()">
        <translate>Close</translate>
      </b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { IOrganization, IProject } from '@/types/projects'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'info-modal',
})
export default class InfoModal extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: null }) project!: IProject
  @Prop({ default: null }) organization!: IOrganization
  infoModalContent = ''

  @Watch('project')
  onProjectChange(): void {
    this.fetchModalContent()
  }

  @Watch('organization')
  onOrganizationChange(): void {
    this.fetchModalContent()
  }

  get modalTitle(): string {
    if (this.project) {
      return this.project.title
    } else if (this.organization) {
      return this.organization.title
    }
    return ''
  }

  get infoUrl(): string {
    if (!(this.organization || this.project)) {
      return ''
    }
    return this.project
      ? API_URLS.PROJECTS.INFO(this.project.slug)
      : API_URLS.ORGANIZATIONS.INFO(this.organization.slug)
  }

  get isOpenModel(): boolean {
    return this.value
  }

  set isOpenModel(value: boolean) {
    this.$emit('input', value)
  }

  async fetchModalContent(): Promise<void> {
    this.$wait.start('fetch info')
    await axios
      .get(this.infoUrl)
      .then((response) => {
        this.infoModalContent = response.data
      })
      .catch(() => {
        if (this.project) {
          this.infoModalContent = this.$gettext('Failed to load project data')
        } else if (this.organization) {
          this.infoModalContent = this.$gettext('Failed to load organization data')
        }
      })
    this.$wait.end('fetch info')
  }

  async created(): Promise<void> {
    this.fetchModalContent()
  }
}
</script>
