<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

import { ISelectOption } from '@/types/base'

@Component
export default class DateFilterMixin extends Vue {
  datePickerRangeSelectValue?: string = null
  datePickerRanges = {
    today: {
      range: {
        from: this.$moment().format('YYYY-MM-DD'),
        until: this.$moment().format('YYYY-MM-DD'),
      },
      label: this.$gettext('Today'),
    },
    week: {
      range: {
        from: this.$moment().startOf('week').format('YYYY-MM-DD'),
        until: this.$moment().format('YYYY-MM-DD'),
      },
      label: this.$gettext('This week'),
    },
    month: {
      range: {
        from: this.$moment().startOf('month').format('YYYY-MM-DD'),
        until: this.$moment().format('YYYY-MM-DD'),
      },
      label: this.$gettext('This month'),
    },
    yesterday: {
      range: {
        from: this.$moment().subtract(1, 'days').format('YYYY-MM-DD'),
        until: this.$moment().subtract(1, 'days').format('YYYY-MM-DD'),
      },
      label: this.$gettext('Yesterday'),
    },
    last_week: {
      range: {
        from: this.$moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
        until: this.$moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
      },
      label: this.$gettext('Last week'),
    },
    last_month: {
      range: {
        from: this.$moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        until: this.$moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      },
      label: this.$gettext('Last month'),
    },
    this_quarter: {
      range: {
        from: this.$moment().startOf('quarter').format('YYYY-MM-DD'),
        until: this.$moment().endOf('quarter').format('YYYY-MM-DD'),
      },
      label: this.$gettext('This quarter'),
    },
    last_quarter: {
      range: {
        from: this.$moment().subtract(1, 'quarters').startOf('quarter').format('YYYY-MM-DD'),
        until: this.$moment().subtract(1, 'quarters').endOf('quarter').format('YYYY-MM-DD'),
      },
      label: this.$gettext('Last quarter'),
    },
  }

  get datePickerRangeSelectOptions(): ISelectOption[] {
    return [
      {
        value: null,
        text: this.$gettext('Pre-defined date ranges'),
      },
      ...Object.keys(this.datePickerRanges).map((key) => {
        return {
          value: key,
          text: this.datePickerRanges[key].label,
        }
      }),
    ]
  }
}
</script>
