<template>
  <div class="row pt-4 px-0 pb-2 mb-2" style="background-color: #ececec">
    <div class="col-12">
      <p id="backend_content_top" class="p-large">
        <a href="/site-admin/">
          <span class="fa fa-home" />
        </a>
        <span v-for="breadcrumb in breadcrumbs" :key="breadcrumb.title">
          <span class="breadcrumb-arrow fa fa-angle-right" />
          <router-link v-if="breadcrumb.routerUrl" :to="breadcrumb.url">{{ breadcrumb.title }}</router-link>
          <a v-else :href="breadcrumb.url">{{ breadcrumb.title }}</a>
        </span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

interface IBreadcrumb {
  title: string
  url: string
  routerUrl?: boolean
}

@Component({
  name: 'breadcrumb-header',
})
export default class BreadcrumbHeader extends Vue {
  @Prop({ default: () => [] }) breadcrumbs!: IBreadcrumb[]
}
</script>

<style scoped>
.breadcrumb-arrow {
  margin: 0 5px;
}
</style>
