<template>
  <span class="labels" v-html="label" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IOrganization } from '@/types/projects'

@Component({
  name: 'organization-non-profit-review-status',
})
export default class OrganizationReviewStatus extends Vue {
  @Prop() organization!: IOrganization
  @Prop({ default: false }) showNOELabel!: boolean

  get label(): string {
    let labelHtml = '-'
    const status = this.organization.review_status
    if (status) {
      switch (status.choice) {
        case 2: {
          labelHtml = `<span class="badge badge-success">${status.display}</span>`
          break
        }
        case 3: {
          labelHtml = `<span class="badge badge-danger">${status.display}</span>`
          break
        }
        case 4: {
          {
            labelHtml = `<span class="badge badge-success">${status.display}</span>`
            // No default
          }
          break
        }
      } // accepted
    }
    if (this.showNOELabel && this.organization.is_proof_expired && this.organization.is_proof_uploaded) {
      const noeLabel = `<span class="badge badge-danger">${this.$gettext('Proof expired')}</span>`
      if (labelHtml.length > 1) {
        labelHtml += noeLabel
      } else {
        labelHtml = noeLabel
      }
    }
    if (this.organization.needs_review) {
      const needsReviewLabel = `<span class="badge badge-warning">${this.$gettext('Needs review')}</span>`
      if (labelHtml.length > 1) {
        labelHtml += needsReviewLabel
      } else {
        labelHtml = needsReviewLabel
      }
    }
    return labelHtml
  }
}
</script>
