<template>
  <div>
    <template v-if="!projectDetailSettings.projectPromoterDonation">
      <b-alert v-if="project.fully_funded" show>
        <span class="fa fa-info-circle float-left" />
        <translate>This project is fully funded already.</translate>
      </b-alert>
      <b-alert v-if="project.expired" show>
        <span class="fa fa-info-circle float-left" />
        <translate>This project has ended already.</translate>
      </b-alert>
    </template>

    <CodeInput
      v-model="code"
      :label="$gettext('Redeem donation code')"
      class="mb-2"
      :blockLength="codeFormat?.blockLength || 4"
      :errorMessage="errorMessage"
      :isValid="codeInputValid"
      @input="redemptionError = null"
    />
    <b-button variant="base-ci" block :disabled="!codeInputValid" @click="redeemCode">
      <b-spinner v-if="saving" small />
      <translate v-else key="redeem-code">Redeem</translate>
    </b-button>
    <b-alert variant="success" class="mt-2 mb-0" dismissible :show="!!redemptionSuccessMessage">
      <span>{{ redemptionSuccessMessage }}</span>
    </b-alert>
    <b-alert
      variant="danger"
      class="mt-2 mb-0"
      dismissible
      :show="!!redemptionErrorMessage"
      @dismissed="redemptionError = null"
    >
      <span>{{ redemptionErrorMessage }}</span>
    </b-alert>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import CodeInputAreaMixin from '@/mixins/CodeInputAreaMixin'
import ToastMixin from '@/mixins/ToastMixin'
import UserMixin from '@/mixins/UserMixin'
import { IExploreProjectDetail } from '@/types/projects'
import { addContextToUrl, API_URLS } from '@/utils/helpers'

import CodeInput from './CodeInput.vue'

@Component({
  name: 'coin-code-input-area',
  components: {
    CodeInput,
  },
})
export default class CoinCodeInputArea extends Mixins(CodeInputAreaMixin, UserMixin, ToastMixin) {
  @Prop() project!: IExploreProjectDetail

  get redemptionSuccessMessage(): string {
    if (this.redemption && this.currencyDisplay) {
      return this.$gettextInterpolate(this.$gettext('Successfuly redeemed code worth %{ value }'), {
        value: this.formatCurrency(
          this.currencyDisplay.useCurrency
            ? this.toLocalisedDecimal(this.redemption.cents / 100)
            : this.redemption.coins
        ),
      })
    }
  }

  get redemptionErrorMessage(): string {
    if (this.redemptionError) {
      return this.redemptionError?.code_string.join(' - ')
    }
  }

  formatCurrency(value: number): string {
    const displayName = this.currencyDisplay.useCurrency
      ? this.currencyDisplay.currencySymbol
      : this.currencyDisplay.coinNamePlural
    return `${value} ${displayName}`
  }

  redeemCode(): void {
    this.saving = true
    this.redemption = null
    if (!this.userProfile.is_anonymous) {
      axios.defaults.headers.common['X-CSRFToken'] = this.$cookies.get('csrftoken')
    }
    const redeemUrl = addContextToUrl(API_URLS.COINS.CODES.REDEEM, {
      extraParams: { 'project-slug': this.$route.params.projectSlug },
    })
    const data = { code_string: this.code.replaceAll(/[^\s\w]/gi, '') }

    axios
      .post(redeemUrl, data)
      .then((response) => {
        this.saving = false
        this.redemption = response.data
        this.redemptionError = null
        this.code = '' // clear input field
        if (this.userProfile.is_anonymous) {
          this.fetchUserProfile(true)
          this.$emit('anon-redeemed')
        } else {
          this.$emit('non-anon-redeemed')
        }
      })
      .catch((error) => {
        this.saving = false
        this.redemption = null
        this.redemptionError = error.response.data
        if ('response' in error && error.response.data && error.response.data.code_string) {
          this.makeToast('danger', this.$gettext('Error'), error.response.data.code_string)
        } else {
          this.makeToast('danger', this.$gettext('Error'), `${this.$gettext('Failed to redeem code')}`)
        }
      })
  }
}
</script>
