<template>
  <b-card body-class="px-4 py-2" class="shadow-sm mb-3 rounded" :title="$gettext('Co-Funding event')">
    <b-card-body class="p-2">
      <div class="row">
        <div v-for="coFunding in coFundings" :key="coFunding.slug" class="col-12 bg-light py-3 mb-2 rounded">
          <span
            :key="coFunding.slug"
            v-translate="{
              amountDonated: coFunding.budget_spent.in_currency_display,
              totalAmount: coFunding.budget.in_currency_display,
            }"
            class="fact-value project-balance mr-1 font-weight-bold h5"
          >
            %{ amountDonated } <span class="font-weight-light">of</span> %{ totalAmount }
          </span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ICoFunding } from '@/types/finances'

@Component({
  name: 'voting-info',
})
export default class VotingInfo extends Vue {
  @Prop() coFundings!: ICoFunding[]
}
</script>
