<template>
  <div class="d-flex form-group" style="gap: 0.25rem">
    <fieldset
      v-for="(maxLength, index) in maxLengths"
      :key="index"
      :class="index === 0 ? '' : 'col'"
      :style="{ width: index === 0 ? '2rem' : 'auto' }"
    >
      <b-form-input
        :key="index"
        :ref="`input-${index + 1}`"
        v-model="inputs[index]"
        :disabled="index === 0"
        class="form-control w-100 text-center p-0"
        :maxlength="maxLength"
        type="text"
        :readonly="isReadOnly"
        @input="checkInputs(index)"
        @paste="handlePaste($event, index)"
      />
    </fieldset>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class CodeStringInput extends Vue {
  @Prop({ default: 0 }) private count!: number
  @Prop({ default: '' }) private _codeString!: string
  @Prop({ default: false }) isReadOnly!: boolean

  @Prop({ default: () => [1, 4, 4] }) private maxLengths!: number[]

  private inputs: string[] = Array.from<string>({ length: this.count }).fill('')

  codeString = ''

  created(): void {
    this.setCodeString(this._codeString)
  }

  @Watch('_codeString')
  private onCodeStringChange(newCodeString: string): void {
    this.setCodeString(newCodeString)
  }

  private checkInputs(index: number): void {
    const maxLength = this.maxLengths[index]

    if (this.inputs[index].length === maxLength) {
      if (index === this.inputs.length - 1) {
        const value = this.inputs.join('')
        this.$emit('completed', value)
        this.$emit('update:codeString', value)
      } else {
        const input = this.$refs[`input-${index + 2}`][0] as HTMLInputElement
        input.focus()
      }
    } else if (this.inputs[index].length === 0 && index > 0) {
      // Springt zum vorherigen Eingabefeld, wenn das aktuelle Eingabefeld leer ist
      const input = this.$refs[`input-${index}`][0] as HTMLInputElement
      input.focus()
    } else {
      const input = this.$refs[`input-${index + 1}`][0] as HTMLInputElement
      input.focus()
    }
  }

  private setCodeString(codeString: string): void {
    const inputLengths = [1, 4, 4]
    let startIndex = 0

    for (const [i, inputLength] of inputLengths.entries()) {
      let inputString = ''
      if (i === 0) {
        inputString = 'F' // Set the first character of the first inputString to 'F'
        startIndex++
      } else {
        inputString = codeString.slice(startIndex, startIndex + inputLength)
        startIndex += inputLength
      }
      this.$set(this.inputs, i, inputString) // Use Vue.set or this.$set to update the inputs array
    }
  }

  private handlePaste(event: ClipboardEvent, index: number): void {
    event.preventDefault()
    const pastedText = event.clipboardData.getData('text/plain')

    let inputIndex = index
    let charIndex = 0

    if (pastedText.length > 0) {
      charIndex = 1
    }

    while (charIndex < pastedText.length && inputIndex < this.inputs.length) {
      const maxLength = this.maxLengths[inputIndex]
      const pastedValue = pastedText.slice(charIndex, maxLength)
      charIndex += maxLength
      this.codeString = pastedValue
      this.$set(this.inputs, inputIndex, pastedValue)
      this.checkInputs(inputIndex)
      inputIndex++
    }
  }
}
</script>
