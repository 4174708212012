<template>
  <div class="url-button-wrapper">
    <span class="shadow p-2" :class="{ active: showCopied }">
      <translate>Copied URL</translate>
    </span>
    <b-button variant="default" @click="copyURL">
      <span class="fas fa-link" />
    </b-button>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'sf-alert',
})
export default class CopyURLButton extends Vue {
  showCopied = false

  copyURL(): void {
    navigator.clipboard.writeText(window.location.href)
    new Promise((resolve) => {
      this.showCopied = true
      setTimeout(resolve, 4000)
    }).then(() => {
      this.showCopied = false
    })
  }
}
</script>

<style lang="scss" scoped>
.url-button-wrapper {
  & > span {
    display: none;
    position: absolute;
    text-align: center;
    transform: translateY(-65px) translateX(-30%);
    background-color: #81c784;
    color: white;
    border-radius: 1rem;
    &.active {
      display: inline;
    }
  }
}
</style>
