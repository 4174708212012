import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'

import { BvTableFieldArrayWithStickColumn, ITableSettings, TGenericObject } from '@/types/base'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'table-settings-mixin',
})
export default class TableSettingsMixin extends Vue {
  tableName = ''
  fields: BvTableFieldArrayWithStickColumn = []
  tableSettingsModalOpen = false
  tableSettings: ITableSettings | null = null

  get tableSettingsFields(): ({ key: string; label: string } | string)[] {
    const fieldsArray: ({ key: string; label: string } | string)[] = []
    for (let i = 0; i < this.fields.length; i++) {
      const field = this.fields[i]
      if (typeof field === 'object') {
        if (field.label === '#') {
          continue
        }
        if (field.key.startsWith('additional_field__')) {
          continue
        }
        fieldsArray.push({ key: field.key, label: field.label })
      } else if (typeof field === 'string') {
        if (field === '#') {
          continue
        }
        fieldsArray.push(field)
      }
    }
    const additionalFields = this.tableSettings.table_fields.additional_fields
    if (additionalFields) {
      for (const additionalStepKey of Object.keys(additionalFields)) {
        const additionalStep = this.tableSettings.table_fields.additional_fields[additionalStepKey]
        fieldsArray.push(additionalStepKey)
        for (const additionalFieldKey of Object.keys(additionalStep)) {
          const additionalFieldLabel = additionalStep[additionalFieldKey]
          fieldsArray.push({
            key: additionalFieldKey,
            label: additionalFieldLabel,
          })
        }
      }
    }
    return fieldsArray
  }

  get fieldsFiltered(): BvTableFieldArrayWithStickColumn {
    return this.fields.filter((field) => {
      if (!this.tableSettings) {
        return true
      }
      if (typeof field === 'string') {
        return !this.tableSettings.hidden_fields.includes(field)
      }
      return !this.tableSettings.hidden_fields.includes(field.key)
    })
  }

  async loadTableFields(): Promise<void> {
    await axios.get<ITableSettings>(API_URLS.TABLE_SETTINGS(this.tableName)).then((response) => {
      this.tableSettings = response.data
      const additionalFields = this.tableSettings.table_fields.additional_fields
      if (additionalFields) {
        for (const additionalStepKey of Object.keys(additionalFields)) {
          const additionalStep = this.tableSettings.table_fields.additional_fields[additionalStepKey]
          for (const additionalFieldKey of Object.keys(additionalStep)) {
            const additionalFieldLabel = additionalStep[additionalFieldKey]
            const actualSlug = additionalFieldKey.split('additional_field__')[1]
            this.fields.push({
              key: additionalFieldKey,
              label: additionalFieldLabel,
              formatter: (x: undefined, y: string, item: TGenericObject) => {
                // item can be IProject or IOrganization
                if (item.additional_field_values[actualSlug]) {
                  return item.additional_field_values[actualSlug].display_value
                } else {
                  if ('organization' in item && item.organization.additional_field_values[actualSlug]) {
                    return item.organization.additional_field_values[actualSlug]
                  }
                  return '-'
                }
              },
            })
          }
        }
      }
    })
  }

  tableSettingsSaveSuccess(response: ITableSettings): void {
    this.tableSettings = response
    this.tableSettingsModalOpen = false
  }

  async created(): Promise<void> {
    this.loadTableFields()
  }
}
