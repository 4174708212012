import { Component, Prop, Vue } from 'vue-property-decorator'

import { BvTableFieldArrayWithStickColumn } from '@/types/base'
import { IFilter, IFilterInit, IFilters } from '@/types/filters'

@Component({
  name: 'persistent-filters-mixin',
})
export default class PersistentFiltersMixin extends Vue {
  @Prop({ default: null }) localStorageName!: string | null
  @Prop() fields!: BvTableFieldArrayWithStickColumn
  filters: IFilters = {}
  activeFilters: IFilter[] = []

  saveFiltersAndEmit(): void {
    localStorage[this.localStorageName] = JSON.stringify(this.activeFilters)
    this.$emit('filter-update', this.activeFilters)
  }

  applyFilter(filter: IFilter): void {
    this.activeFilters = this.activeFilters.filter((_filter) => _filter.filterName !== filter.filterName)
    this.activeFilters.push(filter)
    this.activeFilters = this.activeFilters.filter((filter) => filter.selected.length)
    this.saveFiltersAndEmit()
  }

  resetFilters(): void {
    for (const key in this.filters) {
      this.filters[key].selected = []
    }
    this.activeFilters = []
    this.saveFiltersAndEmit()
  }

  getFilter(filterName: string): IFilterInit {
    return this.filters[filterName]
  }

  created(): void {
    if (this.localStorageName) {
      try {
        const fieldNames = new Set(this.fields.map((field) => (typeof field === 'string' ? field : field.key)))

        this.activeFilters = JSON.parse(localStorage[this.localStorageName]).filter(
          (filter: IFilter) => filter.selected.length
        )

        this.activeFilters = this.activeFilters.filter((filter) => fieldNames.has(filter.fieldName))
        const activeFilterNames = new Set(this.activeFilters.map((filter) => filter.filterName))

        // eslint-disable-next-line unicorn/no-array-reduce
        const activeFiltersObj = this.activeFilters.reduce((result, item: IFilter) => {
          result[item.filterName] = item.selected
          return result
        }, {})

        for (const filterKey in this.filters) {
          const filter = this.filters[filterKey]
          if (activeFilterNames.has(filter.filterName)) {
            filter.selected = activeFiltersObj[filter.filterName]
          }
        }
        this.$emit('filter-update', this.activeFilters)
      } catch {
        localStorage.removeItem(this.localStorageName)
      }
    }
  }
}
