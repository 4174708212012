import axios from 'axios'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { IPageSettings, IProjectDetailSettings } from '@/types/cms'
import { ICoFunding } from '@/types/finances'
import { addContextToUrl, API_URLS } from '@/utils/helpers'

@Module({ dynamic: true, store, name: 'platformFeatures' })
class Explore extends VuexModule {
  pageSettingsMap: { [key: string]: IPageSettings } = {}
  projectDetailSettings: IProjectDetailSettings = null
  projectDetailImportantSettings: null
  coFundings: ICoFunding[] = []

  @Mutation
  SET_PAGE_SETTINGS(object: { slug: string; pageSettings: IPageSettings }) {
    this.pageSettingsMap[object.slug] = object.pageSettings
  }

  @Action({ commit: 'SET_PAGE_SETTINGS' })
  async fetchPageSettings(fetchContext: { slug: string; previewToken?: string | string[] }) {
    let settingsUrl = API_URLS.EXPLORE.SETTINGS.PAGE(fetchContext.slug)
    if (fetchContext.previewToken) {
      settingsUrl = addContextToUrl(settingsUrl, { extraParams: { preview_token: fetchContext.previewToken } })
    }
    const pageSettings = await axios
      .get(settingsUrl)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw new Error(error.message)
      })
    return { slug: fetchContext.slug, pageSettings }
  }

  @Mutation
  SET_PROJECT_DETAIL_SETTINGS(projectSettings: IProjectDetailSettings) {
    this.projectDetailSettings = projectSettings
  }

  @Action({ commit: 'SET_PROJECT_DETAIL_SETTINGS' })
  async fetchProjectDetailSettings() {
    const projectSettings = await axios
      .get(API_URLS.EXPLORE.SETTINGS.PROJECT)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw new Error(error.message)
      })
    return projectSettings
  }

  @Mutation
  SET_COFUNDINGS(coFundings: ICoFunding[]) {
    this.coFundings = coFundings
  }

  @Action({ commit: 'SET_COFUNDINGS' })
  async fetchCofundings() {
    const coFundings = await axios
      .get(API_URLS.EXPLORE.COFUNDINGS)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw new Error(error.message)
      })
    return coFundings
  }
}

export const ExploreModule = getModule(Explore)
