<template>
  <b-modal id="delete-modal" centered v-bind="{ ...$attrs, ...$props }" v-on="$listeners">
    <template #modal-title>
      <h5 v-translate>Verify your vote</h5>
    </template>

    <template #default>
      <p v-translate>You have to verify your vote</p>
      <p v-translate>
        We sent you an e-mail to the given address which contains a verification link. With clicking on this link your
        vote will be counted.
      </p>
      <SfAlert variant="warning" icon="exclamation-triangle" iconWeight="fal" show>
        <translate> Your vote will only count if you clicked on the verification link. </translate>
        <br /><br />
        <translate> Got no e-mail? Please check your spam folder. </translate>
      </SfAlert>
    </template>

    <template #modal-footer="{ close }">
      <b-button id="close-delete-modal-btn" variant="default" class="mr-1" @click="close">
        <translate>Close</translate>
      </b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

import SfAlert from '@/components/SfAlert.vue'

@Component({
  name: 'verify-vote-modal',
  components: {
    SfAlert,
  },
})
export default class VerifyVoteModal extends Vue {}
</script>
