<template>
  <div>
    <div v-if="selectedFieldsCount > 0" class="ag-status-name-value ag-status-panel ag-status-panel-total-row-count">
      <span><translate>Selected fields</translate>: </span>
      <span class="ag-status-name-value-value ml-1">{{ selectedFieldsCount }}</span>
    </div>
    <div
      v-if="selectedCurrencyCellsCount > 0"
      class="ag-status-name-value ag-status-panel ag-status-panel-total-row-count"
    >
      <span><translate>Sum</translate>: </span>
      <span class="ag-status-name-value-value ml-1">{{ selectedCurrencyCellsSumAsCurrency }}</span>
    </div>
    <div
      v-if="selectedCurrencyCellsCount > 0"
      class="ag-status-name-value ag-status-panel ag-status-panel-total-row-count"
    >
      <span><translate>Average</translate>:</span>
      <span class="ag-status-name-value-value ml-1">{{ selectedCurrencyCellsAvgAsCurrency }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { IStatusPanelParams } from 'ag-grid-enterprise'
import { Component, Mixins } from 'vue-property-decorator'

import LocaleMixin from '@/mixins/LocaleMixin'

@Component({
  name: 'currency-aggregation-component',
})
export default class CurrencyAggregationComponent extends Mixins(LocaleMixin) {
  params: IStatusPanelParams = null
  selectedCurrencyCellsSum = 0
  selectedCurrencyCellsAvg = 0
  selectedFieldsCount = 0
  selectedCurrencyCellsCount = 0
  get selectedCurrencyCellsSumAsCurrency() {
    return this.centsToCurrency(this.selectedCurrencyCellsSum)
  }

  get selectedCurrencyCellsAvgAsCurrency() {
    return this.centsToCurrency(this.selectedCurrencyCellsAvg)
  }

  get showAverage() {
    return this.params.api
  }

  onRangeSelectionChanged() {
    const cellRanges = this.params.api.getCellRanges()
    this.selectedCurrencyCellsSum = 0
    this.selectedCurrencyCellsAvg = 0
    this.selectedFieldsCount = 0
    this.selectedCurrencyCellsCount = 0
    for (const cellRange of cellRanges) {
      const minRow = Math.min(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex)
      const maxRow = Math.max(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex)
      for (const column of cellRange.columns) {
        const colDef = column.getColDef()
        if (Array.isArray(colDef.cellClass)) {
          if (!colDef.cellClass.includes('currencyFormat')) {
            continue
          }
        } else if (colDef.cellClass !== 'currencyFormat') {
          continue
        }
        for (let i = minRow; i <= maxRow; i++) {
          const value = this.params.api.getCellValue({
            rowNode: this.params.api.getDisplayedRowAtIndex(i),
            colKey: column.getColId(),
          })
          if (typeof value === 'number') {
            this.selectedCurrencyCellsSum += value * 100
            this.selectedCurrencyCellsCount++
          }
        }
      }
      this.selectedFieldsCount += cellRange.columns.length * (maxRow - minRow + 1)
    }
    if (this.selectedCurrencyCellsCount > 0) {
      this.selectedCurrencyCellsAvg = this.selectedCurrencyCellsSum / this.selectedCurrencyCellsCount
    }
  }

  beforeMount() {
    this.params.api.addEventListener('rangeSelectionChanged', this.onRangeSelectionChanged.bind(this))
    this.params.api.addEventListener('filterChanged', this.onRangeSelectionChanged.bind(this))
  }
}
</script>
