<template>
  <b-modal id="table-settings-modal" v-model="isOpenModel" centered size="lg" scrollable>
    <template #modal-title>
      <p v-translate class="modal-header-lg">Table Settings</p>
    </template>

    <template #default>
      <div v-for="(field, key) in fields" :key="key" :class="{ 'mt-3 mb-1': !field.key }">
        <b-form-checkbox
          v-if="field.key"
          :id="'table-settings' + field.key"
          v-model="formData[field.key]"
          :name="'table-settings' + field.key"
        >
          {{ field.label }}
        </b-form-checkbox>
        <span v-else class="text-muted h6">{{ field }}</span>
      </div>
    </template>

    <template #modal-footer="{ close }">
      <b-button variant="default" class="mr-1" @click="close()">
        <translate>Close</translate>
      </b-button>
      <b-button variant="base-ci" :disabled="saving" @click="saveTableSettings">
        <b-spinner v-if="saving" small class="mr-1" />
        <translate>Save</translate>
      </b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import ToastMixin from '@/mixins/ToastMixin'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'TableSettingsModal',
})
export default class TableSettingsModal extends Mixins(ToastMixin) {
  @Prop({ default: () => [] }) fields!: { key: string; label: string }[]
  @Prop({ default: () => [] }) hiddenFields!: string[]
  @Prop({ default: '' }) tableName!: string
  @Prop({ default: false }) isOpen!: boolean
  formData: { [key: string]: boolean } = {}
  saving = false

  get isOpenModel(): boolean {
    return this.isOpen
  }

  set isOpenModel(value: boolean) {
    this.$emit('update:isOpen', value)
  }

  async saveTableSettings() {
    this.saving = true
    const tableSettingsRes = await axios
      .patch(
        API_URLS.TABLE_SETTINGS(this.tableName),
        {
          [this.tableName]: Object.keys(this.formData)
            .map((key) => (this.formData[key] ? false : key))
            .filter(Boolean)
            .join('/'),
        },
        { headers: { 'X-CSRFToken': this.$cookies.get('csrftoken') } }
      )
      .then((response) => response.data)
      .catch(() => {
        this.makeToast('danger', this.$gettext('Error'), this.$gettext('Could not save new table settings'))
      })
    this.saving = false
    this.$emit('success', tableSettingsRes)
  }

  created() {
    for (const key in this.fields) {
      this.$set(this.formData, this.fields[key].key, !this.hiddenFields.includes(this.fields[key].key))
    }
  }
}
</script>
