<template>
  <div class="d-flex align-items-center bg-light pl-2 rounded-sm border mr-1">
    <span class="text-nowrap mr-2">
      <translate>Items per page</translate>
    </span>
    <b-form-select
      v-model="inputModel"
      style="margin: -1px; border-top-left-radius: 0; border-bottom-left-radius: 0"
      :options="pageSizeOptions"
      @input="(value) => $emit('input', value)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'page-size-select',
})
export default class PageSizeSelect extends Vue {
  @Prop({ default: 25 }) value!: number

  get inputModel(): number | string {
    return this.value
  }

  set inputModel(value: number | string) {
    this.$emit('input', value)
  }

  pageSizeOptions = {
    10: 10,
    25: 25,
    50: 50,
    100: 100,
  }
}
</script>
