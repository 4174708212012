<template>
  <span class="labels" v-html="label" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IOrganization } from '@/types/projects'

@Component({
  name: 'organization-proof-status',
})
export default class OrganizationProofStatus extends Vue {
  @Prop() organization!: IOrganization

  get label(): string {
    let labelHtml = '-'
    const expired = this.organization.is_proof_expired
    if (this.organization.is_proof_uploaded) {
      labelHtml = expired
        ? `<span class="badge badge-danger">${this.$gettext('Expired')}</span>`
        : `<span class="badge badge-success">${this.$gettext('Valid')}</span>`
    }
    return labelHtml
  }
}
</script>
