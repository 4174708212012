import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { TGenericObject } from '@/types/base'
import {
  IPermissions,
  IPermissionsForUsers,
  IPermissionsState,
  IPermissionsWithUsers,
  IPermissionsWithUsersResponse,
} from '@/types/permissions'
import { API_URLS } from '@/utils/helpers'

@Module({ dynamic: true, store, name: 'permissions' })
class Permissions extends VuexModule implements IPermissionsState {
  permissions: IPermissions = {}
  userSearch = ''
  activePermission = ''
  permissionTypes: TGenericObject = {}
  permissionFilters: string[] = []
  selectedPermissions: string[] = []
  permissionsWithUsers: IPermissionsWithUsers = {}
  selectedUsers: string[] = []
  ignoreFilters = false

  get filteredUsers(): IPermissionsForUsers {
    if (!this.activePermission || this.permissionsWithUsers[this.activePermission] === undefined) {
      return {}
    }

    const users = this.permissionsWithUsers[this.activePermission].users
    const permissionTypeKeys = Object.keys(this.permissionTypes)

    return Object.assign(
      {},
      ...Object.keys(users)
        .filter((username) => {
          const filteredUserPerms = users[username].filter((perm) => {
            return permissionTypeKeys.includes(perm)
          })
          if (filteredUserPerms.length === 0) {
            filteredUserPerms.push('none')
          }
          return filteredUserPerms.some((perm) => {
            return (
              (this.permissionFilters.includes(perm) || !permissionTypeKeys.includes(perm)) &&
              username.toLowerCase().includes(this.userSearch.toLowerCase())
            )
          })
        })
        .map((username) => ({ [username]: users[username] }))
    )
  }

  @Mutation
  SET_USER_SEARCH(value: string) {
    this.userSearch = value
  }

  @Action({ commit: 'SET_USER_SEARCH' })
  handleSearchChange(value: string) {
    return value
  }

  @MutationAction({ mutate: ['permissions'] })
  async fetchPermissions() {
    const response = await fetch(API_URLS.PERMISSIONS.LIST).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    })
    return { permissions: response }
  }

  @MutationAction({ mutate: ['activePermission'] })
  async setActivePermission(activePermission: string) {
    return { activePermission }
  }

  @Mutation
  ADD_PERMISSION_WITH_USERS(permNameAndPermWithUsers: {
    permissionName: string
    permissionsWithUsers: IPermissionsWithUsersResponse
  }) {
    const permName = permNameAndPermWithUsers.permissionName as string
    this.permissionTypes = permNameAndPermWithUsers.permissionsWithUsers.permission_types
    this.permissionsWithUsers = {
      ...this.permissionsWithUsers,
      [permName]: permNameAndPermWithUsers.permissionsWithUsers,
    }
  }

  @Action({ commit: 'ADD_PERMISSION_WITH_USERS' })
  async fetchPermissionWithUsers(permissionName: string) {
    const response = await fetch(API_URLS.PERMISSIONS.USERS(permissionName)).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    })
    if (response.permission_types) {
      response.permissionTypes = response.permission_types
    }
    return { permissionName, permissionsWithUsers: response }
  }

  @Mutation
  SET_SELECTED_USERS(selectedUsers: Array<string>) {
    this.selectedUsers = selectedUsers
  }

  @Action({ commit: 'SET_SELECTED_USERS' })
  addUser(username: string) {
    if (username === 'all') {
      return Object.keys(this.filteredUsers)
    }
    return [...this.selectedUsers, username]
  }

  @Action({ commit: 'SET_SELECTED_USERS' })
  removeUser(username: string) {
    if (username === 'all') {
      return []
    }
    return this.selectedUsers.filter((x) => x !== username)
  }

  @Mutation
  SET_PERMISSION_FILTERS(permissionFilters: string[]) {
    this.permissionFilters = permissionFilters
  }

  @Action({ commit: 'SET_PERMISSION_FILTERS' })
  addPermissionFilter(permissionName: string) {
    return [...this.permissionFilters, permissionName]
  }

  @Action({ commit: 'SET_PERMISSION_FILTERS' })
  addPermissionFilters(permissionNames: string[]) {
    return [...this.permissionFilters, ...permissionNames]
  }

  @Action({ commit: 'SET_PERMISSION_FILTERS' })
  removePermissionFilter(permissionName: string) {
    return this.permissionFilters.filter((x) => x !== permissionName)
  }

  @Action({ commit: 'SET_PERMISSION_FILTERS' })
  removePermissionFilters(permissionNames: string[]) {
    return this.permissionFilters.filter((x) => !permissionNames.includes(x))
  }

  @Mutation
  SET_SELECTED_PERMISSION(permissionKey: string) {
    this.selectedPermissions = [...this.selectedPermissions, permissionKey]
  }

  @Mutation
  SET_SELECTED_PERMISSIONS(selectedPermissions: string[]) {
    this.selectedPermissions = selectedPermissions
  }

  @Action({ commit: 'SET_SELECTED_PERMISSION' })
  addSelectedPermission(permissionKey: string) {
    return permissionKey
  }

  @Action({ commit: 'SET_SELECTED_PERMISSIONS' })
  removeSelectedPermission(permissionKey: string) {
    return this.selectedPermissions.filter((x) => x !== permissionKey)
  }

  @Action({ commit: 'SET_SELECTED_PERMISSIONS' })
  clearSelectedPermissions() {
    return []
  }

  @Mutation
  private SET_IGNORE_FILTERS(value: boolean): void {
    this.ignoreFilters = value
  }

  @Action({ commit: 'SET_IGNORE_FILTERS' })
  public updateIgnoreFilters(value: boolean): boolean {
    return value
  }
}

export const PermissionsModule = getModule(Permissions)
