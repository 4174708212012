<template>
  <div class="map-placeholder ml-n3 mr-n3" :class="{ sparkasse }">
    <div class="container">
      <div class="row h-100 align-items-center justify-content-md-center">
        <div class="col-md-8 col-12 text-center text-white">
          <div>
            <h3><translate>Project map not active</translate></h3>
          </div>
          <div
            v-translate="{ platformOperatorName: platformOperator, privacyPolicyURL: platformPrivacyPolicyLink }"
            render-html="true"
          >
            If you activate the project map by clicking on the button below, you agree to
            <strong>%{ platformOperatorName }</strong> that personal data (e.g. IP address) will be transmitted to
            Google Ireland Limited as the provider of the service and may also be processed on servers in the USA. You
            can revoke your consent at any time via the <a href="/cookie-settings" target="_blank">cookie settings</a>.
            Further information on data protection can be found
            <a href="%{ privacyPolicyURL }" target="_blank">here</a>.
          </div>
          <div class="text-center padding-10">
            <b-button href="#" @click="onClickCookieButton">
              <translate>Activate project map</translate>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { setCookie } from '@/utils/helpers'

@Component({
  name: 'map-placeholder',
})
export default class MapPlaceholder extends Vue {
  @Prop({ default: false }) sparkasse: boolean
  @Prop({ default: '' }) platformOperator: string
  @Prop({ default: '' }) platformPrivacyPolicyLink: string

  COOKIE_NAME = 'cookie_rule_detail'
  REQUIRED_COOKIE_VALUE = 'google-maps'

  get cookieRuleDetail(): string {
    return this.$cookies.get(this.COOKIE_NAME)
  }

  setCookieRuleDetail(): void {
    if (this.cookieRuleDetail) {
      // The vue-cookies library doesn't provide the underlying setRaw function, so we have to do it ourselves.
      // this.$cookies.set would encode the comma, which other parts of the application expect to be unencoded
      setCookie(this.COOKIE_NAME, `${this.cookieRuleDetail},${this.REQUIRED_COOKIE_VALUE}`)
    } else {
      this.$cookies.set(this.COOKIE_NAME, this.REQUIRED_COOKIE_VALUE)
    }
  }

  onClickCookieButton() {
    this.setCookieRuleDetail()
    window.location.reload()
  }
}
</script>
