<template>
  <b-modal id="export-modal" v-model="isOpenModel" centered size="lg" scrollable v-on="$listeners">
    <template #modal-title>
      <h5 v-translate>Export</h5>
    </template>

    <template #default>
      <p key="export-select" v-translate>Export data according to currently active filters.</p>

      <b-alert v-if="!allowDirectExport" show variant="warning">
        <div class="d-flex align-items-center">
          <span class="warning-triangle fal fa-exclamation-triangle mr-2" />
          <div>
            <translate key="export-warning">
              You are exporting %{itemCount} items. This means that a direct export is currently disabled.
            </translate>
          </div>
        </div>
      </b-alert>
    </template>

    <template #modal-footer="{ close }">
      <b-button id="close-delete-modal-btn" variant="default" class="mr-1" @click="close()">
        <translate>Close</translate>
      </b-button>
      <b-button
        v-if="allowDirectExport"
        id="direct-export-btn"
        :disabled="exportStarted"
        class="mr-1"
        variant="base-ci"
        @click="startDirectExport"
      >
        <i v-if="!startingDirectExport" class="fal fa-download" />
        <b-spinner v-else small label="Spinning" />
        <translate class="ml-1">Direct Export</translate>
      </b-button>
      <b-button
        id="async-export-btn"
        :disabled="exportStarted"
        class="mr-1"
        variant="base-ci"
        @click="startAsyncExport"
      >
        <i v-if="!startingAsyncExport" class="fal fa-envelope" />
        <b-spinner v-else small label="Spinning" />
        <translate class="ml-1">Receive export via e-mail</translate>
      </b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import ToastMixin from '@/mixins/ToastMixin'

@Component({
  name: 'export-modal',
})
export default class ExportModal extends Mixins(ToastMixin) {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: 0 }) itemCount!: number
  @Prop({ default: 500 }) directExportLimit!: number
  @Prop({ default: 'export.xlsx' }) fileName!: string
  @Prop() directExportUrl: string
  @Prop() asyncExportUrl: string

  startingDirectExport = false
  startingAsyncExport = false

  get allowDirectExport(): boolean {
    return this.itemCount < this.directExportLimit
  }

  get exportStarted(): boolean {
    return this.startingDirectExport || this.startingAsyncExport
  }

  get isOpenModel(): boolean {
    return this.value
  }

  set isOpenModel(value: boolean) {
    this.$emit('input', value)
  }

  async startDirectExport(): Promise<void> {
    this.startingDirectExport = true
    await axios
      .get(this.directExportUrl, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.fileName)
        document.body.append(link)
        link.click()
      })
    this.startingDirectExport = false
  }

  async startAsyncExport(): Promise<void> {
    this.startingAsyncExport = true
    await axios
      .get(this.asyncExportUrl)
      .then((response) => {
        this.makeToast(
          'success',
          response.data.message,
          this.$gettext('You will receive an e-mail once your export is ready!')
        )
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          this.makeToast('danger', this.$gettext('Error'), this.$gettext('Failed to start export'))
        }
      })
    this.startingAsyncExport = false
  }
}
</script>
