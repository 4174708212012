<template>
  <b-form-group :label="label">
    <b-input-group>
      <template #prepend>
        <b-input-group-text>
          <span class="fa fa-qrcode" />
        </b-input-group-text>
      </template>
      <b-form-input
        type="text"
        :value="displayValue"
        placeholder="Code"
        :state="isValid"
        :debounce="200"
        @input="codeInput"
      />
    </b-input-group>
    <b-form-invalid-feedback :state="isValid">
      {{ errorMessage }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'code-input',
})
export default class CodeInput extends Vue {
  @Prop({ default: '' }) value!: string
  @Prop({ default: 4 }) blockLength!: number
  @Prop({ default: null }) errorMessage!: string
  @Prop({ default: null }) isValid!: string
  @Prop({ default: null }) label!: string

  get formatRegex(): RegExp {
    return new RegExp(`.{1,${this.blockLength}}`, 'g')
  }

  get displayValue(): string {
    const chunks = this.value.replaceAll(/\W/gi, '').match(this.formatRegex)
    return chunks ? chunks.join(' ') : null
  }

  codeInput(value: string): void {
    this.$emit('input', value)
  }
}
</script>
