<template>
  <b-modal id="vote-success-modal" v-model="isOpenModel" size="lg" centered v-on="$listeners">
    <template #modal-title>
      <h5 v-translate>Thank you for your vote!</h5>
    </template>

    <template #default>
      <div class="row">
        <div class="col-12">
          <SocialMediaArea
            id="vote-modal-social-media"
            :project="project"
            :projectDetailSettings="projectDetailSettings"
          />
          <b-card class="shadow-sm rounded mb-3">
            <b-img
              v-if="project.photos?.length"
              :src="project.photos[0].src"
              fluid
              :alt="project.title + ' cover image'"
            />
            <hr />
            <div>
              <p
                key="vote-success-message-1"
                v-if="voteResponse?.new_votes > 1"
                v-translate="{
                  voteAmount: voteResponse?.new_votes,
                  projectTitle: project.title,
                }"
              >
                You supported the project <strong><span class="project_title">%{ projectTitle }</span></strong> with
                <strong><span class="votes">%{ voteAmount }</span></strong> votes.
              </p>
              <p
                key="vote-success-message-2"
                v-else
                v-translate="{
                  projectTitle: project.title,
                }"
              >
                You supported the project <strong><span class="project_title">%{ projectTitle }</span></strong> with
                your vote.
              </p>
            </div>
          </b-card>
        </div>
      </div>
    </template>

    <template #modal-footer="{ close }">
      <b-button id="close-vote-modal-btn" variant="default" class="mr-1" @click="close">
        <translate>Close</translate>
      </b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'

import LocaleMixin from '@/mixins/LocaleMixin'
import { IProjectDetailSettings } from '@/types/cms'
import { IExploreProjectDetail } from '@/types/projects'
import { IUserProfile } from '@/types/users'

import SocialMediaArea from '../explore/SocialMediaArea.vue'
import { IVoteResponse } from '@/types/votings'

@Component({
  name: 'vote-success-modal',
  components: {
    SocialMediaArea,
  },
})
export default class VoteSuccessModal extends Mixins(LocaleMixin) {
  @Prop() project!: IExploreProjectDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings
  @Prop({ default: null }) voteResponse: IVoteResponse
  @Prop({ default: null }) userProfile: IUserProfile
  @Prop({ default: false }) value!: boolean

  get isOpenModel(): boolean {
    return this.value
  }

  set isOpenModel(value: boolean) {
    this.$emit('input', value)
  }
}
</script>
