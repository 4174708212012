<template>
  <div>
    <b-embed v-if="showVideo" type="iframe" :src="photo.video" allowfullscreen />
    <b-overlay
      v-if="!showCookieBanner && !showVideo"
      :show="!!photo.video"
      class="d-inline-block"
      variant="transparent"
      blur=""
      no-fade
    >
      <div class="splide-img-wrapper">
        <b-img thumbnail fluid :src="photo.src" />
        <slot name="image-description">
          <div class="splide-description-text">
            <h5 v-if="photo.main_description">{{ photo.main_description }}</h5>
            <p v-if="photo.description">{{ photo.description }}</p>
          </div>
        </slot>
      </div>
      <template #overlay>
        <div v-if="!showCookieBanner">
          <b-button size="lg" variant="transparent" @click="gotoVideo">
            <i class="fad fa-play-circle play-video-icon" />
          </b-button>
        </div>
      </template>
    </b-overlay>

    <b-overlay v-if="showCookieBanner" :show="photo.video" class="d-inline-block splide-full-size-img" variant="white">
      <b-img class="splide-full-size-img" thumbnail fluid :src="photo.src" />
      <template #overlay>
        <div class="text-center">
          <p class="splide-cookie-msg">{{ videoProvider.cookieMsg }}</p>
          <button class="btn btn-primary" tabindex="0" @click="addCookie">
            <span>
              {{ videoProvider.acceptCookieMsg }}
            </span>
          </button>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { IPhoto, IVideoProvider, IVideoProviders } from '@/types/photos'
import { setCookie } from '@/utils/helpers'

@Component({
  name: 'video-component',
})
export default class VideoComponent extends Vue {
  @Prop({ required: true }) photo: IPhoto

  videoProviders: IVideoProviders = {
    youtube: {
      name: 'Youtube',
      cookieName: 'youtube',
      cookieMsg: this.$gettext('YouTube cookies are not accepted'),
      acceptCookieMsg: this.$gettext('Activate YouTube'),
    },
    streamio: {
      name: 'Streamio',
      cookieName: 'streamio',
      cookieMsg: this.$gettext('Streamio cookies are not accepted'),
      acceptCookieMsg: this.$gettext('Activate Streamio'),
    },
    vimeo: {
      name: 'Vimeo',
      cookieName: 'vimeo',
      cookieMsg: this.$gettext('Vimeo cookies are not accepted'),
      acceptCookieMsg: this.$gettext('Activate Vimeo'),
    },
    mozaik: {
      name: 'Mozaik',
      cookieName: 'mozaik',
      cookieMsg: this.$gettext('Mozaik cookies are not accepted'),
      acceptCookieMsg: this.$gettext('Activate Mozaik'),
    },
  }

  @Watch('photo')
  handlePhotoChanged() {
    this.showVideo = false
  }

  get videoProvider(): IVideoProvider {
    return this.videoProviders[this.photo.video_provider]
  }

  set videoProvider(provider) {
    this.$emit('provider', provider)
  }

  showCookieBanner = false
  showVideo = false

  gotoVideo(): void {
    const cookieValues = this.$cookies.get('cookie_rule_detail')
    if (!cookieValues || !cookieValues.includes(this.videoProvider.cookieName)) {
      this.showCookieBanner = true
    } else {
      this.showCookieBanner = false
      this.showVideo = true
    }
  }

  addCookie(): void {
    const existingCookie = this.$cookies.get('cookie_rule_detail')
    const requiredCookieValue = this.videoProvider.cookieName

    if (existingCookie) {
      const updatedValue = `${existingCookie},${requiredCookieValue}`
      setCookie('cookie_rule_detail', updatedValue)
    } else {
      setCookie('cookie_rule_detail', requiredCookieValue)
    }

    this.showCookieBanner = false
    this.showVideo = true
  }
}
</script>
