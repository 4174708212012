<template>
  <div>
    {{ title }}
    <i :id="id" :class="`${iconClass} labeled-tooltip`" />
    <b-tooltip :custom-class="tooltipClass" :target="id" placement="left">
      <template #default>
        <slot>
          {{ description }}
        </slot>
      </template>
    </b-tooltip>
  </div>
</template>
<script lang="ts">
import { v4 as uuid4 } from 'uuid'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'labeled-tooltip',
})
export default class LabeledTooltip extends Vue {
  @Prop({ default: () => uuid4() }) id!: string
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) description!: string
  @Prop({ default: '' }) tooltipClass!: string
  @Prop({ default: 'fas fa-question-circle' }) iconClass!: string
}
</script>

<style scoped lang="scss">
.labeled-tooltip {
  margin-left: 10px;
  cursor: pointer;
}
</style>
