<template>
  <div>
    <b-form-group>
      <b-form-input
        id="street-input"
        v-model="modelForm.street"
        type="text"
        :placeholder="$gettext('Street and number') + ' *'"
        required
      />
      <b-form-invalid-feedback :state="hasError('street')">
        {{ formErrors.street }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        id="additional-information-input"
        v-model="modelForm.additional_information"
        type="text"
        :placeholder="$gettext('Additional information')"
        required
      />
      <b-form-invalid-feedback :state="hasError('additional_information')">
        {{ formErrors.additional_information }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        id="postal-code-input"
        v-model="modelForm.postal_code"
        type="text"
        :placeholder="$gettext('Postal code') + ' *'"
        required
      />
      <b-form-invalid-feedback :state="hasError('postal_code')">
        {{ formErrors.postal_code }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        id="city-input"
        v-model="modelForm.city"
        type="text"
        :placeholder="$gettext('City') + ' *'"
        required
      />
      <b-form-invalid-feedback :state="hasError('city')">
        {{ formErrors.city }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-select v-model="modelForm.country" :options="countries" placeholder="text" />
      <b-form-invalid-feedback :state="hasError('country')">
        {{ formErrors.country }}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { TGenericObject } from '@/types/base'
import { IAddress } from '@/types/projects'
@Component({
  name: 'address-input',
})
export default class AddressInput extends Vue {
  @Prop({ required: false }) form: IAddress
  @Prop() formErrors!: TGenericObject

  get modelForm() {
    return this.form
  }

  set modelForm(value: IAddress) {
    this.$emit('input', value)
  }

  mounted() {
    this.modelForm = this.form
  }

  hasError(field: string): boolean {
    return !this.formErrors[field]
  }

  countries = [
    {
      text: this.$gettext('Germany'),
      value: 'DE',
    },
    {
      text: this.$gettext('Austria'),
      value: 'AT',
    },
    {
      text: this.$gettext('Switzerland'),
      value: 'CH',
    },
    {
      text: this.$gettext('Afghanistan'),
      value: 'AF',
    },
    {
      text: this.$gettext('\u00C5land Islands'),
      value: 'AX',
    },
    {
      text: this.$gettext('Albania'),
      value: 'AL',
    },
    {
      text: this.$gettext('Algeria'),
      value: 'DZ',
    },
    {
      text: this.$gettext('American Samoa'),
      value: 'AS',
    },
    {
      text: this.$gettext('Andorra'),
      value: 'AD',
    },
    {
      text: this.$gettext('Angola'),
      value: 'AO',
    },
    {
      text: this.$gettext('Anguilla'),
      value: 'AI',
    },
    {
      text: this.$gettext('Antarctica'),
      value: 'AQ',
    },
    {
      text: this.$gettext('Antigua and Barbuda'),
      value: 'AG',
    },
    {
      text: this.$gettext('Argentina'),
      value: 'AR',
    },
    {
      text: this.$gettext('Armenia'),
      value: 'AM',
    },
    {
      text: this.$gettext('Aruba'),
      value: 'AW',
    },
    {
      text: this.$gettext('Australia'),
      value: 'AU',
    },
    {
      text: this.$gettext('Azerbaijan'),
      value: 'AZ',
    },
    {
      text: this.$gettext('Bahamas'),
      value: 'BS',
    },
    {
      text: this.$gettext('Bahrain'),
      value: 'BH',
    },
    {
      text: this.$gettext('Bangladesh'),
      value: 'BD',
    },
    {
      text: this.$gettext('Barbados'),
      value: 'BB',
    },
    {
      text: this.$gettext('Belarus'),
      value: 'BY',
    },
    {
      text: this.$gettext('Belgium'),
      value: 'BE',
    },
    {
      text: this.$gettext('Belize'),
      value: 'BZ',
    },
    {
      text: this.$gettext('Benin'),
      value: 'BJ',
    },
    {
      text: this.$gettext('Bermuda'),
      value: 'BM',
    },
    {
      text: this.$gettext('Bhutan'),
      value: 'BT',
    },
    {
      text: this.$gettext('Bolivia, Plurinational State of'),
      value: 'BO',
    },
    {
      text: this.$gettext('Bonaire, Sint Eustatius and Saba'),
      value: 'BQ',
    },
    {
      text: this.$gettext('Bosnia and Herzegovina'),
      value: 'BA',
    },
    {
      text: this.$gettext('Botswana'),
      value: 'BW',
    },
    {
      text: this.$gettext('Bouvet Island'),
      value: 'BV',
    },
    {
      text: this.$gettext('Brazil'),
      value: 'BR',
    },
    {
      text: this.$gettext('British Indian Ocean Territory'),
      value: 'IO',
    },
    {
      text: this.$gettext('Brunei Darussalam'),
      value: 'BN',
    },
    {
      text: this.$gettext('Bulgaria'),
      value: 'BG',
    },
    {
      text: this.$gettext('Burkina Faso'),
      value: 'BF',
    },
    {
      text: this.$gettext('Burundi'),
      value: 'BI',
    },
    {
      text: this.$gettext('Cambodia'),
      value: 'KH',
    },
    {
      text: this.$gettext('Cameroon'),
      value: 'CM',
    },
    {
      text: this.$gettext('Canada'),
      value: 'CA',
    },
    {
      text: this.$gettext('Cape Verde'),
      value: 'CV',
    },
    {
      text: this.$gettext('Cayman Islands'),
      value: 'KY',
    },
    {
      text: this.$gettext('Central African Republic'),
      value: 'CF',
    },
    {
      text: this.$gettext('Chad'),
      value: 'TD',
    },
    {
      text: this.$gettext('Chile'),
      value: 'CL',
    },
    {
      text: this.$gettext('China'),
      value: 'CN',
    },
    {
      text: this.$gettext('Christmas Island'),
      value: 'CX',
    },
    {
      text: this.$gettext('Cocos (Keeling) Islands'),
      value: 'CC',
    },
    {
      text: this.$gettext('Colombia'),
      value: 'CO',
    },
    {
      text: this.$gettext('Comoros'),
      value: 'KM',
    },
    {
      text: this.$gettext('Congo'),
      value: 'CG',
    },
    {
      text: this.$gettext('Congo, the Democratic Republic of the'),
      value: 'CD',
    },
    {
      text: this.$gettext('Cook Islands'),
      value: 'CK',
    },
    {
      text: this.$gettext('Costa Rica'),
      value: 'CR',
    },
    {
      text: this.$gettext("C\u00F4te d'Ivoire"),
      value: 'CI',
    },
    {
      text: this.$gettext('Croatia'),
      value: 'HR',
    },
    {
      text: this.$gettext('Cuba'),
      value: 'CU',
    },
    {
      text: this.$gettext('Cura\u00E7ao'),
      value: 'CW',
    },
    {
      text: this.$gettext('Cyprus'),
      value: 'CY',
    },
    {
      text: this.$gettext('Czech Republic'),
      value: 'CZ',
    },
    {
      text: this.$gettext('Denmark'),
      value: 'DK',
    },
    {
      text: this.$gettext('Djibouti'),
      value: 'DJ',
    },
    {
      text: this.$gettext('Dominica'),
      value: 'DM',
    },
    {
      text: this.$gettext('Dominican Republic'),
      value: 'DO',
    },
    {
      text: this.$gettext('Ecuador'),
      value: 'EC',
    },
    {
      text: this.$gettext('Egypt'),
      value: 'EG',
    },
    {
      text: this.$gettext('El Salvador'),
      value: 'SV',
    },
    {
      text: this.$gettext('Equatorial Guinea'),
      value: 'GQ',
    },
    {
      text: this.$gettext('Eritrea'),
      value: 'ER',
    },
    {
      text: this.$gettext('Estonia'),
      value: 'EE',
    },
    {
      text: this.$gettext('Ethiopia'),
      value: 'ET',
    },
    {
      text: this.$gettext('Falkland Islands (Malvinas)'),
      value: 'FK',
    },
    {
      text: this.$gettext('Faroe Islands'),
      value: 'FO',
    },
    {
      text: this.$gettext('Fiji'),
      value: 'FJ',
    },
    {
      text: this.$gettext('Finland'),
      value: 'FI',
    },
    {
      text: this.$gettext('France'),
      value: 'FR',
    },
    {
      text: this.$gettext('French Guiana'),
      value: 'GF',
    },
    {
      text: this.$gettext('French Polynesia'),
      value: 'PF',
    },
    {
      text: this.$gettext('French Southern Territories'),
      value: 'TF',
    },
    {
      text: this.$gettext('Gabon'),
      value: 'GA',
    },
    {
      text: this.$gettext('Gambia'),
      value: 'GM',
    },
    {
      text: this.$gettext('Georgia'),
      value: 'GE',
    },
    {
      text: this.$gettext('Ghana'),
      value: 'GH',
    },
    {
      text: this.$gettext('Gibraltar'),
      value: 'GI',
    },
    {
      text: this.$gettext('Greece'),
      value: 'GR',
    },
    {
      text: this.$gettext('Greenland'),
      value: 'GL',
    },
    {
      text: this.$gettext('Grenada'),
      value: 'GD',
    },
    {
      text: this.$gettext('Guadeloupe'),
      value: 'GP',
    },
    {
      text: this.$gettext('Guam'),
      value: 'GU',
    },
    {
      text: this.$gettext('Guatemala'),
      value: 'GT',
    },
    {
      text: this.$gettext('Guernsey'),
      value: 'GG',
    },
    {
      text: this.$gettext('Guinea'),
      value: 'GN',
    },
    {
      text: this.$gettext('Guinea-Bissau'),
      value: 'GW',
    },
    {
      text: this.$gettext('Guyana'),
      value: 'GY',
    },
    {
      text: this.$gettext('Haiti'),
      value: 'HT',
    },
    {
      text: this.$gettext('Heard Island and McDonald Islands'),
      value: 'HM',
    },
    {
      text: this.$gettext('Holy See (Vatican City State)'),
      value: 'VA',
    },
    {
      text: this.$gettext('Honduras'),
      value: 'HN',
    },
    {
      text: this.$gettext('Hong Kong'),
      value: 'HK',
    },
    {
      text: this.$gettext('Hungary'),
      value: 'HU',
    },
    {
      text: this.$gettext('Iceland'),
      value: 'IS',
    },
    {
      text: this.$gettext('India'),
      value: 'IN',
    },
    {
      text: this.$gettext('Indonesia'),
      value: 'ID',
    },
    {
      text: this.$gettext('Iran, Islamic Republic of'),
      value: 'IR',
    },
    {
      text: this.$gettext('Iraq'),
      value: 'IQ',
    },
    {
      text: this.$gettext('Ireland'),
      value: 'IE',
    },
    {
      text: this.$gettext('Isle of Man'),
      value: 'IM',
    },
    {
      text: this.$gettext('Israel'),
      value: 'IL',
    },
    {
      text: this.$gettext('Italy'),
      value: 'IT',
    },
    {
      text: this.$gettext('Jamaica'),
      value: 'JM',
    },
    {
      text: this.$gettext('Japan'),
      value: 'JP',
    },
    {
      text: this.$gettext('Jersey'),
      value: 'JE',
    },
    {
      text: this.$gettext('Jordan'),
      value: 'JO',
    },
    {
      text: this.$gettext('Kazakhstan'),
      value: 'KZ',
    },
    {
      text: this.$gettext('Kenya'),
      value: 'KE',
    },
    {
      text: this.$gettext('Kiribati'),
      value: 'KI',
    },
    {
      text: this.$gettext("Korea, Democratic People's Republic of"),
      value: 'KP',
    },
    {
      text: this.$gettext('Korea, Republic of'),
      value: 'KR',
    },
    {
      text: this.$gettext('Kuwait'),
      value: 'KW',
    },
    {
      text: this.$gettext('Kyrgyzstan'),
      value: 'KG',
    },
    {
      text: this.$gettext("Lao People's Democratic Republic"),
      value: 'LA',
    },
    {
      text: this.$gettext('Latvia'),
      value: 'LV',
    },
    {
      text: this.$gettext('Lebanon'),
      value: 'LB',
    },
    {
      text: this.$gettext('Lesotho'),
      value: 'LS',
    },
    {
      text: this.$gettext('Liberia'),
      value: 'LR',
    },
    {
      text: this.$gettext('Libya'),
      value: 'LY',
    },
    {
      text: this.$gettext('Liechtenstein'),
      value: 'LI',
    },
    {
      text: this.$gettext('Lithuania'),
      value: 'LT',
    },
    {
      text: this.$gettext('Luxembourg'),
      value: 'LU',
    },
    {
      text: this.$gettext('Macao'),
      value: 'MO',
    },
    {
      text: this.$gettext('Macedonia, the Former Yugoslav Republic of'),
      value: 'MK',
    },
    {
      text: this.$gettext('Madagascar'),
      value: 'MG',
    },
    {
      text: this.$gettext('Malawi'),
      value: 'MW',
    },
    {
      text: this.$gettext('Malaysia'),
      value: 'MY',
    },
    {
      text: this.$gettext('Maldives'),
      value: 'MV',
    },
    {
      text: this.$gettext('Mali'),
      value: 'ML',
    },
    {
      text: this.$gettext('Malta'),
      value: 'MT',
    },
    {
      text: this.$gettext('Marshall Islands'),
      value: 'MH',
    },
    {
      text: this.$gettext('Martinique'),
      value: 'MQ',
    },
    {
      text: this.$gettext('Mauritania'),
      value: 'MR',
    },
    {
      text: this.$gettext('Mauritius'),
      value: 'MU',
    },
    {
      text: this.$gettext('Mayotte'),
      value: 'YT',
    },
    {
      text: this.$gettext('Mexico'),
      value: 'MX',
    },
    {
      text: this.$gettext('Micronesia, Federated States of'),
      value: 'FM',
    },
    {
      text: this.$gettext('Moldova, Republic of'),
      value: 'MD',
    },
    {
      text: this.$gettext('Monaco'),
      value: 'MC',
    },
    {
      text: this.$gettext('Mongolia'),
      value: 'MN',
    },
    {
      text: this.$gettext('Montenegro'),
      value: 'ME',
    },
    {
      text: this.$gettext('Montserrat'),
      value: 'MS',
    },
    {
      text: this.$gettext('Morocco'),
      value: 'MA',
    },
    {
      text: this.$gettext('Mozambique'),
      value: 'MZ',
    },
    {
      text: this.$gettext('Myanmar'),
      value: 'MM',
    },
    {
      text: this.$gettext('Namibia'),
      value: 'NA',
    },
    {
      text: this.$gettext('Nauru'),
      value: 'NR',
    },
    {
      text: this.$gettext('Nepal'),
      value: 'NP',
    },
    {
      text: this.$gettext('Netherlands'),
      value: 'NL',
    },
    {
      text: this.$gettext('New Caledonia'),
      value: 'NC',
    },
    {
      text: this.$gettext('New Zealand'),
      value: 'NZ',
    },
    {
      text: this.$gettext('Nicaragua'),
      value: 'NI',
    },
    {
      text: this.$gettext('Niger'),
      value: 'NE',
    },
    {
      text: this.$gettext('Nigeria'),
      value: 'NG',
    },
    {
      text: this.$gettext('Niue'),
      value: 'NU',
    },
    {
      text: this.$gettext('Norfolk Island'),
      value: 'NF',
    },
    {
      text: this.$gettext('Northern Mariana Islands'),
      value: 'MP',
    },
    {
      text: this.$gettext('Norway'),
      value: 'NO',
    },
    {
      text: this.$gettext('Oman'),
      value: 'OM',
    },
    {
      text: this.$gettext('Pakistan'),
      value: 'PK',
    },
    {
      text: this.$gettext('Palau'),
      value: 'PW',
    },
    {
      text: this.$gettext('Palestine, State of'),
      value: 'PS',
    },
    {
      text: this.$gettext('Panama'),
      value: 'PA',
    },
    {
      text: this.$gettext('Papua New Guinea'),
      value: 'PG',
    },
    {
      text: this.$gettext('Paraguay'),
      value: 'PY',
    },
    {
      text: this.$gettext('Peru'),
      value: 'PE',
    },
    {
      text: this.$gettext('Philippines'),
      value: 'PH',
    },
    {
      text: this.$gettext('Pitcairn'),
      value: 'PN',
    },
    {
      text: this.$gettext('Poland'),
      value: 'PL',
    },
    {
      text: this.$gettext('Portugal'),
      value: 'PT',
    },
    {
      text: this.$gettext('Puerto Rico'),
      value: 'PR',
    },
    {
      text: this.$gettext('Qatar'),
      value: 'QA',
    },
    {
      text: this.$gettext('R\u00E9union'),
      value: 'RE',
    },
    {
      text: this.$gettext('Romania'),
      value: 'RO',
    },
    {
      text: this.$gettext('Russian Federation'),
      value: 'RU',
    },
    {
      text: this.$gettext('Rwanda'),
      value: 'RW',
    },
    {
      text: this.$gettext('Saint Barth\u00E9lemy'),
      value: 'BL',
    },
    {
      text: this.$gettext('Saint Helena, Ascension and Tristan da Cunha'),
      value: 'SH',
    },
    {
      text: this.$gettext('Saint Kitts and Nevis'),
      value: 'KN',
    },
    {
      text: this.$gettext('Saint Lucia'),
      value: 'LC',
    },
    {
      text: this.$gettext('Saint Martin (French part)'),
      value: 'MF',
    },
    {
      text: this.$gettext('Saint Pierre and Miquelon'),
      value: 'PM',
    },
    {
      text: this.$gettext('Saint Vincent and the Grenadines'),
      value: 'VC',
    },
    {
      text: this.$gettext('Samoa'),
      value: 'WS',
    },
    {
      text: this.$gettext('San Marino'),
      value: 'SM',
    },
    {
      text: this.$gettext('Sao Tome and Principe'),
      value: 'ST',
    },
    {
      text: this.$gettext('Saudi Arabia'),
      value: 'SA',
    },
    {
      text: this.$gettext('Senegal'),
      value: 'SN',
    },
    {
      text: this.$gettext('Serbia'),
      value: 'RS',
    },
    {
      text: this.$gettext('Seychelles'),
      value: 'SC',
    },
    {
      text: this.$gettext('Sierra Leone'),
      value: 'SL',
    },
    {
      text: this.$gettext('Singapore'),
      value: 'SG',
    },
    {
      text: this.$gettext('Sint Maarten (Dutch part)'),
      value: 'SX',
    },
    {
      text: this.$gettext('Slovakia'),
      value: 'SK',
    },
    {
      text: this.$gettext('Slovenia'),
      value: 'SI',
    },
    {
      text: this.$gettext('Solomon Islands'),
      value: 'SB',
    },
    {
      text: this.$gettext('Somalia'),
      value: 'SO',
    },
    {
      text: this.$gettext('South Africa'),
      value: 'ZA',
    },
    {
      text: this.$gettext('South Georgia and the South Sandwich Islands'),
      value: 'GS',
    },
    {
      text: this.$gettext('South Sudan'),
      value: 'SS',
    },
    {
      text: this.$gettext('Spain'),
      value: 'ES',
    },
    {
      text: this.$gettext('Sri Lanka'),
      value: 'LK',
    },
    {
      text: this.$gettext('Sudan'),
      value: 'SD',
    },
    {
      text: this.$gettext('Suriname'),
      value: 'SR',
    },
    {
      text: this.$gettext('Svalbard and Jan Mayen'),
      value: 'SJ',
    },
    {
      text: this.$gettext('Swaziland'),
      value: 'SZ',
    },
    {
      text: this.$gettext('Sweden'),
      value: 'SE',
    },
    {
      text: this.$gettext('Syrian Arab Republic'),
      value: 'SY',
    },
    {
      text: this.$gettext('Taiwan, Province of China'),
      value: 'TW',
    },
    {
      text: this.$gettext('Tajikistan'),
      value: 'TJ',
    },
    {
      text: this.$gettext('Tanzania, United Republic of'),
      value: 'TZ',
    },
    {
      text: this.$gettext('Thailand'),
      value: 'TH',
    },
    {
      text: this.$gettext('Timor-Leste'),
      value: 'TL',
    },
    {
      text: this.$gettext('Togo'),
      value: 'TG',
    },
    {
      text: this.$gettext('Tokelau'),
      value: 'TK',
    },
    {
      text: this.$gettext('Tonga'),
      value: 'TO',
    },
    {
      text: this.$gettext('Trinidad and Tobago'),
      value: 'TT',
    },
    {
      text: this.$gettext('Tunisia'),
      value: 'TN',
    },
    {
      text: this.$gettext('Turkey'),
      value: 'TR',
    },
    {
      text: this.$gettext('Turkmenistan'),
      value: 'TM',
    },
    {
      text: this.$gettext('Turks and Caicos Islands'),
      value: 'TC',
    },
    {
      text: this.$gettext('Tuvalu'),
      value: 'TV',
    },
    {
      text: this.$gettext('Uganda'),
      value: 'UG',
    },
    {
      text: this.$gettext('Ukraine'),
      value: 'UA',
    },
    {
      text: this.$gettext('United Arab Emirates'),
      value: 'AE',
    },
    {
      text: this.$gettext('United Kingdom'),
      value: 'GB',
    },
    {
      text: this.$gettext('United States'),
      value: 'US',
    },
    {
      text: this.$gettext('United States Minor Outlying Islands'),
      value: 'UM',
    },
    {
      text: this.$gettext('Uruguay'),
      value: 'UY',
    },
    {
      text: this.$gettext('Uzbekistan'),
      value: 'UZ',
    },
    {
      text: this.$gettext('Vanuatu'),
      value: 'VU',
    },
    {
      text: this.$gettext('Venezuela, Bolivarian Republic of'),
      value: 'VE',
    },
    {
      text: this.$gettext('Viet Nam'),
      value: 'VN',
    },
    {
      text: this.$gettext('Virgin Islands, British'),
      value: 'VG',
    },
    {
      text: this.$gettext('Virgin Islands, U.S.'),
      value: 'VI',
    },
    {
      text: this.$gettext('Wallis and Futuna'),
      value: 'WF',
    },
    {
      text: this.$gettext('Western Sahara'),
      value: 'EH',
    },
    {
      text: this.$gettext('Yemen'),
      value: 'YE',
    },
    {
      text: this.$gettext('Zambia'),
      value: 'ZM',
    },
    {
      text: this.$gettext('Zimbabwe'),
      value: 'ZW',
    },
  ]
}
</script>
