<template>
  <span class="labels" v-html="label" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IStatus } from '@/types/base'

@Component({
  name: 'internal-funding-status',
})
export default class InternalFundingStatus extends Vue {
  @Prop() status!: IStatus

  get label(): string {
    let labelHtml = ''
    switch (this.status.choice) {
      case 0:
      case 1:
      case 2:
      case 42: {
        labelHtml = `<span class="badge badge-warning">${this.status.display}</span>`
        break
      }
      case 3:
      case 6:
      case 9:
      case 4: {
        labelHtml = `<span class="badge badge-danger">${this.status.display}</span>`
        // No default
        break
      }
      case 5:
      case 7: {
        labelHtml = `<span class="badge badge-dark">${this.status.display}</span>`
        // No default
        break
      }
      case 8: {
        labelHtml = `<span class="badge badge-success">${this.status.display}</span>`
        // No default
        break
      }
    } // Defunded
    return labelHtml
  }
}
</script>
<!--        Scheduled = ChoiceItem(0, _('Direct grant intended'))
            Approved = ChoiceItem(1, _('Direct grant approved'))
            DEPRECATED_Funded = ChoiceItem(1, _('Funded'))
            InitiatePayoutCreation = ChoiceItem(42, _('Initiate payout creation for direct grant'))
            Cancelled = ChoiceItem(3, _('Direct grant cancelled'))
            Defunded = ChoiceItem(4, _('Defunded'))
            WaitForPayoutCreation = ChoiceItem(5, _('Waiting for payout creation'))
            PayoutCreationCancelled = ChoiceItem(6, _('Payout creation cancelled'))
            PayoutCreated = ChoiceItem(8, _('Payout created'))
            PayoutReversed = ChoiceItem(9, _('Payout reversed'))
-->
