<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'map-zoom-slider',
})
export default class MapZoomSlider extends Vue {
  @Prop() value!: number

  get zoom(): number {
    return this.value
  }

  set zoom(value: number) {
    // the input value type is actually string, but getter and setter type need to be identical
    this.$emit('input', Number(value))
  }
}
</script>

<template>
  <div class="google-map-zoom-slider-container">
    <div class="google-map-zoom-slider">
      <translate>worldwide</translate>
      <b-form-input id="range-1" v-model="zoom" type="range" min="1" max="16" size="lg" />
      <translate>regional</translate>
    </div>
    <i class="fas fa-location-arrow" @click="$emit('zoom-to-user')" />
  </div>
</template>
