import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export interface IRootState {
  state: unknown
  mutations: unknown
  actions: unknown
  modules: unknown
}

export default new Vuex.Store<IRootState>({})
