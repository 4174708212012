<template>
  <div class="form-module" style="paddingtop: 2px">
    <div class="reminder-details" style="paddingbottom: 20px">
      <h3>{{ reminder.title }}</h3>
      <h4 v-translate>Description</h4>
      <p style="marginbottom: 20px">{{ reminder.description }}</p>
      <h4 v-translate>Due date</h4>
      <p style="marginbottom: 20px">{{ dueDate }}</p>
      <h4 v-translate>Assignees</h4>
      <ul style="padding-left: 22px; padding-bottom: 20px">
        <li v-for="user in reminder.users" :key="user.id">
          {{ user.full_name_w_username }}
        </li>
      </ul>
      <ReminderStatus :reminder="reminder" />
    </div>
    <div v-if="reminder" class="form-actions text-right">
      <a v-translate class="btn form-btn" href="#" @click="$emit('close-details')">Close</a>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import ReminderStatus from '@/components/reminders/ReminderStatus.vue'
import { IReminder } from '@/types/reminders'

@Component({
  components: {
    ReminderStatus,
  },
})
export default class ReminderDetails extends Vue {
  @Prop() reminder!: IReminder

  get dueDate() {
    const date = new Date(this.reminder.scheduled_for)
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    return `${day}.${month}.${date.getFullYear()}`
  }
}
</script>
