<template>
  <b-alert v-bind="{ ...$attrs, ...$props }" v-on="$listeners">
    <template #default>
      <div class="d-flex align-items-center">
        <span :class="['h4', iconWeight, 'fa-' + icon]" />
        <span class="ml-4 p-small float-left">
          <slot />
        </span>
      </div>
    </template>
  </b-alert>
</template>

<script lang="ts">
import { BAlert } from 'bootstrap-vue'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  name: 'sf-alert',
})
export default class SfAlert extends Mixins(BAlert) {
  @Prop({ default: 'info' }) variant!: string
  @Prop({ default: 'info-circle' }) icon!: string
  @Prop({ default: 'fa' }) iconWeight!: string
}
</script>

<style scoped></style>
