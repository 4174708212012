<template>
  <div class="form-module pb-3">
    <div class="form-legend p-3">
      <b-form-input
        v-if="editable"
        :value="title"
        :placeholder="placeholder"
        maxlength="128"
        :state="state"
        required
        @change="(value) => $emit('input', value)"
      />
      <b-form-invalid-feedback v-if="editable" :state="errorText.length > 0">
        {{ errorText }}
      </b-form-invalid-feedback>
      <h5 v-else class="m-0">
        {{ title }}
      </h5>
    </div>
    <fieldset>
      <slot />
    </fieldset>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'form-module',
})
export default class FormModule extends Vue {
  @Prop() title!: string
  @Prop({ default: false }) editable!: boolean
  @Prop() placeholder: string
  @Prop() state: boolean
  @Prop({ default: '' }) errorText!: string
}
</script>

<style scoped></style>
