<template>
  <span class="labels" v-html="label" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IProject } from '@/types/projects'

@Component
export default class ProjectFundingAccountLabel extends Vue {
  @Prop() project!: IProject

  get label(): string {
    let labelHtml = '-'
    if (this.project.individual_bank_account) {
      labelHtml = `<span class="badge badge-info label-bankacc-i-${this.project.individual_bank_account.slug}">${this.project.individual_bank_account.title}</span>`
    } else if (this.project.funding_type && this.project.funding_type.choice === 1) {
      labelHtml = `<span class="badge badge-info label-bankacc-donation">${this.$gettext('Donation Bank Account')}</span>`
    } else if (this.project.funding_type && this.project.funding_type.choice === 2) {
      labelHtml = `<span class="badge badge-info label-bankacc-sponsoring">${this.$gettext('Sponsoring Bank Account')}</span>`
    } else if (this.project.funding_type && this.project.funding_type.choice === 3) {
      labelHtml = `<span class="badge badge-info label-bankacc-foundation">${this.$gettext('Foundation Bank Account')}</span>`
    } else if (this.project.funding_type && this.project.funding_type.choice === 4) {
      labelHtml = `<span class="badge badge-info label-bankacc-zweckertrag">${this.$gettext('Gewinnsparen Bank Account')}</span>`
    }
    return labelHtml
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
