<template>
  <b-card v-if="projectDetailSettings" class="shadow-sm mb-3 bg-white rounded">
    <b-card-body class="p-0">
      <b-row class="mb-3">
        <b-col cols="12" class="project-overview-header">
          <div class="d-flex justify-content-between">
            <div>
              <FavoriteProjectButton :project="project" :projectDetailSettings="projectDetailSettings" />
              <h6 class="text-uppercase category-name"><translate>Category</translate>: {{ project.category }}</h6>
              <h4 class="text-break project-name">{{ projectTitle }}</h4>
              <h6 class="text-break organisation-name">{{ project.organization.title }}</h6>
            </div>
            <b-img
              v-if="project.organization.logo"
              :src="project.organization.logo"
              style="max-width: 160px; max-height: 120px; object-fit: contain"
            />
          </div>
        </b-col>
      </b-row>

      <ImageSlider :photos="project.photos" :thumbnails="true" />

      <b-tabs class="text-body underline-menu" fi>
        <ProjectCustomTab
          v-for="customTab in customTabs(tabKeys.ProjectDescriptionPre)"
          :key="customTab.title"
          :customTab="customTab"
        />
        <b-tab v-if="projectDetailSettings.showDescription" :title="$gettext('Description')" active>
          <b-card-body>
            <p v-html="project.project_description || project.description" />
          </b-card-body>
        </b-tab>
        <ProjectCustomTab
          v-for="customTab in customTabs(tabKeys.ProjectDescriptionPost)"
          :key="customTab.title"
          :customTab="customTab"
        />

        <b-tab
          v-if="
            'goals' in project &&
            (project.is_status_archived ? projectDetailSettings.showNeedsArchived : projectDetailSettings.showNeeds)
          "
          :title="$gettext('Needs')"
        >
          <b-card-body>
            <div class="mt-3">
              <div v-for="(goal, index) in project.goals" :key="goal.slug">
                <b-card-title v-if="projectDetailSettings.detailedMilestones">{{ goal.title }}</b-card-title>
                <b-card-sub-title
                  v-if="project.total_goals_count > 1 && project.goals.length === 1"
                  key="needs-info-2"
                  v-translate="{ completedCount: project.completed_goals_count }"
                  class="font-weight-bold"
                >
                  %{ completedCount } times financed
                </b-card-sub-title>
                <h5>{{ progressText(goal) }}</h5>
                <b-progress :max="goal.goal.in_cents" class="mb-3" height="1.5rem">
                  <b-progress-bar
                    style="height: 100%"
                    :value="goal.balance.in_cents"
                    :label="`${roundGoal((goal.balance.in_cents / goal.goal.in_cents) * 100)}%`"
                  />
                </b-progress>
                <b-card-text v-if="projectDetailSettings.detailedMilestones">{{ goal.description }}</b-card-text>
                <hr v-if="index + 1 < project.goals.length" />
              </div>
              <div v-if="project.goals.length === 0">
                <translate>No need available.</translate>
              </div>
            </div>
          </b-card-body>
        </b-tab>

        <ProjectCustomTab
          v-for="customTab in customTabs(tabKeys.ProjectFundingNeedsPost)"
          :key="customTab.title"
          :customTab="customTab"
        />
        <ProjectCustomTab
          v-for="customTab in customTabs(tabKeys.ProjectGalleryPost)"
          :key="customTab.title"
          :customTab="customTab"
        />

        <b-tab :title="$gettext('Organization')">
          <b-card-body>
            <h3>{{ project.organization.title }}</h3>
            <p v-html="project.organization.description" />

            <div v-if="project.organization.description_activity">
              <h3><translate>Main activities</translate>:</h3>
              <p v-html="project.organization.description_activity" />
            </div>

            <div v-if="project.organization.description_strength">
              <h3><translate>Strengths</translate>:</h3>
              <p v-html="project.organization.description_strength" />
            </div>

            <h4>
              <translate>Contact</translate>
            </h4>
            <p>
              {{ project.organization.title }}<br />
              {{ project.organization.street }}<br />
              {{ project.organization.postal_code }} {{ project.organization.city }}<br />
              {{ project.organization.country }}<br />
              <span v-if="project.organization.website">
                <a :href="project.organization.website">
                  {{ project.organization.website }}
                </a>
              </span>
            </p>
          </b-card-body>
        </b-tab>
        <ProjectCustomTab
          v-for="customTab in customTabs(tabKeys.ProjectOrganizationPost)"
          :key="customTab.title"
          :customTab="customTab"
        />
        <ProjectCustomTab
          v-for="customTab in customTabs(tabKeys.ProjectStatisticsPost)"
          :key="customTab.title"
          :customTab="customTab"
        />
        <ProjectCustomTab
          v-for="customTab in customTabs(tabKeys.ProjectVotingRankPost)"
          :key="customTab.title"
          :customTab="customTab"
        />
        <ProjectCustomTab
          v-for="customTab in customTabs(tabKeys.ProjectSupportPost)"
          :key="customTab.title"
          :customTab="customTab"
        />
        <ProjectCustomTab
          v-for="customTab in customTabs(tabKeys.Append)"
          :key="customTab.title"
          :customTab="customTab"
        />
      </b-tabs>
    </b-card-body>
    <LoginModal v-model="loginModalOpen" @success="loginModalOpen = false" />
  </b-card>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'

import FavoriteProjectButton from '@/components/explore/FavoriteProjectButton.vue'
import ImageSlider from '@/components/explore/ImageSlider.vue'
import LoginModal from '@/components/modals/LoginModal.vue'
import ToastMixin from '@/mixins/ToastMixin'
import UserMixin from '@/mixins/UserMixin'
import { IProjectDetailSettings } from '@/types/cms'
import { IMilestone } from '@/types/finances'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { ICustomTab, IExploreProjectDetail } from '@/types/projects'
import { ceilValueIfBelowX } from '@/utils/helpers/math'

import ProjectCustomTab from './ProjectCustomTab.vue'
@Component({
  components: {
    ProjectCustomTab,
    LoginModal,
    ImageSlider,
    FavoriteProjectButton,
  },
})
export default class ProjectOverview extends Mixins(ToastMixin, UserMixin) {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings

  loginModalOpen = false
  favouriteMouseover = false
  tabKeys = {
    Append: 1,
    ProjectPromoterDescriptionPre: 100,
    ProjectPromoterDescriptionPost: 101,
    ProjectPromoterGalleryPost: 102,
    ProjectPromoterProjectsPost: 103,
    ProjectPromoterDonationUsagePost: 104,
    ProjectPromoterVotingsPost: 105,
    ProjectPromoterStatisticsPost: 106,
    ProjectPromoterSupportPost: 107,
    ProjectDescriptionPre: 200,
    ProjectDescriptionPost: 201,
    ProjectFundingNeedsPost: 202,
    ProjectGalleryPost: 203,
    ProjectOrganizationPost: 204,
    ProjectStatisticsPost: 205,
    ProjectVotingRankPost: 206,
    ProjectSupportPost: 207,
  }

  get projectTitle(): string {
    if ('project_title' in this.project) {
      return this.project.project_title
    }
    return this.project.title
  }

  customTabs(tabKey: number): ICustomTab[] {
    return this.project.custom_tabs[tabKey]
  }

  roundGoal(value: number): number {
    return ceilValueIfBelowX(value, 99)
  }

  progressText(goal: IMilestone): string {
    return this.projectDetailSettings.currencyDisplay.useCurrency
      ? this.$gettextInterpolate(this.$gettext('%{ currentBalance } of %{ totalBalance }'), {
          currentBalance: goal.balance.in_currency_display,
          totalBalance: goal.goal.in_currency_display,
        })
      : this.$gettextInterpolate(this.$gettext('%{ currentBalance } of %{ totalBalance } %{ coinName }'), {
          currentBalance: goal.balance.in_coins,
          totalBalance: goal.goal.in_coins,
          coinName: this.projectDetailSettings.currencyDisplay.coinNamePlural,
        })
  }
}
</script>
