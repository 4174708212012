<template>
  <v-wait for="fetch organizations">
    <template slot="waiting">
      <div class="row">
        <div class="col-sm-12 d-flex justify-content-center">
          <b-spinner variant="primary" label="Spinning" />
        </div>
      </div>
    </template>

    <b-table
      ref="selectableTable"
      hover
      borderless
      striped
      outlined
      responsive
      no-local-sorting
      show-empty
      sort-icon-left
      class="site-admin-min-table-height"
      :no-select-on-click="true"
      :empty-text="$gettext('No organizations found')"
      :fields="availableFields"
      :items="organizations"
      :busy="tableLoading"
      @sort-changed="sortingChanged"
      @row-selected="() => {}"
    >
      <template v-for="col in availableFilterCols" #[`head(${col.field})`]="data">
        <span :key="col.name">
          <TableFilterDropdown
            :key="`header-${col.name}`"
            menuClass="table-filter-menu"
            :label="data.label"
            :fieldName="col.field"
            :options="getFilter(col.filter).options"
            :filterName="getFilter(col.filter).filterName"
            :selected.sync="getFilter(col.filter).selected"
            @on-apply="applyFilterAndReload"
          />
        </span>
      </template>

      <!-- TODO: When moving this whole thing to its own module, build something to iterate through a variable and do this -->
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner variant="primary" class="align-middle" />
        </div>
      </template>

      <template #empty="scope">
        <div class="flex h-align-center v-align-center" style="min-height: 200px">
          {{ scope.emptyText }}
        </div>
      </template>

      <template #cell(index)="data">
        {{ 1 + data.index + (currentPage - 1) * pageSize }}
      </template>

      <template #cell(title)="data">
        <div class="options-hover-center">
          <slot name="actions" :organization="data.item" />
          <span class="labels" v-html="getOrganizationCol(data.item)" />
        </div>
      </template>

      <template #cell(status)="data">
        <StatusLabel :status="data.item.status" />
      </template>

      <template #cell(review_status)="data">
        <OrganizationReviewStatus :organization="data.item" />
      </template>

      <template #cell(voting_review_status)="data">
        <ReviewStatusLabel :status="data.item.voting_review_status" :needs_review="data.item.needs_voting_review" />
      </template>

      <template #cell(content_review_status)="data">
        <ContentReviewStatusLabel :status="data.item.content_review_status" />
      </template>

      <template #cell(nonprofit_review_status)="data">
        <OrganizationNonProfitReviewStatus :organization="data.item" />
      </template>

      <template #cell(proof)="data">
        <OrganizationProofStatus :organization="data.item" />
      </template>

      <template #cell(internal_status)="data">
        <span class="labels" v-html="getInternalStatusCol(data.item)" />
      </template>

      <template #cell(statutory_purpose_list)="data">
        <!-- TODO improve this somehow -->
        <div class="td-statutory_purpose_list" :title="data.item.statutory_purpose_list">
          {{ data.item.statutory_purpose_list || '-' }}
        </div>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      aria-controls="project-list"
      :prev-text="'‹ ' + $gettext('Prev')"
      :next-text="$gettext('Next') + ' ›'"
      :total-rows="totalCount"
      :per-page="pageSize"
      first-number
      last-number
    />
  </v-wait>
</template>

<script lang="ts">
import axios, { CancelTokenSource } from 'axios'
import { BvComponent, BvTableCtxObject } from 'bootstrap-vue'
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'

import InputGroup from '@/components/InputGroup.vue'
import ContentReviewStatusLabel from '@/components/labels/ContentReviewStatusLabel.vue'
import OrganizationNonProfitReviewStatus from '@/components/labels/OrganizationNonProfitReviewStatus.vue'
import OrganizationProofStatus from '@/components/labels/OrganizationProofStatus.vue'
import OrganizationReviewStatus from '@/components/labels/OrganizationReviewStatus.vue'
import ReviewStatusLabel from '@/components/labels/ReviewStatusLabel.vue'
import StatusLabel from '@/components/labels/StatusLabel.vue'
import TableFilterDropdown from '@/components/TableFilterDropdown.vue'
import PersistentFiltersMixin from '@/mixins/PersistentFiltersMixin'
import { IInternalStatus, ITableSettings } from '@/types/base'
import { IFilter, IFilters } from '@/types/filters'
import { IOrganization, IProject } from '@/types/projects'
import {
  contentStatusOptions,
  organizationTypeOptions,
  progressStatusOptions,
  reviewStatusOptions,
  statutoryPurposeOptions,
} from '@/utils/filterOptions'
import { addContextToUrl, API_URLS, SITE_URLS } from '@/utils/helpers'

@Component({
  components: {
    InputGroup,
    StatusLabel,
    OrganizationReviewStatus,
    ContentReviewStatusLabel,
    OrganizationNonProfitReviewStatus,
    OrganizationProofStatus,
    ReviewStatusLabel,
    TableFilterDropdown,
  },
  name: 'organizations-table',
})
export default class OrganizationsTable extends Mixins(PersistentFiltersMixin) {
  // Table field definitions. Check https://bootstrap-vue.js.org/docs/components/table/ for more
  @Ref() readonly selectableTable!: BvComponent
  @Prop({ default: false }) archived!: boolean
  @Prop() searchString!: string
  @Prop() pageSize!: number
  @Prop() tableSettings!: ITableSettings | null
  organizations: IOrganization[] = []
  internalStatuses: IInternalStatus[] = []
  totalCount = 0
  currentPage = 1
  tableContext: BvTableCtxObject | null = null
  tableLoading = false
  hoveredRowId = 0
  selectedOrganizations: IOrganization[] = []
  loadOrganizationsCancelToken: CancelTokenSource | null = null

  filterCols: { field: string; filter: string }[] = [
    { field: 'status', filter: 'organizationStatusFilter' },
    { field: 'review_status', filter: 'reviewStatusFilter' },
    { field: 'voting_review_status', filter: 'votingReviewStatusFilter' },
    { field: 'content_review_status', filter: 'contentReviewStatusFilter' },
    { field: 'nonprofit_review_status', filter: 'nonprofitReviewStatusFilter' },
    { field: 'proof', filter: 'proofFilter' },
    { field: 'internal_status', filter: 'internalStatusFilter' },
    { field: 'organization_type', filter: 'organizationTypeFilter' },
    { field: 'statutory_purpose_list', filter: 'statutoryPurposeListFilter' },
  ]

  filters: IFilters = {
    organizationStatusFilter: {
      filterName: 'status_named',
      selected: [],
      options: progressStatusOptions(this.$language.current),
    },
    reviewStatusFilter: {
      filterName: 'organization_review_status_named',
      selected: [],
      options: reviewStatusOptions(this.$language.current),
    },
    votingReviewStatusFilter: {
      filterName: 'voting_review_status_named',
      selected: [],
      options: reviewStatusOptions(this.$language.current),
    },
    contentReviewStatusFilter: {
      filterName: 'content_review_status_named',
      selected: [],
      options: contentStatusOptions(this.$language.current),
    },
    nonprofitReviewStatusFilter: {
      filterName: 'nonprofit_review_status_named',
      selected: [],
      options: {
        accepted: this.$gettext('Nonprofit'),
        rejected: this.$gettext('Not nonprofit'),
      },
    },
    proofFilter: {
      filterName: 'proof_status_named',
      selected: [],
      options: {
        active: this.$gettext('Valid'),
        expired: this.$gettext('Expired'),
      },
    },
    internalStatusFilter: {
      filterName: 'internal_status__title',
      selected: [],
    },
    organizationTypeFilter: {
      filterName: 'organization_type_named',
      selected: [],
      options: organizationTypeOptions(this.$language.current),
    },
    statutoryPurposeListFilter: {
      filterName: 'statutory_purpose_list',
      selected: [],
      options: statutoryPurposeOptions(this.$language.current),
    },
  }

  @Watch('pageSize')
  onPageSizeChanged(): void {
    this.loadOrganizations()
  }

  @Watch('currentPage')
  onCurrentPageChanged(): void {
    this.loadOrganizations()
  }

  @Watch('searchString')
  onSearchStringChanged(): void {
    this.currentPage = 1
    this.loadOrganizations()
  }

  getOrganizationCol(organization: IProject): string {
    return `<a href="${SITE_URLS.SITE_ADMIN.ORGANIZATIONS.UPDATE(organization.slug)}">${organization.title}</a>`
  }

  getFundingTypeCol(project: IProject): string {
    return `<span class="badge badge-info">${project.funding_type ? project.funding_type.display : '-'}</span>`
  }

  sortingChanged(ctx: BvTableCtxObject) {
    this.tableContext = ctx
    this.loadOrganizations()
  }

  rowHovered(row: IProject) {
    if (row.id) {
      this.hoveredRowId = row.id
    }
  }

  applyFilterAndReload(filter: IFilter): void {
    this.applyFilter(filter)
    this.reloadOrganizations()
  }

  resetFiltersAndReload(): void {
    this.resetFilters()
    this.reloadOrganizations()
  }

  getInternalStatusCol(organization: IOrganization): string {
    return organization.internal_status ? organization.internal_status.label : '-'
  }

  get searchPlaceholder() {
    return this.$gettext('Search organizations')
  }

  get availableFields() {
    const _availableFields = [...this.fields]
    if (this.tableSettings) {
      const fieldKeys = Object.keys(this.tableSettings.table_fields)
      const additionalFields = this.tableSettings.table_fields.additional_fields
      if (additionalFields) {
        for (const additionalStepKey of Object.keys(additionalFields)) {
          const additionalStep = this.tableSettings.table_fields.additional_fields[additionalStepKey]
          for (const additionalFieldKey of Object.keys(additionalStep)) {
            fieldKeys.push(additionalFieldKey)
          }
        }
      }
      const displayedFields = fieldKeys.filter(
        (field) => this.tableSettings && !this.tableSettings.hidden_fields.includes(field)
      )
      displayedFields.unshift('index')
      _availableFields.unshift({ key: 'index', label: this.$gettext('#') })
      return _availableFields.filter((field) => {
        const fieldName = typeof field === 'string' ? field : field.key
        return displayedFields.includes(fieldName)
      })
    }
    return []
  }

  get availableFilterCols() {
    if (this.tableSettings) {
      const fieldKeys = Object.keys(this.tableSettings.table_fields)
      return this.filterCols.filter((filterCol) => {
        return (
          this.tableSettings &&
          !this.tableSettings.hidden_fields.includes(filterCol.field) &&
          fieldKeys.includes(filterCol.field)
        )
      })
    }
    return []
  }

  async loadOrganizations() {
    this.tableLoading = true

    if (this.loadOrganizationsCancelToken) {
      this.loadOrganizationsCancelToken.cancel()
    }
    this.loadOrganizationsCancelToken = axios.CancelToken.source()

    await axios
      .get(
        addContextToUrl(API_URLS.ORGANIZATIONS.LIST, {
          sortBy: this.tableContext ? this.tableContext.sortBy : undefined,
          sortDesc: this.tableContext ? this.tableContext.sortDesc : undefined,
          search: this.searchString,
          filters: this.activeFilters,
          page: this.currentPage,
          pageSize: this.pageSize,
          archived: this.archived,
        }),
        {
          cancelToken: this.loadOrganizationsCancelToken.token,
        }
      )
      .then((response) => {
        this.loadOrganizationsCancelToken = null
        this.organizations = response.data.results
        this.totalCount = response.data.count
        this.$emit('total-count-update', this.totalCount)
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          this.loadOrganizationsCancelToken = null
        }
      })
    this.tableLoading = false
  }

  async reloadOrganizations(): Promise<void> {
    this.tableLoading = true
    this.currentPage = 1
    this.loadOrganizations()
  }

  async loadInternalStatus(): Promise<void> {
    return axios.get(API_URLS.ORGANIZATIONS.INTERNAL_STATUS).then((response) => {
      this.internalStatuses = response.data.results || []
      if (this.internalStatuses.length > 0) {
        this.filters.internalStatusFilter.options = Object.fromEntries(
          this.internalStatuses.map((item) => [item.title, item.title])
        )
      }
    })
  }

  async loadInitialOrganizations(): Promise<void> {
    this.$wait.start('fetch organizations')
    await Promise.all([this.loadOrganizations(), this.loadInternalStatus()])
    this.$wait.end('fetch organizations')
  }

  async created(): Promise<void> {
    this.loadInitialOrganizations()
  }
}
</script>

<style scoped lang="scss">
.border {
  border: 1px solid #dee2e6 !important;
}
.show-on-hover {
  visibility: hidden;
}
tr:hover {
  .show-on-hover {
    visibility: visible;
  }
}
</style>
