<template>
  <div>
    <h4>{{ title }}</h4>
    <b-dropdown
      ref="dropdown"
      right
      block
      variant="default"
      :popper-opts="{ placement: 'top', modifiers: { offset: { offset: '0,0' } } }"
      boundary="scrollParent"
    >
      <template #button-content>
        <span>{{ title }}</span>
        <span class="caret" />
      </template>
      <b-dropdown-header>
        <b-input-group>
          <b-input-group-prepend is-text>
            <i class="fal fa-search" />
          </b-input-group-prepend>
          <b-form-input
            id="search-items"
            v-model="itemSearch"
            type="text"
            maxlength="64"
            debounce="500"
            :placeholder="$gettext('Search')"
          />
        </b-input-group>
      </b-dropdown-header>
      <b-dropdown-form>
        <div v-if="itemOptions" style="overflow-y: scroll; max-height: 45vh">
          <span v-if="itemOptions.length === 0" key="no-items-found">
            <slot name="no-items-message">
              <translate class="pt-2">No items found</translate>
            </slot>
          </span>
          <div v-for="item in itemOptions" :key="item.label">
            <b-form-group v-if="item.label" :label="item.label">
              <b-form-checkbox-group v-model="inputModel" stacked :options="item.options" />
            </b-form-group>

            <b-form-checkbox-group v-else v-model="inputModel" stacked :options="[item]" />
          </div>
        </div>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ISelectGroup, ISelectOption, TGenericObject } from '@/types/base'

@Component({
  name: 'searchable-multi-select-dropdown',
})
export default class SearchableMultiSelectDropdown extends Vue {
  @Prop() value!: string[]
  @Prop() title!: string
  @Prop() items!: TGenericObject[]
  @Prop({ default: () => [] }) searchableFields!: string[]
  itemSearch = ''
  selected = []

  get inputModel(): string[] {
    return this.value
  }

  set inputModel(value: string[]) {
    this.$emit('input', value)
  }

  isSelectGroup(item: any): boolean {
    return item && typeof item === 'object' && 'options' in item
  }

  get itemOptions(): (ISelectOption | ISelectGroup)[] {
    return this.filteredItems.map((item) => {
      if (this.isSelectGroup(item)) {
        const filteredGroupOptions = this.filterGroupOptions(item.options)
        return {
          label: item.label,
          options: filteredGroupOptions,
        }
      }
      return {
        value: item.slug,
        text: item.title,
      }
    })
  }

  get filteredItems(): TGenericObject[] {
    const lowerCaseItemSearch = this.itemSearch.toLowerCase()

    return this.items.filter((item) => {
      if (this.isSelectGroup(item)) {
        if (item.label.toLowerCase().includes(lowerCaseItemSearch.toLowerCase())) {
          return true
        }
        const optionTexts = item.options.map((option) => option.text.toLowerCase())
        return optionTexts.some((text) => text.includes(lowerCaseItemSearch))
      }
      const title = item.title.toLowerCase()
      return title.includes(lowerCaseItemSearch)
    })
  }

  filterGroupOptions(items) {
    const lowerCaseItemSearch = this.itemSearch.toLowerCase()
    return items.filter((item) => {
      const title = item.text.toLowerCase()
      return title.includes(lowerCaseItemSearch)
    })
  }
}
</script>
