<template>
  <b-card class="shadow-sm rounded mb-3">
    <b-card-title>
      <translate>Infos</translate>
    </b-card-title>
    <p>
      <strong><translate>Category</translate></strong
      >:
      {{ project.category }}
    </p>
    <p v-if="project.ends_at">
      <strong><translate>Ends on</translate></strong
      >:
      {{ formatDate(project.ends_at) }}
    </p>
    <p class="margin-bottom-0">
      <strong><translate>Address</translate></strong
      >: {{ project.street }} {{ project.postal_code }}, {{ project.city }}, {{ project.country }}
    </p>
  </b-card>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IExploreProjectDetail } from '@/types/projects'
@Component({
  name: 'info-area',
})
export default class InfoArea extends Vue {
  @Prop() project!: IExploreProjectDetail

  // Get localised date
  formatDate(dateString: string): string {
    if (!dateString) {
      return '-'
    }
    return this.$moment(dateString).format('LL')
  }
}
</script>
