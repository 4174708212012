<template>
  <div>
    <slot v-if="isActive" name="images" />
    <div v-if="isActive && currentImages">
      <ImageSlider :photos="currentImages" />
    </div>
    <b-card-body v-if="isActive" :class="{ hide: !isActive }">
      <slot v-if="!customTab" name="tabContent" />
      <ReadMoreBtn v-if="customTab" :customTabContent="customTab?.render_template" />
    </b-card-body>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import ReadMoreBtn from '@/components/explore/hds/ReadMoreBtn.vue'
import ImageSlider from '@/components/explore/ImageSlider.vue'
import { IPhoto } from '@/types/photos'
import { ICustomTab } from '@/types/projects'
@Component({
  name: 'hds-tab',
  components: {
    ReadMoreBtn,
    ImageSlider,
  },
})
export default class HdsTab extends Vue {
  @Prop() customTab!: ICustomTab
  @Prop() currentImages!: IPhoto[]
  @Prop() activeTabKey!: string
  @Prop() tabKey!: string

  isActive = false

  @Watch('activeTabKey')
  onActiveTabChanged(newValue: string): void {
    this.isActive = this.tabKey === newValue
  }

  activateTab(): void {
    this.$emit('tabActivated', this.tabKey)
  }
}
</script>
