<template>
  <SfAlert
    :variant="variant"
    :show="items.length <= selectedItems.length && pageSize < totalItemCount"
    style="margin-bottom: 1rem; text-align: center"
  >
    <span v-if="items.length === selectedItems.length && !loadingSelectTrueAll">
      <slot name="items-page-selected" />
      <b @click="selectTrueAll">
        <a class="pointer">
          <slot name="select-all-items" />
        </a>
      </b>
    </span>
    <span v-else-if="loadingSelectTrueAll">
      <b-spinner variant="primary" label="Spinning" />
    </span>
    <span v-else>
      <slot name="all-items-selected" />
      <b @click="unselectTrueAll">
        <a class="pointer">
          <translate key="all-selected-clear"> Clear selection. </translate>
        </a>
      </b>
    </span>
  </SfAlert>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Prop, Vue } from 'vue-property-decorator'

import SfAlert from '@/components/SfAlert.vue'
import { TGenericObject } from '@/types/base'

@Component({
  name: 'table-select-alert',
  components: {
    SfAlert,
  },
})
export default class TableSelectAlert extends Vue {
  @Prop({ default: 'info' }) variant!: string
  @Prop() apiUrl: string
  @Prop() pageSize: number
  @Prop() items: TGenericObject[]
  @Prop() totalItemCount: number
  @Prop() selectedItems: TGenericObject[]
  loadingSelectTrueAll = false

  async selectTrueAll(): Promise<void> {
    this.loadingSelectTrueAll = true
    await axios.get(this.apiUrl).then((response) => {
      this.$emit('change', response.data)
    })
    this.loadingSelectTrueAll = false
  }

  unselectTrueAll(): void {
    this.$emit('change', [])
    this.$emit('reset')
  }
}
</script>

<style scoped lang="scss">
.border {
  border: 1px solid #dee2e6 !important;
}
.pointer {
  cursor: pointer;
}
</style>
