<template>
  <span class="labels" v-html="label" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IProject } from '@/types/projects'

@Component({
  name: 'project-financing-status',
})
export default class ProjectFinancingStatus extends Vue {
  @Prop() project!: IProject

  get label(): string {
    let labelHtml = ''
    if (this.project.fully_funded) {
      labelHtml = `<span class="badge badge-success">${this.$gettext('Fully funded')}</span>`
    } else if (this.project.expired) {
      labelHtml = `<span class="badge badge-info">${this.$gettext('Expired')}</span>`
    } else if (this.project.can_receive_fundings) {
      labelHtml = `<span class="badge badge-warning">${this.$gettext('In financing')}</span>`
    }
    if (this.project.is_banned) {
      labelHtml += `<span class="badge badge-danger">${this.$gettext('Banned')}</span>`
    }
    return labelHtml.length > 0 ? labelHtml : '-'
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
