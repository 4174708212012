import { VNode } from 'vue'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ToastMixin extends Vue {
  makeToast(variant: string, title: string, body: string) {
    this.$bvToast.toast(body, {
      title,
      toaster: 'b-toaster-top-left old',
      variant,
      solid: true,
      toastClass: 'sf-toast',
    })
  }

  makeCustomToast(variant: string, title: VNode | string, body: VNode, hideDelaySeconds: number = 5) {
    this.$bvToast.toast([body], {
      title: typeof title === 'string' ? title : [title],
      toaster: 'b-toaster-top-left old',
      variant: variant,
      solid: true,
      toastClass: 'sf-toast',
      autoHideDelay: hideDelaySeconds * 1000,
    })
  }
}
