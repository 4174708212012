<template>
  <b-button :variant="variant" @click="openPopup">
    <span class="fa-lg" :class="customClass">𝕏</span>
  </b-button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IProjectDetailSettings } from '@/types/cms'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { IExploreProjectDetail } from '@/types/projects'

@Component({
  name: 'twitter-share-button',
})
export default class TwitterShareButton extends Vue {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings

  get variant() {
    return this.projectDetailSettings.enableFoerderApp ? 'transparent' : 'twitter'
  }

  get customClass() {
    return this.projectDetailSettings.enableFoerderApp ? 'smb-tw-foerder-app' : ''
  }

  get shareUrl(): string {
    return (
      'https://twitter.com/intent/tweet?' +
      `url=${location.protocol + '//' + location.host + location.pathname}` +
      `&text=${this.project.share_text}` +
      `&hashtags=${this.projectDetailSettings.share.facebook.hashtags.join(',')}`
    )
  }

  openPopup(): void {
    window.open(this.shareUrl, 'sharer', 'toolbar=0, status=0, width=520, height=350')
  }
}
</script>
