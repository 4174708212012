<template>
  <v-wait for="fetch projects">
    <template slot="waiting">
      <div class="row">
        <div class="col-sm-12 d-flex justify-content-center mt-8">
          <b-spinner variant="primary" label="Spinning" />
        </div>
      </div>
    </template>

    <TableSelectAlert
      :pageSize="pageSize"
      :items="projects"
      :selectedItems="selectedProjects"
      :totalItemCount="projectCount"
      :apiUrl="allProjectsAPIURL"
      @change="(val) => $emit('update:selectedProjects', val)"
      @reset="() => selectableTable.clearSelected()"
    >
      <template #items-page-selected>
        <translate key="projects-page-selected" :translate-params="{ projectCount: projects.length }">
          All %{projectCount} projects on this page are selected.
        </translate>
      </template>
      <template #select-all-items>
        <translate key="select-all-projects" :translate-params="{ projectCount: projectCount }">
          Select all {{ projectCount }} projects.
        </translate>
      </template>
      <template #all-items-selected>
        <translate key="projects-all-selected" :translate-params="{ projectCount: projectCount }">
          All %{projectCount} projects are selected.
        </translate>
      </template>
    </TableSelectAlert>

    <b-table
      ref="selectableTable"
      hover
      borderless
      striped
      outlined
      responsive
      no-local-sorting
      show-empty
      sort-icon-left
      selectable
      class="site-admin-min-table-height"
      :no-select-on-click="true"
      :empty-text="emptyText"
      :fields="availableFields"
      :items="projects"
      :busy="tableLoading"
      @sort-changed="sortingChanged"
      @row-selected="onRowSelected"
    >
      <template v-for="col in availableFilterCols" #[`head(${col.field})`]="data">
        <span :key="col.field">
          <TableFilterDropdown
            :key="`header-${col.field}`"
            menuClass="table-filter-menu"
            :label="data.label"
            :fieldName="col.field"
            :options="getFilter(col.filter).options"
            :filterName="getFilter(col.filter).filterName"
            :selected.sync="getFilter(col.filter).selected"
            @on-apply="applyFilterAndReload"
          />
        </span>
      </template>
      <!-- TODO: When moving this whole thing to its own module, build something to iterate through a variable and do this -->
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner variant="primary" class="align-middle" />
        </div>
      </template>

      <template #empty="scope">
        <div class="flex h-align-center v-align-center" style="minheight: 200px">
          {{ scope.emptyText }}
        </div>
      </template>

      <template #head(selected)>
        <b-form-checkbox :checked="allVisibleItemsSelected" @change="toggleCheckAll" />
      </template>

      <template #cell(selected)="data">
        <b-form-checkbox :checked="data.rowSelected !== false" @change="toggleSelect(data.index)" />
      </template>

      <template #cell(index)="data">
        {{ 1 + data.index + (currentPage - 1) * pageSize }}
      </template>

      <template #cell(title)="data">
        <div class="options-hover-center">
          <slot name="actions" :project="data.item" />
          <span class="labels" v-html="getProjectCol(data.item)" />
        </div>
      </template>

      <template #cell(status)="data">
        <StatusLabel :status="data.item.status" />
      </template>

      <template #cell(review_status)="data">
        <ReviewStatusLabel :status="data.item.review_status" :needs_review="data.item.needs_donation_review" />
      </template>

      <template #cell(projectpromoter)="data">
        <span class="labels" v-html="getOrganizationCol(data.item)" />
      </template>

      <template #cell(organization_status)="data">
        <OrganizationReviewStatus :organization="data.item.organization" :showNOELabel="true" />
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      aria-controls="project-list"
      :prev-text="'‹ ' + $gettext('Prev')"
      :next-text="$gettext('Next') + ' ›'"
      :total-rows="projectCount"
      :per-page="pageSize"
      first-number
      last-number
    />
  </v-wait>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'

import OrganizationReviewStatus from '@/components/labels/OrganizationReviewStatus.vue'
import ReviewStatusLabel from '@/components/labels/ReviewStatusLabel.vue'
import StatusLabel from '@/components/labels/StatusLabel.vue'
import TableFilterDropdown from '@/components/TableFilterDropdown.vue'
import TableSelectAlert from '@/components/utils/TableSelectAlert.vue'
import ProjectTableMixin from '@/mixins/ProjectTableMixin'
import { IAPIFilterCtx } from '@/types/base'
import { IProject } from '@/types/projects'

@Component({
  components: {
    TableFilterDropdown,
    OrganizationReviewStatus,
    StatusLabel,
    ReviewStatusLabel,
    TableSelectAlert,
  },
  mixins: [ProjectTableMixin],
  name: 'account-project-table',
})
export default class AccountProjectTable extends Mixins(ProjectTableMixin) {
  get urlContext(): IAPIFilterCtx {
    return {
      sortBy: this.sortBy,
      sortDesc: this.sortDesc,
      search: this.searchString,
      filters: this.activeFilters,
      page: this.currentPage,
      pageSize: this.pageSize,
      archived: this.archived,
      extraParams: {
        administrated_by: true,
      },
      own: true,
    }
  }

  getProjectCol(project: IProject): string {
    if (!this.archived) {
      return `<a href="/account/project/${project.slug}/update/">${project.title}</a>`
    }
    return project.title
  }

  getOrganizationCol(project: IProject): string {
    return `<a href="/account/organization/${project.organization.slug}/update/">${project.organization.title}</a>`
  }

  async created(): Promise<void> {
    this.loadInitialProjects()
  }
}
</script>
