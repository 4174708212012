import { render, staticRenderFns } from "./DocumentAdministration.vue?vue&type=template&id=6fe98dc4&scoped=true"
import script from "./DocumentAdministration.vue?vue&type=script&lang=ts"
export * from "./DocumentAdministration.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fe98dc4",
  null
  
)

export default component.exports