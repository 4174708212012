<template>
  <b-tab :title="customTab.title">
    <b-card-body>
      <div v-html="customTab.render_template" />
    </b-card-body>
  </b-tab>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ICustomTab } from '@/types/projects'

@Component({
  name: 'project-custom-tab',
})
export default class ProjectCustomTab extends Vue {
  @Prop() customTab!: ICustomTab
}
</script>
