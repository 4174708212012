<template>
  <div class="mb-4 rounded">
    <Splide ref="mainSlider" class="mb-1" :options="mainOptions" @splide:move="splideMoved">
      <SplideSlide v-for="(slide, index) in photos" :key="slide.src">
        <div v-if="photos.length > 1" class="splide-count">{{ index + 1 }}/{{ photos.length }}</div>
        <VideoComponent v-if="slide.video" :photo="slide" />
        <div v-else class="position-relative">
          <div class="splide-img-wrapper">
            <b-img :src="slide.src" :alt="slide.description" fluid class="w-100" />
            <div v-if="showSlideDescription(slide)" class="splide-description-text">
              <h5 v-if="slide.main_description">{{ slide.main_description }}</h5>
              <p v-if="slide.description">{{ slide.description }}</p>
            </div>
          </div>
        </div>
      </SplideSlide>
    </Splide>
    <Splide v-if="thumbnails && photos.length > 1" ref="thumbnailSlider" :options="thumbnailOptions">
      <SplideSlide v-for="slide in photos" :key="slide.src" class="mb-1">
        <img :src="slide.src" :alt="slide.description" />
      </SplideSlide>
    </Splide>
  </div>
</template>

<script lang="ts">
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { IframeHTMLAttributes } from 'vue/types/jsx'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

import VideoComponent from '@/components/explore/VideoComponent.vue'
import { IPhoto } from '@/types/photos'

@Component({
  components: {
    Splide,
    SplideSlide,
    VideoComponent,
  },
})
export default class ImageSlider extends Vue {
  @Ref() readonly mainSlider: SplideSlide
  @Ref() readonly thumbnailSlider: SplideSlide
  @Prop() photos!: IPhoto[]
  @Prop({ default: false }) thumbnails!: boolean
  activeSlideIndex = 0

  mainOptions = {
    type: 'slide',
    rewind: true,
    perPage: 1,
    perMove: 1,
    gap: '1rem',
    pagination: false,
    center: true,
    arrows: this.showSplideArrow(),
  }

  thumbnailOptions = {
    type: 'slide',
    rewind: true,
    gap: '1rem',
    pagination: false,
    fixedWidth: 110,
    fixedHeight: 70,
    cover: true,
    focus: 'center',
    isNavigation: true,
    updateOnMove: true,
  }

  showSplideArrow(): boolean {
    return this.photos.length > 1
  }

  showSlideDescription(slide: IPhoto): boolean {
    return !!(slide.description || slide.main_description)
  }

  splideMoved(slide: SplideSlide, index: number) {
    this.activeSlideIndex = index
    this.resetVideos()
  }

  resetVideos(): void {
    for (const IFrame of this.mainSlider.$el.querySelectorAll('iframe') as IframeHTMLAttributes[]) {
      const iframeSrc = IFrame.src
      IFrame.src = iframeSrc
    }
  }

  mounted() {
    if (this.thumbnails) {
      const thumbnailSplide = this.thumbnailSlider?.splide
      if (thumbnailSplide) {
        this.mainSlider?.sync(thumbnailSplide)
      }
    }
  }
}
</script>
