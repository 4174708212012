<template>
  <div>
    <b-row class="justify-content-between mb-3">
      <b-col cols="6" class="mr-auto d-flex">
        <b-input-group class="w-100">
          <b-input-group-prepend is-text>
            <i class="fal fa-search" />
          </b-input-group-prepend>
          <b-form-input
            v-model="searchString"
            type="search"
            :placeholder="$gettext('Search')"
            @keyup="handleSearchChange"
          />
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-button
          :variant="searchString.length === 0 && !filtersChanged ? 'default' : 'danger'"
          class="mr-3"
          :disabled="searchString.length === 0 && !filtersChanged"
          @click="resetFilters"
        >
          <span class="pr-3 fal fa-filter" /><translate>Reset filters</translate>
        </b-button>
        <b-button
          :variant="!gridColumnsChanged ? 'default' : 'danger'"
          :disabled="!gridColumnsChanged"
          @click="resetGridColumns"
        >
          <span class="pr-3 fal fa-columns" /><translate>Reset columns</translate>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'ag-grid-filter-bar',
})
export default class FilterBar extends Vue {
  @Prop({ default: false }) filtersChanged!: boolean
  @Prop({ default: false }) gridColumnsChanged!: boolean
  searchString = ''

  @Watch('searchString')
  onSearchStringChange() {
    this.$emit('search-change', this.searchString)
  }

  resetFilters() {
    this.searchString = ''
    this.$emit('reset-filters')
  }

  resetGridColumns() {
    this.$emit('reset-columns-changed')
  }

  handleSearchChange(event: Event): void {
    const target = event.target as HTMLInputElement
    this.searchString = target.value
  }
}
</script>

<style scoped></style>
