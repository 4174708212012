<template>
  <b-dropdown ref="dropdown" :menu-class="menuClasses" right variant="outline-reduced-dark">
    <template #button-content>
      <i v-if="hasBeenApplied" class="fal fa-filter mr-1" /> {{ label }}
      <span class="caret" />
    </template>
    <b-dropdown-form>
      <b-form-group :label="$gettext('Order direction')">
        <b-form-radio-group v-model="sortDesc" :options="directionOptions" name="radios-stacked-a" stacked plain />
      </b-form-group>
      <b-form-group :label="$gettext('Order by')">
        <b-form-radio-group v-model="sortBy" :options="options" name="radios-stacked-b" stacked plain />
      </b-form-group>
      <b-button id="table-order-dropdown-apply-btn" v-translate class="mt-2" variant="primary" @click="applyOrder"
        >Apply</b-button
      >
    </b-dropdown-form>
  </b-dropdown>
</template>

<script lang="ts">
import { BDropdown } from 'bootstrap-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ISelectOption } from '@/types/base'

@Component({
  name: 'table-order-dropdown',
})
export default class TableOrderDropdown extends Vue {
  @Prop() label!: string
  @Prop({ default: () => [] }) options!: ISelectOption[]
  @Prop({ default: '' }) menuClass!: string
  $refs!: { dropdown: BDropdown }
  hasBeenApplied = false
  sortBy: string | number = ''
  sortDesc = false
  directionOptions = [
    { text: this.$gettext('Ascending'), value: false },
    { text: this.$gettext('Descending'), value: true },
  ]

  applyOrder(): void {
    this.hasBeenApplied = true
    this.$refs.dropdown.hide(true)
    this.$emit('order-apply', { sortBy: this.sortBy, sortDesc: this.sortDesc })
  }

  get menuClasses(): string {
    return `dropdown-menu-search ${this.menuClass}`
  }

  created(): void {
    if (this.options.length > 0) {
      this.sortBy = this.options[0].value
    }
  }
}
</script>

<style scoped></style>
