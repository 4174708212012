<template>
  <div class="form-module" style="padding-top: 20px">
    <form id="reminder_form" class="api-v4-form form" action="#" style="height: 100%; display: block">
      <fieldset>
        <b-form-group :label="$gettext('Title') + '*'" label-for="reminder-title">
          <b-form-input id="reminder-title" v-model="title" required maxlength="64" />
          <b-form-invalid-feedback :state="errorState('title')">{{ getErrorText('title') }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group :label="$gettext('Description')" label-for="reminder-description">
          <b-form-textarea id="reminder-description" v-model="description" cols="40" rows="10" />
          <b-form-invalid-feedback :state="errorState('description')">{{
            getErrorText('description')
          }}</b-form-invalid-feedback>
        </b-form-group>

        <div class="row">
          <div class="col-12 mb-3 form-group required" :class="{ 'has-error': errorState('scheduled_for') }">
            <label for="due_date_datepicker">
              <translate>Due date</translate>
              <span class="asterisk">*</span>
            </label>
            <LocalisedBFormDatepicker
              id="due_date_datepicker"
              v-model="dueDate"
              class="mb-2"
              :placeholder="$gettext('Choose a date')"
            />
            <span v-if="errorState('scheduled_for')" class="help-inline">{{ getErrorText('scheduled_for') }}</span>
          </div>

          <div class="sf col-md-5 form-group required mb-3" :class="{ 'has-error': errorState('users') }">
            <label for="assignee_select">
              <translate>Assignees</translate>
              <span class="asterisk">*</span>
            </label>
            <b-dropdown id="assignee_select" ref="dropdown" right variant="default">
              <template #button-content>
                {{ assigneButtonLabel }}
                <span class="caret" />
              </template>
              <b-dropdown-form>
                <div class="form-group">
                  <input
                    id="search-users"
                    type="text"
                    maxlength="64"
                    class="form-control"
                    :placeholder="$gettext('Search')"
                    @keyup="handleUserSearchChange"
                  />
                  <span
                    v-if="filteredUsers.length === 0"
                    key="no-users-found"
                    v-translate
                    style="font-size: 15px; top: 2rem; position: relative"
                    >No users found</span
                  >
                </div>
                <b-form-checkbox-group
                  v-model="selectedAssignees"
                  stacked
                  class="table-dropdown"
                  :options="assigneeOptions"
                />
              </b-dropdown-form>
            </b-dropdown>
            <span v-if="errorState('users')" class="help-inline">{{ getErrorText('users') }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 form-group">
            <b-form-checkbox v-model="sendCompletedMail">
              <translate>Notify me on completion</translate>
            </b-form-checkbox>
          </div>
        </div>
      </fieldset>
      <div v-if="reminder" id="edit_actions" class="form-actions text-right">
        <a key="cancel-save-reminder" class="btn form-btn" href="#" @click="$emit('cancel-edit')">{{
          $gettext('Cancel')
        }}</a>
        <b-button
          id="update-reminder-form-btn"
          key="save-reminder"
          variant="link"
          :disabled="saving"
          @click="updateReminder"
        >
          <b-spinner v-if="saving" variant="default" small />
          {{ $gettext('Save') }}
        </b-button>
      </div>
      <div v-else-if="hasPermission('change_reminders')" id="add_actions" class="form-actions text-right">
        <b-button
          id="add-reminder-form-btn"
          key="add-reminder"
          variant="link"
          :disabled="saving"
          @click="createReminder"
        >
          <b-spinner v-if="saving" variant="default" small />
          {{ $gettext('Add task') }}
        </b-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { debounce } from 'lodash'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import LocalisedBFormDatepicker from '@/components/LocalisedBFormDatepicker.vue'
import FormErrorMixin from '@/mixins/FormErrorMixin'
import ToastMixin from '@/mixins/ToastMixin'
import UserMixin from '@/mixins/UserMixin'
import { ISelectOption, TGenericObject } from '@/types/base'
import { IOrganization, IProject } from '@/types/projects'
import { IUser } from '@/types/users'
import { API_URLS } from '@/utils/helpers'

import { IReminder, IReminderTemplate } from '../../types/reminders'

@Component({
  name: 'reminder-form',
  components: {
    LocalisedBFormDatepicker,
  },
})
export default class ReminderForm extends Mixins(ToastMixin, FormErrorMixin, UserMixin) {
  @Prop({ default: null }) project!: IProject | null
  @Prop({ default: null }) organization!: IOrganization | null
  @Prop({ default: false }) reminder!: IReminder | false
  @Prop({ default: null }) reminderTemplate!: IReminderTemplate | null

  userSearch = ''
  dueDate = ''
  users: IUser[] = []
  selectedAssignees: number[] = []
  title = ''
  description = ''
  errors: TGenericObject = {}
  sendCompletedMail = true
  saving = false

  @Watch('reminder')
  onReminderChange(): void {
    if (this.reminder) {
      this.title = this.reminder.title
      this.description = this.reminder.description
      this.dueDate = this.formatDate(new Date(this.reminder.scheduled_for))
      this.selectedAssignees = this.reminder.users.map((user) => user.id)
      this.sendCompletedMail = this.reminder.send_completed_mail
    } else {
      this.resetForm()
    }
  }

  @Watch('reminderTemplate')
  onReminderTemplateChange(): void {
    if (this.reminderTemplate) {
      this.title = this.reminderTemplate.title
      this.description = this.reminderTemplate.description
    } else {
      this.title = ''
      this.description = ''
    }
  }

  handleUserSearchChange(event: Event): void {
    const target = event.target as HTMLInputElement
    this.userSearch = target.value
  }

  formatDate(date: Date): string {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    return [day, month, date.getFullYear()].reverse().join('-')
  }

  resetForm(): void {
    this.title = ''
    this.description = ''
    this.dueDate = ''
    this.selectedAssignees = []
    this.sendCompletedMail = false
  }

  reminderPostError(error: TGenericObject) {
    if (error.response) {
      if (error.response.status === 403) {
        this.makeToast(
          'danger',
          this.$gettext('Not authorized'),
          this.$gettext('You do not have the permission to perfom this action')
        )
      } else {
        this.errors = error.response.data
      }
    } else {
      this.makeToast('danger', this.$gettext('Error'), this.$gettext('Failed to create/update task.'))
    }
  }

  async createReminder() {
    this.saving = true
    await axios
      .post(API_URLS.REMINDERS.LIST, this.postData)
      .then(() => {
        this.$emit('refresh-reminders')
        this.resetForm()
        this.errors = {}
      })
      .catch((error) => {
        this.reminderPostError(error)
      })
    this.saving = false
  }

  async updateReminder(): Promise<void> {
    if (!this.reminder) {
      return
    }
    this.saving = true
    await axios
      .patch(API_URLS.REMINDERS.RETRIEVE(this.reminder.slug), this.postData)
      .then(() => {
        this.$emit('refresh-reminders')
        this.errors = {}
      })
      .catch((error) => {
        this.reminderPostError(error)
      })
    this.saving = false
  }

  get assigneButtonLabel(): string {
    if (this.selectedAssignees.length > 0) {
      return this.selectedAssignees.length + ' ' + this.$gettext('selected')
    }
    return this.$gettext('Choose')
  }

  get filteredUsers(): IUser[] {
    const lowerCaseUserSearch = this.userSearch.toLowerCase()
    return this.users.filter(
      (user) =>
        user.username.toLowerCase().includes(lowerCaseUserSearch) ||
        (user.full_name_w_username && user.full_name_w_username.toLowerCase().includes(lowerCaseUserSearch)) ||
        user.email.toLowerCase().includes(lowerCaseUserSearch)
    )
  }

  get assigneeOptions(): ISelectOption[] {
    return this.filteredUsers.map((user) => {
      return {
        value: user.id,
        text: user.full_name_w_username,
      }
    })
  }

  get postData(): TGenericObject {
    return {
      title: this.title,
      description: this.description,
      scheduled_for: this.dueDate,
      targetId: this.project ? this.project.id : this.organization.id,
      targetType: this.project ? 'project' : 'organization',
      users: this.selectedAssignees,
      send_completed_mail: this.sendCompletedMail,
    }
  }

  async fetchStaffUsers(): Promise<void> {
    const staffUsers = await axios
      .get(API_URLS.USERS.STAFF_LIST)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw new Error(error.message)
      })
    // .then(response => {
    //   if (!response.ok) { throw new Error(response.statusText) }
    //   return response.json()
    // })
    this.users = staffUsers
  }

  async created(): Promise<void> {
    this.fetchStaffUsers()
    this.fetchUserProfile()
    this.handleUserSearchChange = debounce(this.handleUserSearchChange, 500)
  }
}
</script>
