<template>
  <span class="labels" v-html="label" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IChoice } from '@/types/base'

@Component({
  name: 'status-label',
})
export default class StatusLabel extends Vue {
  @Prop() status!: IChoice

  get label(): string {
    let labelHtml = ''
    switch (this.status.choice) {
      case 1: {
        // Draft
        labelHtml = `<span class="badge badge-warning">${this.status.display}</span>`
        break
      }
      case 2: {
        // Public
        labelHtml = `<span class="badge badge-success">${this.status.display}</span>`
        break
      }
      case 3: {
        // Archived
        labelHtml = `<span class="badge badge-light">${this.status.display}</span>`
        break
      }
      case 4: {
        // Paused
        labelHtml = `<span class="badge badge-dark">${this.status.display}</span>`
        break
      }
    }
    return labelHtml
  }
}
</script>
