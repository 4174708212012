<template>
  <div class="card">
    <div class="w-100 light-grey img-placeholder placeholder" style="height: 200px" />
    <div class="card-body py-1">
      <div class="title-placeholder placeholder w-100 mt-2 mb-3" />
      <div class="text-muted text-placeholder placeholder w-50" />
      <div class="text-muted text-placeholder placeholder w-75 mb-3" />
      <div class="text-placeholder placeholder w-50" />
      <br />
      <div class="text-placeholder placeholder w-50 mb-3" />
      <div class="text-placeholder placeholder w-100" />
      <div class="text-placeholder placeholder w-100" />
      <div class="w-100 btn-placeholder placeholder mt-3" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'project-card-placeholder',
})
export default class ProjectCardPlaceholder extends Vue {}
</script>
