import { Component, Vue } from 'vue-property-decorator'

import { TGenericObject } from '@/types/base'

@Component
export default class FormErrorMixin extends Vue {
  errors: TGenericObject = {}

  errorState(key: string): boolean {
    return key in this.errors
  }

  inputState(key: string): boolean {
    return this.errorState(key) ? false : null
  }

  getErrorText(key: string): string {
    return key in this.errors
      ? this.errors[key]
          .map((text) => {
            return text.toString()
          })
          .join(', ')
      : ''
  }
}
