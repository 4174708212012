<template>
  <b-card class="shadow-sm mb-3 bg-white rounded">
    <h5 v-if="!projectDetailSettings.enableFoerderApp" key="sma-title" v-translate>Tell your friends</h5>
    <div class="social-buttons d-flex">
      <FacebookShareButton :project="project" :projectDetailSettings="projectDetailSettings" />
      <WhatsappShareButton v-if="isTouchDevice" :project="project" :projectDetailSettings="projectDetailSettings" />
      <TwitterShareButton :project="project" :projectDetailSettings="projectDetailSettings" />
      <EmailShareButton :project="project" :projectDetailSettings="projectDetailSettings" />
    </div>
  </b-card>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'

import EmailShareButton from '@/components/buttons/EmailShareButton.vue'
import FacebookShareButton from '@/components/buttons/FacebookShareButton.vue'
import TwitterShareButton from '@/components/buttons/TwitterShareButton.vue'
import WhatsappShareButton from '@/components/buttons/WhatsappShareButton.vue'
import MobileMixin from '@/mixins/MobileMixin'
import { IProjectDetailSettings } from '@/types/cms'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { IExploreProjectDetail } from '@/types/projects'

@Component({
  name: 'social-media-area',
  components: {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    EmailShareButton,
  },
})
export default class SocialMediaArea extends Mixins(MobileMixin) {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings
}
</script>
