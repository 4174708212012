<template>
  <div>
    <a class="read-more-collapse" :class="[btnClass, 'pointer']" @click="expanded = !expanded">
      <span :class="expanded ? 'fa fa-angle-down' : 'fa fa-angle-right'" /> {{ btnTxt }}
    </a>
    <b-collapse v-model="expanded" class="mt-2 ml-3">
      <slot />
    </b-collapse>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'read-more-collapse',
})
export default class ReadMoreCollapse extends Vue {
  @Prop({ required: true }) btnTxt: string
  @Prop({ required: false, default: 'info-icon' }) btnClass: string

  expanded = false
}
</script>
