<template>
  <b-modal id="login-modal" v-model="isOpenModal" centered size="md" v-on="$listeners">
    <template #modal-title>
      <h5><translate>Log in to save favorites</translate></h5>
    </template>

    <template #default>
      <LoginForm @success="$emit('success')" />
    </template>

    <template #modal-footer="">
      <b-button id="close-delete-modal-btn" variant="default" class="mr-1" @click="isOpenModal = false">
        <translate>Cancel</translate>
      </b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import LoginForm from '@/components/forms/LoginForm.vue'

@Component({
  name: 'login-modal',
  components: {
    LoginForm,
  },
})
export default class LoginModal extends Vue {
  @Prop({ default: false }) value!: boolean

  get isOpenModal(): boolean {
    return this.value
  }

  set isOpenModal(value: boolean) {
    this.$emit('input', value)
  }
}
</script>
