<template>
  <b-button :variant="variant" :href="shareUrl" target="_blank">
    <i class="fab fa-whatsapp fa-lg" :class="customClass" />
  </b-button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IProjectDetailSettings } from '@/types/cms'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { IExploreProjectDetail } from '@/types/projects'

@Component({
  name: 'whatsapp-share-button',
})
export default class WhatsappShareButton extends Vue {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings

  get variant() {
    return this.projectDetailSettings.enableFoerderApp ? 'transparent' : 'whatsapp'
  }

  get customClass() {
    return this.projectDetailSettings.enableFoerderApp ? 'smb-wa-foerder-app' : ''
  }

  get shareUrl(): string {
    return `whatsapp://send?text=${this.project.share_text} ${window.location}`
  }
}
</script>
