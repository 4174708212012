<template>
  <div>
    <div class="d-flex justify-content-between align-items-end">
      <div>
        <div>
          <div v-if="projectDetailSettings.enableFavorites">
            <span
              style="color: var(--base-ci)"
              :class="[favouriteIconClass, isFavourite ? '' : 'pointer', 'fa-heart h2']"
              @click="setFavourite"
              @mouseover="favouriteMouseover = true"
              @mouseleave="favouriteMouseover = false"
            />
          </div>
        </div>
        <b-button-group :vertical="isMobile">
          <b-button
            v-for="customTab in customTabs(tabKeys.ProjectDescriptionPre)"
            :key="'hds_btn_' + customTab.title"
            class="hds-tab-btn"
            :class="{ 'hds-tab-btn-active': customTab.title === activeTabKey }"
            @click="handleTabActivated(customTab.title)"
            >{{ customTab.title }}</b-button
          >
          <b-button
            class="hds-tab-btn"
            :class="{ 'hds-tab-btn-active': 'key_project' === activeTabKey }"
            @click="handleTabActivated('key_project')"
            ><translate>What we do</translate></b-button
          >
          <b-button
            class="hds-tab-btn"
            :class="{ 'hds-tab-btn-active': 'key_organization' === activeTabKey }"
            @click="handleTabActivated('key_organization')"
            ><translate>Who we are</translate></b-button
          >
          <b-button
            v-for="customTab in customTabs(tabKeys.ProjectDescriptionPost)"
            :key="'hds_content_' + customTab.title"
            class="hds-tab-btn"
            :class="{ 'hds-tab-btn-active': customTab.title === activeTabKey }"
            @click="handleTabActivated(customTab.title)"
            >{{ customTab.title }}</b-button
          >
          <b-button
            v-for="customTab in customTabs(tabKeys.ProjectFundingNeedsPost)"
            :key="'hds_content_' + customTab.title"
            class="hds-tab-btn"
            :class="{ 'hds-tab-btn-active': customTab.title === activeTabKey }"
            @click="handleTabActivated(customTab.title)"
            >{{ customTab.title }}</b-button
          >
          <b-button
            v-for="customTab in customTabs(tabKeys.ProjectGalleryPost)"
            :key="'hds_content_' + customTab.title"
            class="hds-tab-btn"
            :class="{ 'hds-tab-btn-active': customTab.title === activeTabKey }"
            @click="handleTabActivated(customTab.title)"
            >{{ customTab.title }}</b-button
          >
          <b-button
            v-for="customTab in customTabs(tabKeys.ProjectOrganizationPost)"
            :key="'hds_content_' + customTab.title"
            class="hds-tab-btn"
            :class="{ 'hds-tab-btn-active': customTab.title === activeTabKey }"
            @click="handleTabActivated(customTab.title)"
            >{{ customTab.title }}</b-button
          >
          <b-button
            v-for="customTab in customTabs(tabKeys.ProjectStatisticsPost)"
            :key="'hds_content_' + customTab.title"
            class="hds-tab-btn"
            :class="{ 'hds-tab-btn-active': customTab.title === activeTabKey }"
            @click="handleTabActivated(customTab.title)"
            >{{ customTab.title }}</b-button
          >
          <b-button
            v-for="customTab in customTabs(tabKeys.ProjectVotingRankPost)"
            :key="'hds_content_' + customTab.title"
            class="hds-tab-btn"
            :class="{ 'hds-tab-btn-active': customTab.title === activeTabKey }"
            @click="handleTabActivated(customTab.title)"
            >{{ customTab.title }}</b-button
          >
          <b-button
            v-for="customTab in customTabs(tabKeys.ProjectSupportPost)"
            :key="'hds_content_' + customTab.title"
            class="hds-tab-btn"
            :class="{ 'hds-tab-btn-active': customTab.title === activeTabKey }"
            @click="handleTabActivated(customTab.title)"
            >{{ customTab.title }}</b-button
          >
          <b-button
            v-for="customTab in customTabs(tabKeys.Append)"
            :key="'hds_content_' + customTab.title"
            class="hds-tab-btn"
            :class="{ 'hds-tab-btn-active': customTab.title === activeTabKey }"
            @click="handleTabActivated(customTab.title)"
            >{{ customTab.title }}</b-button
          >
        </b-button-group>
      </div>
      <b-img
        v-if="project.organization.logo"
        class="mb-1"
        right
        :src="project.organization.logo"
        style="max-height: 80px; max-width: 100%"
      />
    </div>
    <b-card v-if="projectDetailSettings" class="shadow-sm" :title="project.title">
      <b-card-body class="p-0">
        <HdsTab
          v-for="customTab in customTabs(tabKeys.ProjectDescriptionPre)"
          :key="'hds_content_' + customTab.title"
          :currentImages="currentImages"
          :tabKey="customTab.title"
          :activeTabKey="activeTabKey"
          :customTab="customTab"
          @tabActivated="handleTabActivated"
        />

        <HdsTab tabKey="key_organization" :activeTabKey="activeTabKey" @tabActivated="handleTabActivated">
          <template #images>
            <ImageSlider :photos="currentImages" :hideControllerCarousel="true" :hideSplideCount="false" />
          </template>

          <template #tabContent>
            <ReadMoreBtn :readLessContent="project.funding_scope.description_1">
              <template #read-more-top>
                <h3>{{ project.organization.title }}</h3>
              </template>

              <template #readMoreMiddle>
                <p>{{ project.organization.description }}</p>
                <div v-if="project.organization.description_activity">
                  <h3><translate>Main activities</translate>:</h3>
                  <p>{{ project.organization.description_activity }}</p>
                </div>
                <br />
                <div v-if="project.organization.description_strength">
                  <h3><translate>Strengths</translate>:</h3>
                  <p>{{ project.organization.description_strength }}</p>
                </div>
              </template>

              <template #readMoreBottom>
                <h4>
                  <translate>Contact</translate>
                </h4>
                <p>
                  {{ project.organization.title }}<br />
                  {{ project.organization.street }}<br />
                  {{ project.organization.postal_code }} {{ project.organization.city }}<br />
                  {{ project.organization.country }}<br />
                  <span v-if="project.organization.website">
                    <a :href="project.organization.website">
                      {{ project.organization.website }}
                    </a>
                  </span>
                </p>
              </template>

              <ReadMoreBtn :readLessContent="project.funding_scope.description_1">
                <template #read-more-top>
                  <h3>{{ project.funding_scope.name }}</h3>
                </template>
                <template #readMoreMiddle>
                  <p>{{ project.funding_scope.description_1 }}</p>
                  <a class="text-ci-base" target="_blank" :href="project.funding_scope.url_1">
                    {{ project.funding_scope.url_1 }}</a
                  >
                  <br />
                  <a class="text-ci-base" target="_blank" :href="project.funding_scope.url_2">
                    {{ project.funding_scope.url_2 }}</a
                  >
                  <br />
                </template>
                <template #readMoreBottom>
                  <div v-for="item in project.funding_scope.document" :key="item.src">
                    <a class="text-ci-base" :href="item.src" target="_blank" download> {{ item.filename }}</a>
                  </div>
                </template>
              </ReadMoreBtn>
            </ReadMoreBtn>
          </template>
        </HdsTab>

        <HdsTab tabKey="key_project" :activeTabKey="activeTabKey" @tabActivated="handleTabActivated">
          <template #images>
            <ImageSlider :photos="currentImages" />
          </template>

          <template #tabContent>
            <ReadMoreBtn :readLessContent="project.funding_scope.description_1">
              <template #read-more-top>
                <h3>{{ project.funding_scope.name }}</h3>
              </template>

              <template #readMoreMiddle>
                <p>{{ project.funding_scope.description_1 }}</p>
                <a class="text-ci-base" target="_blank" :href="project.funding_scope.url_1">
                  {{ project.funding_scope.url_1 }}</a
                >
                <br />
                <a class="text-ci-base" target="_blank" :href="project.funding_scope.url_2">
                  {{ project.funding_scope.url_2 }}</a
                >
                <br />
              </template>

              <template #readMoreBottom>
                <div v-for="item in project.funding_scope.document" :key="item.src">
                  <a class="text-ci-base" :href="item.src" target="_blank" download> {{ item.filename }}</a>
                </div>
              </template>

              <ReadMoreBtn :readLessContent="project.funding_scope.description_1">
                <template #read-more-top>
                  <h3>{{ project.funding_scope.name }}</h3>
                </template>
                <template #readMoreMiddle>
                  <p>{{ project.funding_scope.description_1 }}</p>
                  <a class="text-ci-base" target="_blank" :href="project.funding_scope.url_1">
                    {{ project.funding_scope.url_1 }}</a
                  >
                  <br />
                  <a class="text-ci-base" target="_blank" :href="project.funding_scope.url_2">
                    {{ project.funding_scope.url_2 }}</a
                  >
                  <br />
                </template>
                <template #readMoreBottom>
                  <div v-for="item in project.funding_scope.document" :key="item.src">
                    <a class="text-ci-base" :href="item.src" target="_blank" download> {{ item.filename }}</a>
                  </div>
                </template>
              </ReadMoreBtn>
            </ReadMoreBtn>
          </template>
        </HdsTab>
        <HdsTab
          v-for="customTab in customTabs(tabKeys.ProjectDescriptionPost)"
          :key="'hds_content_' + customTab.title"
          :tabKey="customTab.title"
          :activeTabKey="activeTabKey"
          :customTab="customTab"
          @tabActivated="handleTabActivated"
        />
        <HdsTab
          v-for="customTab in customTabs(tabKeys.ProjectFundingNeedsPost)"
          :key="'hds_content_' + customTab.title"
          :tabKey="customTab.title"
          :activeTabKey="activeTabKey"
          :customTab="customTab"
          @tabActivated="handleTabActivated"
        />
        <HdsTab
          v-for="customTab in customTabs(tabKeys.ProjectOrganizationPost)"
          :key="'hds_content_' + customTab.title"
          :tabKey="customTab.title"
          :activeTabKey="activeTabKey"
          :customTab="customTab"
          @tabActivated="handleTabActivated"
        />
        <HdsTab
          v-for="customTab in customTabs(tabKeys.ProjectStatisticsPost)"
          :key="'hds_content_' + customTab.title"
          :tabKey="customTab.title"
          :activeTabKey="activeTabKey"
          :customTab="customTab"
          @tabActivated="handleTabActivated"
        />
        <HdsTab
          v-for="customTab in customTabs(tabKeys.ProjectVotingRankPost)"
          :key="'hds_content_' + customTab.title"
          :tabKey="customTab.title"
          :activeTabKey="activeTabKey"
          :customTab="customTab"
          @tabActivated="handleTabActivated"
        />
        <HdsTab
          v-for="customTab in customTabs(tabKeys.ProjectSupportPost)"
          :key="'hds_content_' + customTab.title"
          :tabKey="customTab.title"
          :activeTabKey="activeTabKey"
          :customTab="customTab"
          @tabActivated="handleTabActivated"
        />
        <HdsTab
          v-for="customTab in customTabs(tabKeys.Append)"
          :key="'hds_content_' + customTab.title"
          :tabKey="customTab.title"
          :activeTabKey="activeTabKey"
          :customTab="customTab"
          @tabActivated="handleTabActivated"
        />
        <HdsTab
          v-for="customTab in customTabs(tabKeys.ProjectGalleryPost)"
          :key="'hds_content_' + customTab.title"
          :tabKey="customTab.title"
          :activeTabKey="activeTabKey"
          :customTab="customTab"
          @tabActivated="handleTabActivated"
        />
      </b-card-body>
      <LoginModal v-model="loginModalOpen" @success="onLoginSuccess()" />
      <FavouriteAddedModal v-model="favouriteAddedModalOpen" />
    </b-card>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import ReadMoreBtn from '@/components/explore/hds/ReadMoreBtn.vue'
import ImageSlider from '@/components/explore/ImageSlider.vue'
import FavouriteAddedModal from '@/components/modals/FavouriteAddedModal.vue'
import LoginModal from '@/components/modals/LoginModal.vue'
import MobileMixin from '@/mixins/MobileMixin'
import ToastMixin from '@/mixins/ToastMixin'
import UserMixin from '@/mixins/UserMixin'
import { IProjectDetailSettings } from '@/types/cms'
import { IMilestone } from '@/types/finances'
import { IPhoto } from '@/types/photos'
import { ICustomTab, IExploreProjectDetail } from '@/types/projects'
import { API_URLS } from '@/utils/helpers'

import ProjectCustomTab from '../ProjectCustomTab.vue'
import HdsTab from './HdsTab.vue'

@Component({
  components: {
    ReadMoreBtn,
    ProjectCustomTab,
    ImageSlider,
    LoginModal,
    FavouriteAddedModal,
    HdsTab,
  },
})
export default class ProjectOverviewFoerderapp extends Mixins(ToastMixin, UserMixin, MobileMixin) {
  @Prop() project!: IExploreProjectDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings

  loginModalOpen = false
  favouriteAddedModalOpen = false
  favouriteMouseover = false
  tabKeys = {
    Append: 1,
    ProjectPromoterDescriptionPre: 100,
    ProjectPromoterDescriptionPost: 101,
    ProjectPromoterGalleryPost: 102,
    ProjectPromoterProjectsPost: 103,
    ProjectPromoterDonationUsagePost: 104,
    ProjectPromoterVotingsPost: 105,
    ProjectPromoterStatisticsPost: 106,
    ProjectPromoterSupportPost: 107,
    ProjectDescriptionPre: 200,
    ProjectDescriptionPost: 201,
    ProjectFundingNeedsPost: 202,
    ProjectGalleryPost: 203,
    ProjectOrganizationPost: 204,
    ProjectStatisticsPost: 205,
    ProjectVotingRankPost: 206,
    ProjectSupportPost: 207,
  }

  activeTabKey = null

  get currentImages(): IPhoto[] {
    if (!this.activeTabKey || this.activeTabKey === 'key_project') {
      return this.project.photos
    } else if (this.activeTabKey === 'key_organization') {
      return this.project.organization.photos
    } else {
      return this.project.funding_scope.photos
    }
  }

  get isFavourite(): boolean {
    if (this.userProfile?.favorite_projects) {
      return this.userProfile.favorite_projects.some((fav) => fav.slug === this.project.slug)
    }
    return false
  }

  get favouriteIconClass(): string {
    if (this.isFavourite) {
      return 'fad'
    } else if (this.favouriteMouseover) {
      return 'fas'
    }
    return 'far'
  }

  customTabs(tabKey: number): ICustomTab[] {
    return this.project.custom_tabs[tabKey]
  }

  handleTabActivated(tabKey: string): void {
    this.activeTabKey = tabKey
    this.$emit('tab-changed', tabKey)
  }

  async setFavourite() {
    if (this.userProfile.is_anonymous) {
      this.loginModalOpen = true
      return true
    }
    await (this.isFavourite
      ? axios.post(API_URLS.EXPLORE.PROJECTS.REMOVE_FAVOURITE(this.project.slug)).catch(() => {
          this.makeToast('danger', 'Error', 'Failed to remove favorite')
        })
      : axios.post(API_URLS.EXPLORE.PROJECTS.FAVOURITE(this.project.slug)).catch(() => {
          this.makeToast('danger', 'Error', 'Failed to add favorite')
        }))

    this.fetchFavoriteProjects()
  }

  onLoginSuccess(): void {
    if (this.loginModalOpen) {
      this.loginModalOpen = false
      this.favouriteAddedModalOpen = true
    }
  }

  progressText(goal: IMilestone): string {
    return this.projectDetailSettings.currencyDisplay.useCurrency
      ? this.$gettextInterpolate(this.$gettext('%{ currentBalance } of %{ totalBalance }'), {
          currentBalance: goal.balance.in_currency_display,
          totalBalance: goal.goal.in_currency_display,
        })
      : this.$gettextInterpolate(this.$gettext('%{ currentBalance } of %{ totalBalance } %{ coinName }'), {
          currentBalance: goal.balance.in_coins,
          totalBalance: goal.goal.in_coins,
          coinName: this.projectDetailSettings.currencyDisplay.coinNamePlural,
        })
  }

  beforeDestroy() {
    for (const brand of document.querySelectorAll<HTMLElement>('.navbar-brand')) {
      brand.classList.remove('hide')
    }
  }

  mounted() {
    this.activeTabKey = this.project.custom_tabs[200] ? this.customTabs(200)[0].title : 'key_project'
    const navbarBrand = document.querySelectorAll('.navbar-brand')
    if (navbarBrand.length > 0) {
      for (const brand of document.querySelectorAll<HTMLElement>('.navbar-brand')) {
        brand.classList.add('hide')
      }
    }
  }
}
</script>
