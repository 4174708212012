<template>
  <b-card header-class="p-0 m-0" class="shadow-sm bg-white rounded mb-3" :title="$gettext('Donation Information')">
    <b-card-body class="px-3 py-1">
      <div v-if="isOrganization && projectDetailSettings?.displayOrganizationBalance">
        <div class="row d-flex justify-content-between align-items-center py-3 rounded bg-light">
          <div class="col-12">
            <h6>
              <span key="collected" v-translate="{ coinName: projectDetailSettings.currencyDisplay.coinNamePlural }"
                >%{ coinName } collected</span
              >
            </h6>
          </div>
          <div class="col-12">
            <span class="text-nowrap">{{ displayBalance }}</span>
          </div>
        </div>
      </div>

      <div v-else-if="showCurrentGoal || showOverallGoal || showOverallAndCurrentGoal">
        <div
          class="row d-flex justify-content-between align-items-center py-3 rounded bg-light project-stats-collected"
        >
          <div class="col-12">
            <h6>
              <translate>Collected</translate>
            </h6>
          </div>
          <div class="col-12">
            <span
              v-translate="{ currentBalance: displayBalance, totalGoal: displayGoal }"
              class="fact-value project-balance font-weight-bold"
            >
              %{ currentBalance } <span class="font-weight-light">of</span> %{ totalGoal }
            </span>
          </div>
        </div>
        <div class="row d-flex justify-content-between align-item-center mt-3 pr-1 rounded bg-light">
          <div class="col-7 py-4 d-flex flex-column justify-content-center">
            <div
              v-if="projectDetailSettings.enableCoinDonations"
              class="d-flex align-items-center project-stats-legend-codes"
            >
              <span class="legend codes" aria-hidden="true" />
              <span class="font-weight-light legend-text codes">
                <translate>Donation codes</translate>
              </span>
            </div>
            <div
              v-if="projectDetailSettings.enablePrivateDonations"
              class="d-flex align-items-center project-stats-legend-private"
            >
              <span class="legend private" aria-hidden="true" />
              <span class="font-weight-light legend-text private">
                <translate>Private donations</translate>
              </span>
            </div>
            <div v-if="coFundingFundingInPercent" class="d-flex align-items-center project-stats-legend-cofunding">
              <span class="legend cofunding" aria-hidden="true" />
              <span class="font-weight-light legend-text cofunding">
                <translate>Co-Funding</translate>
              </span>
            </div>
            <div v-if="otherDonationAmountInPercent" class="d-flex align-items-center project-stats-legend-direct">
              <span class="legend internal" aria-hidden="true" />
              <span class="font-weight-light legend-text internal">
                <translate>Direct funding</translate>
              </span>
            </div>
          </div>
          <div class="col-5 py-4 project-stats-circle">
            <CircularProgressBar
              :needsReachedInPercent="balanceReachedInPercent"
              :donationCodeFundingInPercent="codeFundingInPercent"
              :privateFundingInPercent="privateFundingInPercent"
              :coFundingFundingInPercent="coFundingFundingInPercent"
              :otherFundingInPercent="otherDonationAmountInPercent"
            />
          </div>
        </div>
      </div>

      <div v-if="showOverallAndCurrentGoal">
        <div class="row d-flex justify-content-between align-items-center py-3 rounded bg-light mt-3">
          <div class="col-12">
            <h6><translate key="needs-info-1">Needs completed</translate></h6>
          </div>
          <div class="col-12">
            <span
              key="needs-info-2"
              v-translate="{
                completedCount: 'goals' in project ? project.completed_goals_count : 0,
                totalCount: 'goals' in project ? project.total_goals_count : 0,
              }"
              class="font-weight-bold"
            >
              %{ completedCount } <span class="font-weight-light">of</span> %{ totalCount }
            </span>
          </div>
        </div>
        <h5 v-translate class="mt-4">Current project need</h5>
        <div
          class="row d-flex justify-content-between align-items-center py-3 rounded bg-light project-stats-current-need"
        >
          <div class="col-12">
            <h6><translate key="needs-info-1">Collected</translate></h6>
          </div>
          <div class="col-12">
            <span
              key="needs-info-2"
              v-translate="{
                completedCount: 'goals' in project ? project.current_goal_balance.in_currency_display : 0,
                totalCount: 'goals' in project ? project.current_goal.in_currency_display : 0,
              }"
              class="font-weight-bold"
            >
              %{ completedCount } <span class="font-weight-light">of</span> %{ totalCount }
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="showOnlyCollectedFunding"
        class="row d-flex bg-light align-content-center justify-content-between rounded-lg py-3 project-stats-collected-funding"
      >
        <div class="col-12">
          <h6>
            <span key="collected" v-translate="{ coinName: projectDetailSettings.currencyDisplay.coinNamePlural }"
              >%{ coinName } collected</span
            >
          </h6>
        </div>
        <div class="col-12">
          <span class="text-nowrap">{{ displayBalance }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ExploreModule } from '@/store/modules/explore'
import { IProjectDetailSettings } from '@/types/cms'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { IExploreProjectDetail } from '@/types/projects'

import CircularProgressBar from './CircularProgressBar.vue'

@Component({
  name: 'donation-info',
  components: {
    CircularProgressBar,
  },
})
export default class DonationInfo extends Vue {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: false }) isOrganization!: boolean

  get projectDetailSettings(): IProjectDetailSettings {
    return ExploreModule.projectDetailSettings
  }

  get displayBalance(): string {
    if (!('goals' in this.project)) {
      return this.project.balance.in_currency_display
    }
    // If project is archived or displayCurrentCoinBalance is not set to "Goal" (showing balance of goal)
    if (this.project.is_status_archived || !(this.projectDetailSettings?.displayCurrentCoinBalance === 2)) {
      return this.project.balance.in_currency_display
    }
    return this.project.current_goal_balance.in_currency_display
  }

  get displayGoal(): string {
    if (!('goals' in this.project)) {
      return ''
    }
    // If project is archived or displayCurrentCoinBalance is not set to "Goal" (showing value of goal)
    if (this.project.is_status_archived || !(this.projectDetailSettings?.displayCurrentCoinBalance === 2)) {
      return this.project.total_goal_amount.in_currency_display
    }
    return this.project.current_goal.in_currency_display
  }

  get balanceReachedInPercent(): number {
    if (!('goals' in this.project) || !this.project.total_goal_amount.in_cents) {
      return 0
    }
    return (this.project.balance.in_cents / this.project.total_goal_amount.in_cents) * 100
  }

  get otherDonationAmount(): number {
    const knownSum =
      this.project.funded_by_co_funding.in_cents +
      this.project.funded_by_codes.in_cents +
      this.project.funded_by_private_donations.in_cents
    return this.project.balance.in_cents - knownSum
  }

  get otherDonationAmountInPercent(): number {
    if (!('goals' in this.project) || !this.otherDonationAmount) {
      return 0
    }
    return (this.otherDonationAmount / this.project.total_goal_amount.in_cents) * 100
  }

  get coFundingFundingInPercent(): number {
    if (!('goals' in this.project) || !this.project.funded_by_co_funding.in_cents) {
      return 0
    }
    return (this.project.funded_by_co_funding.in_cents / this.project.total_goal_amount.in_cents) * 100
  }

  get privateFundingInPercent(): number {
    if (!('goals' in this.project) || !this.project.funded_by_private_donations.in_cents) {
      return 0
    }
    return (this.project.funded_by_private_donations.in_cents / this.project.total_goal_amount.in_cents) * 100
  }

  get codeFundingInPercent(): number {
    if (!('goals' in this.project) || !this.project.funded_by_codes.in_cents) {
      return 0
    }
    return (this.project.funded_by_codes.in_cents / this.project.total_goal_amount.in_cents) * 100
  }

  get showOverallGoal(): boolean {
    if (this.project?.is_status_archived) {
      return true
    }
    return this.projectDetailSettings?.displayCurrentCoinBalance === 1
  }

  get showCurrentGoal(): boolean {
    return this.projectDetailSettings?.displayCurrentCoinBalance === 2 && this.displayGoal !== ''
  }

  get showOverallAndCurrentGoal(): boolean {
    return this.projectDetailSettings?.displayCurrentCoinBalance === 4 && this.displayGoal !== ''
  }

  get showOnlyCollectedFunding(): boolean {
    return this.projectDetailSettings?.displayCurrentCoinBalance === 5 && this.displayGoal !== ''
  }
}
</script>
