<template>
  <span class="labels" v-html="label" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IReminder } from '../../types/reminders'

@Component
export default class ReminderStatus extends Vue {
  @Prop() reminder!: IReminder

  get label(): string {
    let labelHtml = '-'
    if (this.reminder.status === 1) {
      labelHtml = `<span class="badge badge-light">${this.reminder.status_display}</span>`
    } else if (this.reminder.status === 2) {
      labelHtml = `<span class="badge badge-success">${this.reminder.status_display}</span>`
    }
    return labelHtml
  }
}
</script>
