<template>
  <b-form class="p-4" @submit="submitForm">
    <b-form-group>
      <b-form-input
        id="id_identification"
        v-model="formData.identification"
        type="text"
        :placeholder="$gettext('e-mail/username')"
        name="identification"
        maxlength="64"
        required
      />
    </b-form-group>
    <b-form-group>
      <b-form-input
        id="id_password"
        v-model="formData.password"
        type="password"
        :placeholder="$gettext('Passwort')"
        name="password"
        required
      />
    </b-form-group>
    <b-form-group>
      <b-form-checkbox id="id-remember-me" v-model="formData.remember_me" name="remember_me"
        >Remember me</b-form-checkbox
      >
    </b-form-group>
    <b-button type="submit" block variant="base-ci">
      <b-spinner v-if="tryLogin" variant="black" label="Spinning" small class="mr-2" />
      <translate>Log In</translate>
    </b-button>
    <div class="text-right">
      <b-link :href="passwortResetUrl"><translate>forgot password?</translate></b-link>
    </div>
    <div class="divider">
      <span><translate>or</translate></span>
    </div>
    <p><translate>You don't have an account?</translate></p>
    <b-link class="btn btn-block btn-default signup-to-favorite-btn" :href="signUpUrl">
      <translate>Sign Up</translate>
    </b-link>
  </b-form>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Mixins } from 'vue-property-decorator'

import PlatformSettingsMixin from '@/mixins/PlatformSettingsMixin'
import ToastMixin from '@/mixins/ToastMixin'
import UserMixin from '@/mixins/UserMixin'
import { API_URLS, isZO } from '@/utils/helpers'

@Component({
  name: 'login-form',
})
export default class LoginForm extends Mixins(ToastMixin, UserMixin, PlatformSettingsMixin) {
  // TODO: DISCUSS IF EXTRA API POINT NEEDED FOR THIS
  tryLogin = false
  passwortResetUrl = API_URLS.ACCOUNT.PASSWORD.RESET
  signUpUrl = ''
  formData = {
    identification: '',
    password: '',
    remember_me: false,
  }

  getSignUpUrl() {
    this.signUpUrl = this.isZO
      ? API_URLS.SIGNUP.ORGANIZATION
      : this.platformFeatures.enable_user_companies || this.platformFeatures.enable_user_projects
        ? API_URLS.SIGNUP.CHOOSE
        : API_URLS.SIGNUP.SIGNUP
  }

  async checkFeature(features: string) {
    return axios.get(API_URLS.PLATFORMSETTINGS.CHECK_FEATURE(features)).then((response) => {
      return response.data
    })
  }

  get isZO() {
    return isZO()
  }

  async submitForm(event: Event): Promise<void> {
    event.preventDefault()
    this.tryLogin = true
    await axios
      .post(API_URLS.V3.LOGIN, this.formData)
      .then(() => {
        this.$emit('success')
        this.fetchUserProfile(true)
        this.makeToast('success', this.$gettext('Logged in'), '')
      })
      .catch((error) => {
        this.makeToast('danger', this.$gettext('Error'), error.response.data.non_field_errors)
      })
    this.tryLogin = false
  }

  async created(): Promise<void> {
    this.getSignUpUrl()
  }
}
</script>
