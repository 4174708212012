<template>
  <b-button :variant="variant" @click="openPopup">
    <i class="fa fa-facebook fa-lg" :class="customClass" />
  </b-button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IProjectDetailSettings } from '@/types/cms'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { IExploreProjectDetail } from '@/types/projects'

@Component({
  name: 'facebook-share-button',
})
export default class FacebookShareButton extends Vue {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings

  get variant() {
    return this.projectDetailSettings.enableFoerderApp ? 'transparent' : 'facebook'
  }

  get customClass() {
    return this.projectDetailSettings.enableFoerderApp ? 'smb-fb-foerder-app' : ''
  }

  get shareUrl(): string {
    // Using parameters from old code, but facebook only allows 1 hashtag now
    // Also removed "quote" param (to fill in text?) because facebook must have removed that too
    return (
      'https://www.facebook.com/sharer/sharer.php?display=popup' +
      `&u=${location.protocol + '//' + location.host + location.pathname}` +
      `&hashtag=${this.projectDetailSettings.share.facebook.hashtags.map((hashtag) => '%23' + hashtag).join(',')}`
    )
  }

  openPopup(): void {
    window.open(this.shareUrl, 'pop', 'width=600, height=400, scrollbars=no')
  }
}
</script>
