<template>
  <b-card v-if="(coinsEnabled || showRedeemArea) && isFundable" class="shadow-sm rounded mb-3">
    <b-card-title>
      <translate key="support-project-title">Support this project</translate>
    </b-card-title>
    <CoinCodeInputArea
      v-if="coinsEnabled"
      class="mb-4"
      :projectDetailSettings="projectDetailSettings"
      :project="project"
      @anon-redeemed="$emit('anon-redeemed')"
      @non-anon-redeemed="$emit('non-anon-redeemed')"
    />
    <div v-if="showRedeemArea">
      <b-input-group class="my-3">
        <b-input-group-prepend>
          <b-input-group-text>
            <span class="fa fa-heart" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          v-model="redeemValue"
          type="number"
          :step="getStepSize"
          debounce="200"
          :min="getStepSize"
          :max="maxRedeemValue"
        />
        <b-input-group-append>
          <b-button variant="base-ci" :disabled="redeemValue <= 0" @click="donateOnProject">
            <translate>Distribute</translate>
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <b-form-input
        v-model="redeemValue"
        :min="getStepSize"
        :max="maxRedeemValue"
        :disabled="redeemInputDisabled"
        debounce="200"
        type="range"
        :step="getStepSize"
      />
      <b-alert variant="info" class="mt-2 mb-0" dismissible :show="!!infoMessage" @dismissed="infoMessage = null">
        <span>{{ infoMessage }}</span>
      </b-alert>
      <b-alert
        variant="warning"
        class="mt-2 mb-0"
        dismissible
        :show="!!warningMessage"
        @dismissed="warningMessage = null"
      >
        <span>{{ warningMessage }}</span>
      </b-alert>
    </div>
  </b-card>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import LocaleMixin from '@/mixins/LocaleMixin'
import { IBalance } from '@/types/base'
import { ICodeFormatSettings, ICurrencyDisplaySettings, IProjectDetailSettings } from '@/types/cms'
import { IDonateOnProjectPostData } from '@/types/finances'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { IExploreProjectDetail } from '@/types/projects'
import { IUserProfile } from '@/types/users'
import { API_URLS } from '@/utils/helpers'

import CoinCodeInputArea from './CoinCodeInputArea.vue'

@Component({
  name: 'support-project-area',
  components: {
    CoinCodeInputArea,
  },
})
export default class SupportProjectArea extends Mixins(LocaleMixin) {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings
  @Prop({ default: null }) userBalance: IBalance
  @Prop({ default: null }) userProfile: IUserProfile
  @Prop({ default: false }) isOrganization!: boolean

  code = ''
  saving = false
  redeemValue = 0
  infoMessage: string = null
  warningMessage: string = null

  @Watch('userBalance')
  onUserBalanceChange(): void {
    this.updateRedeemValue()
  }

  @Watch('redeemValue')
  onRedeemValueChange(): void {
    this.redeemValue = this.currencyDisplay.useCurrency
      ? Number(Number(this.redeemValue).toFixed(2))
      : Math.floor(this.redeemValue)
    this.redeemValue = Math.min(this.redeemValue, this.maxRedeemValue)
  }

  get isFundable(): boolean {
    if ('expired' in this.project) {
      return !this.project.expired && !this.project.fully_funded
    }
    return this.project.can_receive_fundings
  }

  get showRedeemArea(): boolean {
    return this.userBalance?.in_currency > 0
  }

  get redeemInputDisabled(): boolean {
    return !(this.userBalance && this.userBalance.in_coins > 0)
  }

  get maxUserBalanceInCurrency(): number {
    return this.userBalance?.in_currency || 0
  }

  get maxUserBalanceInCoins(): number {
    return this.userBalance?.in_coins || 0
  }

  get coinsEnabled(): boolean {
    return this.projectDetailSettings?.enableCoinDonations || false
  }

  get currencyDisplay(): ICurrencyDisplaySettings {
    return this.projectDetailSettings?.currencyDisplay
  }

  get codeFormat(): ICodeFormatSettings {
    return this.projectDetailSettings?.codeFormat
  }

  get distributeTitle(): string {
    return this.$gettextInterpolate(this.$gettext('Distribute %{ displayName }'), {
      displayName: this.currencyDisplay.coinNamePlural,
    })
  }

  get maxRedeemValue(): number {
    if (!this.userBalance) {
      return 1
    }
    if ('total_goal_amount' in this.project) {
      if (this.currencyDisplay.useCurrency) {
        return Math.min(
          this.userBalance.in_currency,
          this.project.total_goal_amount.in_currency - this.project.balance.in_currency
        )
      } else {
        return Math.min(
          this.userBalance.in_coins,
          this.project.total_goal_amount.in_coins - this.project.balance.in_coins
        )
      }
    }
    return this.currencyDisplay.useCurrency ? this.userBalance.in_currency : this.userBalance.in_coins
  }

  get getStepSize(): number {
    // even when currency step should be lower than coin value
    return this.currencyDisplay.useCurrency ? 1 / this.projectDetailSettings.currencyDisplay.factorOfCurrencyToCoin : 1
  }

  updateRedeemValue(): void {
    this.redeemValue = this.maxRedeemValue
  }

  donateOnProject(): void {
    this.infoMessage = null
    this.warningMessage = null
    const donationData: IDonateOnProjectPostData = {
      amount: this.redeemValue,
    }
    if (this.isOrganization) {
      donationData.promoter_slug = this.project.slug
    } else {
      donationData.project_slug = this.project.slug
    }

    axios.defaults.headers.common['X-CSRFToken'] = this.$cookies.get('csrftoken')
    axios
      .post(API_URLS.V3.DONATE_ON_PROJECT, donationData)
      .then((response) => {
        this.$emit('donated', response.data)
        this.infoMessage = response.data?.info_message
      })
      .catch((error) => {
        this.warningMessage = error.response.data.error
      })
  }

  created() {
    this.updateRedeemValue()
  }
}
</script>
